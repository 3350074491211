// Angular
import { Component, Inject, OnInit, ChangeDetectionStrategy, Input, forwardRef, OnDestroy, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CustomerModel } from '../../../../../core/crm/customers';

// describes what the return value of the form control will look like
export interface ContactFormValues {
	// PersonName: string;
	Name: string;
	// ContactNo: number;
	// Email: string;
	Designation: string;
	LeadContacts: string;
}

@Component({
	selector: 'kt-contact',
	templateUrl: './contact.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ContactComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => ContactComponent),
			multi: true,
		}
	]

})
export class ContactComponent implements ControlValueAccessor, OnDestroy {

	@ViewChild('input', { static: true }) input: ElementRef;
	@Input() placeholder: string;
	customer: CustomerModel;
	selectedContact: any = [];
	selectable = true;
	removable = true;

	form: FormGroup;
	subscriptions: Subscription[] = [];

	get value(): ContactFormValues {
		return this.form.value;
	}

	set value(value: ContactFormValues) {
		this.form.setValue(value);
		this.onChange(value);
		this.onTouched();
	}

	constructor(private formBuilder: FormBuilder) {
		// create the inner form
		this.form = this.formBuilder.group({
			PersonName: ['', Validators.compose([Validators.required, Validators.maxLength(200)])],
			MobileNo: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
			ContactNo: ['', Validators.compose([Validators.maxLength(50)])],
			Email: ['', Validators.compose([Validators.email, Validators.maxLength(100)])],
			PersonDesignation: ['', Validators.compose([Validators.maxLength(200)])],
			LeadContacts: this.formBuilder.group({
				Name: ['', Validators.compose([Validators.maxLength(200)])],
				ContactNo: ['', Validators.compose([Validators.maxLength(15)])],
				Email: ['', Validators.compose([Validators.email, Validators.maxLength(200)])],
				Designation: ['', Validators.compose([Validators.maxLength(200)])],
			})
		});

		this.subscriptions.push(
			// any time the inner form changes update the parent of any change
			this.form.valueChanges.subscribe(value => {
				this.onChange(value);
				this.onTouched();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	registerOnChange(fn) {
		this.onChange = fn;
	}

	writeValue(value) {
		if (value) {
			this.value = value;
		}
	}

	registerOnTouched(fn) {
		this.onTouched = fn;
	}

	// communicate the inner form validation to the parent form
	validate(_: FormControl) {
		return this.form.valid ? null : { image: { valid: false, }, };
	}

	addMoreContact() {
	}

	remove(tag: any): void {
		let index = this.selectedContact.indexOf(tag);

		if (index >= 0) {
			this.selectedContact.splice(index, 1);
		}
	}
}