import { Injectable } from '@angular/core';
import { AuthService } from '../auth';
import { AuthUsecase } from '../usecase/auth-usecase'

@Injectable({
  providedIn: 'root'
})
export class AppfactoryService {

  constructor(private authService: AuthService) { }

  private getAuthUsecase: AuthUsecase

  get login() {
    if (!this.getAuthUsecase) {
      this.getAuthUsecase = new AuthUsecase(this.authService)
    }
    return this.getAuthUsecase
  }

  get register() {
    if (!this.getAuthUsecase) {
      this.getAuthUsecase = new AuthUsecase(this.authService)
    }
    return this.getAuthUsecase
  }

  get resetPassword() {
    if (!this.getAuthUsecase) {
      this.getAuthUsecase = new AuthUsecase(this.authService)
    }
    return this.getAuthUsecase
  }
  get generateLoginOTP() {
    if (!this.getAuthUsecase) {
      this.getAuthUsecase = new AuthUsecase(this.authService)
    }
    return this.getAuthUsecase
  }

  get loginWithOTP() {
    if (!this.getAuthUsecase) {
      this.getAuthUsecase = new AuthUsecase(this.authService)
    }
    return this.getAuthUsecase
  }
  
}
