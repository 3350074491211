import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { AuthNoticeService, AuthService,  Authenticate } from '../../../../core/auth';
import { LayoutConfigModel, LayoutConfigService } from '../../../../core/_base/layout';

@Component({
  selector: 'kt-login-with-otp',
  templateUrl: './login-with-otp.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LoginWithOtpComponent implements OnInit, OnDestroy {
  model: LayoutConfigModel;
  loginWithOTPForm: FormGroup;
  loading = false;
  isLoggedIn$: Observable<boolean>;
  errors: any = [];
  emailMessages: string;
  private unsubscribe: Subject<any>;
  private returnUrl: any;

  constructor(
    private router: Router,
    private auth: AuthService,
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private layoutConfigService: LayoutConfigService
  ) {
    this.unsubscribe = new Subject();
    this.auth.currentEmailOTP.subscribe(data => { this.emailMessages = data.email; })
    if (this.emailMessages) {
      const initialNotice = `OTP has been sent to Email / Mobile No - 
        <b>${this.emailMessages}</b> click 
        <b><span class='kt-pointer' (click)="generateLoginWithOTP()"> here
        </span></b>
         to resend.`;
      this.authNoticeService.setNotice(initialNotice, 'info');
    } else
      this.router.navigate(['/auth/login']);
  }

  ngOnInit(): void {
    this.model = this.layoutConfigService.getConfig();
    this.initLoginWithOTPForm();
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'] || '/';
    });
  }

  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  initLoginWithOTPForm() {
    this.loginWithOTPForm = this.fb.group({
      otp: ['', Validators.compose([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4)
      ])],
      emailOrMobileNo: [this.emailMessages]
    });
  }

  submit() {
    const controls = this.loginWithOTPForm.controls;
    if (this.loginWithOTPForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    this.loading = true;
    this.auth
      .loginWithOTP(this.loginWithOTPForm.value)
      .pipe(
        tap(user => {
          if (user.success) {
            user.accessToken = 'Bearer ' + user.token;
            this.store.dispatch(new Authenticate({ currentUser: user }));
            this.router.navigateByUrl(this.returnUrl);
            localStorage.setItem('referraltoken',user.referralToken)
          } else {
						this.authNoticeService.setNotice(user.errormessage, 'danger');
					}
        }),
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.loading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }

  // login(event) {
  //   if (event.keyCode == 13) {
  //     const controls = this.loginWithOTPForm.controls;
  //     if (this.loginWithOTPForm.invalid) {
  //       Object.keys(controls).forEach(controlName =>
  //         controls[controlName].markAsTouched()
  //       );
  //       return;
  //     }
  //     this.loading = true;
  //     this.auth
  //       .loginWithOTP(this.loginWithOTPForm.value)
  //       .pipe(
  //         tap(user => {
  //           if (user.success) {
  //             user.accessToken = 'Bearer ' + user.token;
  //             this.store.dispatch(new Authenticate({ currentUser: user }));
  //             this.router.navigateByUrl(this.returnUrl);
  //           } else {
  //             this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_OTP'), 'danger');
  //           }
  //         }),
  //         takeUntil(this.unsubscribe),
  //         finalize(() => {
  //           this.loading = false;
  //           this.cdr.markForCheck();
  //         })
  //       )
  //       .subscribe();
  //   }
  // }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.loginWithOTPForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
