import { Component, OnInit, Inject } from '@angular/core';
import { TaskService } from '../../../../core/task';
import { map } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';

@Component({
  selector: 'kt-show-users-dialog',
  templateUrl: './show-users-dialog.component.html',
  styleUrls: ['./show-users-dialog.component.scss']
})
export class ShowUsersDialogComponent implements OnInit {

  constructor(
    private taskService: TaskService,
    public dialogRef: MatDialogRef<ShowUsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  userList: any;
  noRecords: boolean = false;
  ccList: any;
  ngOnInit() {
    if (this.data.type == 'Assigned') {
      this.taskService.takeUserList(1, -1, '', this.data.Id)
        .pipe(
          map(res => {
            if (res.Status == 200) {
              this.userList = res.data.Users;
              this.noRecords = false;
            }
            else {
              if (res.data == '') {
                this.noRecords = true;
              }
            }
          })
        ).subscribe();
    } else {
      this.ccList = this.data.Id;
      this.userList = this.ccList;
    }

  }

  searchMember(searchValue) {
    if (this.data.type == 'Assigned') {
      this.taskService.takeUserList(1, -1, searchValue, this.data.Id)
        .pipe(
          map(res => {
            if (res.Status == 200) {
              this.userList = res.data.Users;
              this.noRecords = false;
            }
            else {
              if (res.data == '') {
                this.noRecords = true;
              }
            }
          })
        ).subscribe();
    } else {
      if (searchValue != '') {
        this.userList = this.ccList.filter(item => {
          if (item.UserName.toString().toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) {
            this.noRecords = false;
            return true;
          } else {
            this.noRecords = true;
            return false;
          }
        }
        );
      }
      else {
        this.userList = this.ccList;
      }
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }

}
