import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { isEmpty } from 'lodash';

@Component({
  selector: 'kt-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CaptchaComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: CaptchaComponent,
      multi: true
    }
  ],
})
export class CaptchaComponent implements OnInit {
  @Input("config") config: any = {};
  captch_input: any = '';
  @Output() codeResult = new EventEmitter();
  code: any = null;
  resultCode: any = '';
  capchaForm:FormGroup;
  // @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  // answer: number;
  // valid = false;
  // onChange = (v) => { };
  // onTouched = () => { };

  constructor(private fb:FormBuilder) { }

  ngOnInit() {
    // if(this.captch_input == ''){
    //   this.codeResult.emit('isEmpty');
    // }
    this.capchaForm = this.fb.group({
      captchaValue:[''],
    })
    this.capchaForm.controls['captchaValue'].valueChanges.subscribe((res)=>{
      // console.log("capta value", res);
      if (res !== this.resultCode && res !== '') {
        this.codeResult.emit("false");
      } else if(res === this.resultCode) {
        this.codeResult.emit("true");
      }else if(res === ''){
        this.codeResult.emit("empty");
      }
    })
    // this.createCaptcha();
  }
  // writeValue(value) { }

  // validate({ value }: FormControl) {
  //   const isNotValid = this.answer !== Number(value);
  //   return isNotValid && {
  //     invalid: true
  //   }
  // }

  // registerOnChange(fn) { this.onChange = fn; }
  // registerOnTouched(fn) { this.onTouched = fn; }

  // createCaptcha() {
  //   const ctx = this.canvas.nativeElement.getContext("2d");
  //   const [numOne, numTwo] = [random(), random()];
  //   this.answer = numOne + numTwo;

  //   ctx.font = "30px Arial";
  //   ctx.fillText(`${numOne} + ${numTwo} = `, 10, 35);
  // }

  // change(value: string) {
  //   this.onChange(value);
  //   this.onTouched();
  // }

  ngOnChanges() {
    if (this.config) {
      if (!this.config.font || !this.config.font.size) {
        this.config["font"]["size"] = "20px";
      }
      if (!this.config.font || !this.config.font.family) {
        this.config["font"]["family"] = "Arial";
      }
      if (!this.config.strokeColor) {
        this.config["strokeColor"] = "#f20c6c";
      }
      if (!this.config.length) {
        this.config["length"] = 6;
      }
      if (!this.config.cssClass) {
        this.config["cssClass"] = '';
      }

      if (!this.config.type) {
        this.config["type"] = 1;
      }

      if (!this.config.back || !this.config.back.stroke) {
        this.config["back"]["stroke"] = "";
      }
      if (!this.config.back || !this.config.back.solid) {
        this.config["back"]["solid"] = "#e8f0fe";
        // #f2efd2
      }

      this.createCaptcha();
    }
    // console.log("catp input", this.captch_input);
  }
  createCaptcha() {
    this.captch_input = '';
    this.resultCode = '';
    this.codeResult.emit("empty");
    switch (this.config.type) {
      case 1: // only alpha numaric degits to type
        let char = Math.random().toString(24).substring(2, this.config.length) + Math.random().toString(24).substring(2, 4);
        this.code = this.resultCode = char.toUpperCase();
        break;
      case 2: // solve the calculation 
        let num1 = Math.floor(Math.random() * 99);
        let num2 = Math.floor(Math.random() * 9);
        let operators = ['+', '-'];
        let operator = operators[(Math.floor(Math.random() * operators.length))];
        this.code = num1 + operator + num2 + '=?';
        this.resultCode = (operator == '+') ? (num1 + num2) : (num1 - num2);
        break;
    }

    setTimeout(() => {
      let captcahCanvas: any = document.getElementById("captcahCanvas");
      var ctx = captcahCanvas.getContext("2d");
      ctx.fillStyle = this.config.back.solid;
      ctx.fillRect(0, 0, captcahCanvas.width, captcahCanvas.height);
      ctx.beginPath();

      captcahCanvas.style.letterSpacing = 10 + "px";
      ctx.font = this.config.font.size + " " + this.config.font.family;
      ctx.fillStyle = this.config.font.color;
      ctx.textBaseline = "middle";
      ctx.font = 'italic 20pt Courier';
      ctx.textAlign = 'center';
      ctx.fillText(this.code,
        captcahCanvas.width / 2,
        captcahCanvas.height / 2);
      if (this.config.back.stroke) {
        ctx.strokeStyle = this.config.back.stroke;
        for (var i = 0; i < 150; i++) {
          ctx.moveTo(Math.random() * 300, Math.random() * 300);
          ctx.lineTo(Math.random() * 300, Math.random() * 300);
        }
        ctx.stroke();
      }

      // this.captchaCode.emit(char);
    }, 100);
  }

  playCaptcha() {
    var msg = new SpeechSynthesisUtterance(this.code.split('').join(' '));
    msg.pitch = 0.1;
    window.speechSynthesis.speak(msg);
  }

  // checkCaptcha(value) {
  //   if (this.captch_input != this.resultCode) {
  //     // alert('Captcha is not Match');
  //     this.codeResult.emit("false");

  //   } else {
  //     // alert('Captcha is  Verify done');
  //     this.codeResult.emit("true");
  //   }
  // }


}

// function random() {
//   return Math.floor(Math.random() * 10) + 1;
// }
