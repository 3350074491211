// Angular
import { Component, ElementRef, Input, OnInit, ViewChild, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
// Layout config
import { LayoutConfigService } from '../../../../../core/_base/layout';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
/**
 * Sample components with sample data
 */
@Component({ 
	selector: 'kt-custom-graph-widget',
	templateUrl: './custom-graph-widget.component.html',
	styleUrls: ['./custom-graph-widget.component.scss']
})
export class CustomGraphWidgetComponent implements OnInit {
	barChartOptions: ChartOptions = {
		responsive: true,
		// We use these empty structures as placeholders for dynamic theming.
		scales: {
			xAxes: [{
				
			}],
			yAxes: [{
				ticks: {
					beginAtZero: true,
				}
			}]
		}
	};
	barChartLabels: Label[] = [];
	barChartType: ChartType = 'bar';
	barChartLegend = true;
	colors = [{ "backgroundColor": 'rgba(247, 185, 46, 0.80)' }];

		// Public properties
		@Input() chartObj = { labels: [], data: [], total: '', reportLabel: '', options: {} };

	barChartData: ChartDataSets[] = [{ data: [], label: '',barThickness:30, categoryPercentage: 0.2  },{ data: [], label: '', barThickness: 30, categoryPercentage: 0.2 }];
	barChartPlugins = [];


	/**
	 * Component constructor
	 * @param layoutConfigService
	 */
	constructor(private layoutConfigService: LayoutConfigService) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.initChart();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.chartObj) {
			this.initChart();
			this.barChartData[1].data = this.chartObj.data[0].data[0];
			this.barChartData[1].label = this.chartObj.data[0].label;
			this.barChartData[0].data = this.chartObj.data[1].data[0];
			this.barChartData[0].label = this.chartObj.data[1].label;
		}
	}
	
	private initChart() {
		if (this.chartObj) {
			this.barChartLabels = this.chartObj.labels;
		}
	}
}