import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import {BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CrmSettingModel } from "../../../core/crm/crm-setting/crm-setting.model";
@Injectable({
  providedIn: 'root'
})
export class CrmSettingService {
  callLogSettingsSubject : BehaviorSubject<any> = new BehaviorSubject({})
  constructor(private http: HttpClient, ) { }
  getMyData():Observable<any>{
    return this.http.get<any>('api/CRM/CRMSetting');
  }

  mySaleTarget(): Observable<any> {
    return this.http.get('api/SaleTargetFields')
      .pipe(map(res => res));
  }
  mydisplayableunit(id: number): Observable<any> {
    return this.http.get('api/Units?Id=' + id)
      .pipe(map(res => res));
  }
  addCrmsetting(Crmset: CrmSettingModel): Observable<CrmSettingModel> {
    return this.http.post<CrmSettingModel>('api/CRM/CRMSetting', Crmset);
  }
  addStage(tag: string): Observable<CrmSettingModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<CrmSettingModel>('api/CRM/LeadStage', '"' + tag + '"', httpOptions);
  }
  addSource(tag: string): Observable<CrmSettingModel> {
    return this.http.post<CrmSettingModel>('api/CRM/LeadSource', tag);
  }
  addPurpose(tag: string): Observable<CrmSettingModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<CrmSettingModel>('api/CRM/VisitPurposes', '"' + tag + '"', httpOptions);
  }
  getStage(): Observable<any> {
    return this.http.get<any>('api/CRM/LeadStage');
  }
  getStageSource(): Observable<any> {
    return this.http.get<any>('api/CRM/LeadSource');
  }
  getVisitPurpose(): Observable<any> {
    return this.http.get<any>('api/CRM/VisitPurposes');
  }
  deleteStage(Id: number): Observable<any> {
    return this.http.delete<CrmSettingModel>('api/CRM/LeadStage/' + Id);
  }
  deleteSource(Id: number): Observable<any> {
    return this.http.delete<CrmSettingModel>('api/CRM/LeadSource/' + Id);
  }
  deletePurpose(Id: number): Observable<any> {
    return this.http.delete<CrmSettingModel>('api/CRM/VisitPurposes/' + Id);
  }

}

