import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map, tap, catchError } from "rxjs/operators";
import { ExcelService } from "../../_base/crud/services/excel.service";

@Injectable({
	providedIn: "root",
})
export class AttendanceService {
	constructor(private http: HttpClient, private excelService: ExcelService) {}

	// Geo User Detail
	getGeoUserDetail(from, to, searchText): Observable<any> {
		const params = {
			From: from,
			To: to,
			Text: searchText,
			Status: "all",
		};
		return this.http.post("api/GeoUserDetail", params).pipe(map((res) => res));
	}

	// Geo Fencing Area
	getGeoFencingArea(): Observable<any> {
		return this.http.get("api/GetGeoFencingArea").pipe(map((res) => res));
	}

	//Geo User Global Setting
	geoUserGlobalSetting(): Observable<any> {
		return this.http.get("api/GetUserGlobalSetting").pipe(map((res) => res));
	}

	//Add Geo Fencing
	addGeoFencing(fencingDetails): Observable<any> {
		return this.http.post("api/AddGeoFencing", fencingDetails).pipe(map((res) => res));
	}

	//Update Geo Fencing
	updateGeoFencing(fencingDetails): Observable<any> {
		return this.http.post("api/UpdateGeoFencing", fencingDetails).pipe(map((res) => res));
	}

	//Update Global Setting
	updateGlobalSetting(settingData): Observable<any> {
		return this.http.put("api/GC/GlobalSetting", settingData).pipe(map((res) => res));
	}

	//Add Holiday
	addHoliday(holidayDetails): Observable<any> {
		return this.http.post("api/AddFestival", holidayDetails).pipe(map((res) => res));
	}

	//Delete Holiday
	deleteHoliday(holidayId: number): Observable<any> {
		return this.http.get("api/DeleteFestival?HolidayId=" + holidayId).pipe(map((res) => res));
	}

	getWorkstation(workstationDetails): Observable<any> {
		return this.http.get("api/WorkStation?From=" + workstationDetails.from + "&To=" + workstationDetails.to + "&Search=" + workstationDetails.search + "&IsActive=" + workstationDetails.isActive).pipe(map((res) => res));
	}

	addWorkstation(workstationName): Observable<any> {
		return this.http.post("api/WorkStation", workstationName).pipe(map((res) => res));
	}

	updateWorkstation(id, workstationName): Observable<any> {
		return this.http.put("api/WorkStation/" + id, workstationName).pipe(map((res) => res));
	}

	deleteWorkstation(id): Observable<any> {
		return this.http.delete("api/WorkStation/" + id).pipe(map((res) => res));
	}

	//Remove GeoFencing
	removeGeoFencing(id: number): Observable<any> {
		return this.http.get("api/RemoveGeoFencing?Id=" + id).pipe(map((res) => res));
	}

	//Check In Claims
	getCheckInClaims(params): Observable<any> {
		return this.http.post("api/CheckInClaims", params).pipe(map((res) => res));
	}

	//Get Team
	getTeam(from, to, text): Observable<any> {
		const params = {
			from: from,
			text: text,
			to: to,
		};
		return this.http.post("api/CRM/MyTeam", params).pipe(map((res) => res));
	}

	//Update CheckIn Time
	updateCheckInTime(checkInDetails): Observable<any> {
		return this.http.post("api/RequestToUpdateCheckInTime", checkInDetails).pipe(map((res) => res));
	}

	//Update Status of Request
	updateStatusOfRequest(statusRequest): Observable<any> {
		return this.http.post("api/UpdateStatusOfRequestCheckInTime", statusRequest).pipe(map((res) => res));
	}

	//User Not Punched In Report
	exportUserNotPunchedIn(): Observable<any> {
		return this.http.get("api/GC/UsersNotPunchedInReport/Export", { responseType: "arraybuffer" }).pipe(
			map((res) => {
				return res;
			}),
			tap(
				(data) => this.excelService.saveAsExcelFile(data, "UsersNotPunchedInReport"),
				(error) => console.log(error)
			),
			catchError((err) => {
				return null;
			})
		);
	}

	//Attendance Report
	exportAttendanceReport(attendanceDetails): Observable<any> {
		// if(attendanceDetails.userIds && !attendanceDetails.managerIds)
		// {
		//   return this.http.get(`api/GC/DownloadAttendanceReport?userIds=${attendanceDetails.userIds}&userReport.userId=${attendanceDetails.userId}&userReport.reportDate=${attendanceDetails.reportDate}&userReport.startDate=${attendanceDetails.startDate}&userReport.endDate=${attendanceDetails.endDate}&userReport.isCustom=${attendanceDetails.isCustom}`,{ responseType: 'arraybuffer' })
		//   .pipe(
		//     map((res) => {
		//       return res;
		//     }),
		//     tap(
		//       data => this.excelService.saveAsExcelFile(data, 'AttendanceReport'),
		//       error => console.log(error),
		//     ),
		//     catchError(err => {
		//       return null;
		//     })
		//   );
		// }
		// else if(!attendanceDetails.userIds && attendanceDetails.managerIds)
		// {
		//   return this.http.get(`api/GC/DownloadAttendanceReport?managerIds=${attendanceDetails.managerIds}&userReport.userId=${attendanceDetails.userId}&userReport.reportDate=${attendanceDetails.reportDate}&userReport.startDate=${attendanceDetails.startDate}&userReport.endDate=${attendanceDetails.endDate}&userReport.isCustom=${attendanceDetails.isCustom}`,{ responseType: 'arraybuffer' })
		//   .pipe(
		//     map((res) => {
		//       return res;
		//     }),
		//     tap(
		//       data => this.excelService.saveAsExcelFile(data, 'AttendanceReport'),
		//       error => console.log(error),
		//     ),
		//     catchError(err => {
		//       return null;
		//     })
		//   );
		// }
		// else if(attendanceDetails.userIds && attendanceDetails.managerIds)
		// {
		//   return this.http.get(`api/GC/DownloadAttendanceReport?userIds=${attendanceDetails.userIds}&managerIds=${attendanceDetails.managerIds}&userReport.userId=${attendanceDetails.userId}&userReport.reportDate=${attendanceDetails.reportDate}&userReport.startDate=${attendanceDetails.startDate}&userReport.endDate=${attendanceDetails.endDate}&userReport.isCustom=${attendanceDetails.isCustom}`,{ responseType: 'arraybuffer' })
		//   .pipe(
		//     map((res) => {
		//       return res;
		//     }),
		//     tap(
		//       data => this.excelService.saveAsExcelFile(data, 'AttendanceReport'),
		//       error => console.log(error),
		//     ),
		//     catchError(err => {
		//       return null;
		//     })
		//   );
		// }
		// else
		// {
		//   return this.http.get(`api/GC/DownloadAttendanceReport?userReport.userId=${attendanceDetails.userId}&userReport.reportDate=${attendanceDetails.reportDate}&userReport.startDate=${attendanceDetails.startDate}&userReport.endDate=${attendanceDetails.endDate}&userReport.isCustom=${attendanceDetails.isCustom}`,{ responseType: 'arraybuffer' })
		//   .pipe(
		//     map((res) => {
		//       return res;
		//     }),
		//     tap(
		//       data => this.excelService.saveAsExcelFile(data, 'AttendanceReport'),
		//       error => console.log(error),
		//     ),
		//     catchError(err => {
		//       return null;
		//     })
		//   );
		// }

		return this.http.post("api/GC/DownloadAttendanceReport", attendanceDetails, { responseType: "arraybuffer" }).pipe(
			map((res) => {
				return res;
			}),
			tap(
				(data) => this.excelService.saveAsExcelFile(data, "AttendanceReport"),
				(error) => console.log(error)
			),
			catchError((err) => {
				return null;
			})
		);
	}

	emailAttendanceReport(attendanceDetails): Observable<any> {
		return this.http.post("api/GC/DownloadAttendanceReport", attendanceDetails).pipe(map((res) => res));
	}

	getManagers(): Observable<any> {
		return this.http.get("api/Users/GetManagers").pipe(map((res) => res));
	}

	//get Inactive Users
	getInactiveUsers(): Observable<any> {
		return this.http.get("api/Users/GetDeactivatedUsers").pipe(map((res) => res));
	}

	//Get Global Map Data
	getGlobalMapDate(date): Observable<any> {
		return this.http.get("api/AllUsersDateWiseGlobalMapData?date=" + date).pipe(map((res) => res));
	}

	//Get User Global Map Data
	getUserWiseMapData(userId, date): Observable<any> {
		return this.http.get("api/UserDateWiseGlobalMapData?userId=" + userId + "&date=" + date).pipe(map((res) => res));
	}

	//Send User Report
	sendUserReport(userDetails): Observable<any> {
		return this.http.post("api/SendUserReport", userDetails).pipe(map((res) => res));
	}

	//Send All Users Report
	sendAllUsersReport(userDetails): Observable<any> {
		return this.http.post("api/SendAllUsersReport", userDetails).pipe(map((res) => res));
	}

	//Send All Users Details Report
	sendAllUsersDetailsReport(userDetails): Observable<any> {
		return this.http.post("api/SendAllUsersDetailReport", userDetails).pipe(map((res) => res));
	}

	//Get User Settings
	getUserSetting(userId): Observable<any> {
		return this.http.get("api/GetUserSetting?userId=" + userId).pipe(map((res) => res));
	}

	//Get Admin Users
	getAdminUsers(userId): Observable<any> {
		return this.http.get("api/AdminUsers?userId=" + userId).pipe(map((res) => res));
	}

	//removeAdminUsers
	removeAdminUsers(userId, isExternal): Observable<any> {
		return this.http.get("api/RemoveAdminUsers?userId=" + userId + "&isExternal=" + isExternal).pipe(map((res) => res));
	}

	//Update User Setting
	updateUserSetting(userDetails): Observable<any> {
		return this.http.post("api/AddOrUpdateUserSetting", userDetails).pipe(map((res) => res));
	}

	//Update Notify Admin
	updateNotifyAdmin(userDetailsAdmin): Observable<any> {
		return this.http.post("api/AddOrUpdateNotifyAdminForUser", userDetailsAdmin).pipe(map((res) => res));
	}

  //Punch In
  userPunchIn(userDetails): Observable<any>{
    return this.http.post('api/CheckIn', userDetails)
      .pipe(
        map(res => res)
      );
  }

	//Punch Out
	userPunchOut(userDetails): Observable<any> {
		return this.http.post("api/CheckOut", userDetails).pipe(map((res) => res));
	}

	//User Settings Report
	getUserSettingsReport(params): Observable<any> {
		return this.http.get("api/GetAllUserSettingsList?from=" + params.From + "&to=" + params.To + "&SearchText=" + params.SearchText).pipe(map((res) => res));
	}

	//Export_User_Settings_Report
	exportUserSettingFile(data): Observable<any> {
		return this.http.post("api/GetAllUserSettingsListExport", data, { responseType: "arraybuffer" }).pipe(
			map((res) => {
				return res;
			}),
			tap(
				(data) => this.excelService.saveAsExcelFile(data, "UserSettingsReport"),
				(error) => console.log(error)
			),
			catchError((err) => {
				return null;
			})
		);
	}
}
