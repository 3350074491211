import { Component, Inject, OnInit, ChangeDetectionStrategy, Input, forwardRef, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';

export interface ImageFormValues {
	image: string;
	isRemove: boolean;
}

@Component({
	selector: 'kt-image-upload',
	templateUrl: './image-upload.component.html',
	styles: ['.kt-remove-file { position: relative; float: right; margin-top: -40px; color: red; cursor: pointer; }'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ImageUploadComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => ImageUploadComponent),
			multi: true,
		}
	]
})
export class ImageUploadComponent implements ControlValueAccessor, OnDestroy {

	@ViewChild('imageFileInput', { static: true }) imageFileInput: ElementRef;
	@Input() placeholder: string;
	displayFileName = '';

	form: FormGroup;
	subscriptions: Subscription[] = [];

	get value(): ImageFormValues {
		return this.form.value;
	}

	set value(value: ImageFormValues) {
		if (value) { this.displayFileName = this.form.controls['image'].value; }
		this.form.patchValue(value);
		this.onChange(value);
		this.onTouched();
	}

	constructor(private formBuilder: FormBuilder) {
		this.form = this.formBuilder.group({
			image: [''],
			isRemove: false
		});

		this.subscriptions.push(
			this.form.valueChanges.subscribe(value => {
				this.onChange(value);
				this.onTouched();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	registerOnChange(fn) {
		this.onChange = fn;
	}

	writeValue(value) {
		if (value) {
			this.value = value;
		}
	}

	registerOnTouched(fn) {
		this.onTouched = fn;
	}

	validate(_: FormControl) {
		return this.form.valid ? null : { image: { valid: false, }, };
	}

	openFile() {
		this.imageFileInput.nativeElement.click()
	}

	handleFileSelect(inputValue: any): void {
		if (inputValue.files[0]) {
			var file = inputValue.files[0];
			this.displayFileName = file.name;
			var reader = new FileReader();
			reader.onload = (e: any) => {
				var binaryData = e.target.result;
				var base64String = btoa(binaryData);
				var imagePath = base64String;
				this.form.patchValue({
					image: imagePath
				});
			}
			reader.readAsBinaryString(file);
		}
	}

	removeFile() {
		this.imageFileInput.nativeElement.value = '';
		this.displayFileName = '';
		this.form.patchValue({
			image: '',
			isRemove: true
		});
	}
}