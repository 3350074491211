import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrganizationalUnitModel } from './organizational-unit.model';
@Injectable({
  providedIn: 'root'
})
export class OrganizationalUnitService {

  constructor(private http: HttpClient) { }

  getDeparment(data) {
    return this.http.get<OrganizationalUnitModel>('/api/Departments?From=' + data.From + '&To=' + data.To +'&Text='+ data.Text);


  }

  addDepartment(params: any) {
    return this.http.post<OrganizationalUnitModel>('api/Department', params)
  }

  updateDepatment(params: any , Id : number) {
    return this.http.put<OrganizationalUnitModel>('api/Department/' + Id , params)
  }

  deleteDepartment(id: number) {
    return this.http.delete<OrganizationalUnitModel>('api/Department/' + id   )
  }
  getDesignation(data){
    return this.http.get<OrganizationalUnitModel>('/api/Designations?From=' + data.From + '&To=' + data.To +'&Text='+ data.Text);
  }
  addDesignation(params: any){
    return this.http.post<OrganizationalUnitModel>('api/Designation', params)
  }
  updateDesignation(params: any, Id: number) {
    return this.http.put<OrganizationalUnitModel>('api/Designation/' + Id  , params)
  }
  deleteDesignation(id: number) {
    return this.http.delete<OrganizationalUnitModel>('api/Designation/' + id)
  }

  // branches
  getBranches(data){
    return this.http.get<OrganizationalUnitModel>('/api/Branch?From=' + data.From + '&To=' + data.To +'&Text='+ data.Text);
  }

  addBranches(params: any){
    return this.http.post<OrganizationalUnitModel>('api/Branch', params)
  }
  updateBranches(params: any, Id: number) {
    return this.http.put<OrganizationalUnitModel>('api/Branch/' + Id , params)
  }
  deleteBranches(id: number) {
    return this.http.delete<OrganizationalUnitModel>('api/Branch/' + id )
  }

  getBranchHead(params){
		return this.http.post('api/CRM/MyTeamWithGCDetail', params);
	}
  
}

