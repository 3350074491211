import { Component, OnInit, NgZone, ChangeDetectorRef, ChangeDetectionStrategy, Renderer2 } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TeamService } from '../../../../../../../core/crm/team';
import { map } from 'rxjs/operators';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';

interface marker {
	lat: number;
	lng: number;
	draggable: boolean;
	userName: string;
	checkInTime: string;
	checkOutTime?: string;
	address: string;
	isVisible: boolean;
}

@Component({
	selector: 'kt-global-map',
	templateUrl: './global-map.component.html',
	styles: [`agm-map{ height:60vh; } .font{font-size:14px !important}
	.info-window-content {
		position: relative; /* Establish positioning context */
	  }

	  .info-window-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	  }

	  .info-header {
		margin: 0;
		font-size: 1.2em;
		color: #007bff; /* Example color */
	  }

	  .close-btn {
		background: none;
		border: none;
		font-size: 1.5em;
		cursor: pointer;
		color: #555; /* Color for the close icon */
	  }

// 	  :host::ng-deep .gm-style-iw-chr  {
//     display: none;
// }
	  `]
	  ,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DatePipe]
})
export class GlobalMapComponent implements OnInit {

	latitude: number = 18.969050;
	longitude: number = 72.821180;
	mapType = 'roadmap';
	globalMapDate: any;
	private geoCoder;
	address: string = '';
	mapReport: FormGroup;
	markers: marker[] = [];
	infoToggle: any[] = [];
	currentIndex;
	previousIndex;

	constructor(
		private teamService: TeamService,
		private datePipe: DatePipe,
		private fb: FormBuilder,
		private chRef: ChangeDetectorRef,
		private mapsAPILoader: MapsAPILoader,
		private renderer?: Renderer2
	) { }

	ngOnInit() {

		this.createForm();
		let date = new Date();
		let localDate = this.datePipe.transform(date, 'MM/dd/yyyy');

		this.mapsAPILoader.load().then(() => {
			this.geoCoder = new google.maps.Geocoder;
		});

		this.teamService.getTeamWiseGlobalMapData(localDate)
			.pipe(
				map(res => {
					this.globalMapDate = res.data;
					let length = Object.keys(res.data).length;
					for (let i = 0; i < length; i++) {
						if (res.data[i].CheckInLatitude != 0 && res.data[i].CheckInLongitude != 0) {
							this.markers.push({
								lat: res.data[i].CheckInLatitude,
								lng: res.data[i].CheckInLongitude,
								draggable: false,
								userName: res.data[i].UserName,
								checkInTime: res.data[i].CheckInTime,
								checkOutTime: res.data[i].CheckOutTime,
								address: '',
								isVisible: true
							});
						}
					}
					this.createToogleArray();
					this.chRef.detectChanges();
					setTimeout(() => {
						this.updateStyle('none');
					}, 1000);

				})).subscribe();

	}

	createToogleArray() {
		for (let index = 0; index < this.markers.length; index++) {
			this.infoToggle[index] = false;
		}
	}

	createForm() {
		this.mapReport = this.fb.group({
			mapDate: [new Date(), Validators.required]
		})
	}

	clickedMarker(latitude, longitude, index) {

		this.currentIndex = index;
		if (this.previousIndex == this.currentIndex) {
			for (let i = 0; i < this.markers.length; i++) {
				this.markers[i].isVisible = true
				this.infoToggle[i] = false
			}
			this.previousIndex = -1;
		}
		else {
			for (let i = 0; i < this.markers.length; i++) {
				if (this.currentIndex == i) {
					this.infoToggle[i] = true
					this.markers[i].isVisible = true
				}
				else {
					this.infoToggle[i] = false
					this.markers[i].isVisible = false
				}
				this.previousIndex = this.currentIndex
			}
		}

		this.getAddress(latitude, longitude, index);
		this.updateStyle('none'); // Hide the info window

	}

	getAddress(latitude, longitude, index) {
		this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
			if (status === 'OK') {
				if (results[0]) {
					this.address = results[0].formatted_address;
					this.chRef.detectChanges();
				} else {
					alert('No results found');
				}
			}
		});
	}

	getMapReport() {
		this.markers = [];
		let mapDate = this.mapReport.controls.mapDate.value;
		mapDate = this.datePipe.transform(mapDate, 'MM/dd/yyyy');
		this.teamService.getTeamWiseGlobalMapData(mapDate)
			.pipe(
				map(res => {
					this.globalMapDate = res.data;
					let length = Object.keys(res.data).length;
					for (let i = 0; i < length; i++) {
						if (res.data[i].CheckInLatitude != 0 && res.data[i].CheckInLongitude != 0) {
							this.markers.push({
								lat: res.data[i].CheckInLatitude,
								lng: res.data[i].CheckInLongitude,
								draggable: false,
								userName: res.data[i].UserName,
								checkInTime: res.data[i].CheckInTime,
								checkOutTime: res.data[i].CheckOutTime,
								address: '',
								isVisible: true
							});
						}
					}
					this.chRef.detectChanges();
				})).subscribe();
	}

	closeInfoWindow(marker: any) {
		marker.isVisible = false;
		this.updateStyle('block'); // Show the info window
	  }

	  openInfoWindow(marker: any) {
		marker.isVisible = true;
		this.updateStyle('none');
		console.log('openInfoWindow');

	  }

	 updateStyle(displayValue: string) {
		setTimeout(() => {
			const infoWindows = document.querySelectorAll('.gm-style-iw-chr');
			infoWindows.forEach((iw) => {
			  this.renderer.setStyle(iw, 'display', displayValue);
			});
		  }, 0);
	  }
	  closeInfoWindow1(marker: any) {
		marker.isVisible = false;
	  }

	  ngAfterViewInit(): void {
		// setTimeout(() => {
			const infoWindows = document.querySelectorAll('.gm-style-iw-chr');
			infoWindows.forEach((iw) => {
			  this.renderer.setStyle(iw, 'display', 'none');
			});
		// }, 100);
	  }

	mapClick() {
		console.log('mapClick');
		// setTimeout(() => {
		// 	// this.updateStyle('none');
		// 	const infoWindows = document.querySelectorAll('.gm-style-iw-chr');
		// 	infoWindows.forEach((iw) => {
		// 	  this.renderer.setStyle(iw, 'display', 'block');
		// 	});
		// }, 1000);
	}
}
