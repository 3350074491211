import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError, tap, refCount, publishReplay } from 'rxjs/operators';
import { ExcelService } from '../../_base/crud/services/excel.service';
import { User } from '../../auth';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	countryList: Observable<unknown>;

	constructor(private http: HttpClient, private excelService: ExcelService) { }

	getMyProfile():Observable<User[]>{
		return this.http.get<User[]>('api/UserDetail');
	}

	findUsers(data): Observable<any> {
		return this.http.get('api/Users?CityId=' + data.CityId + '&CountryId=' + data.CountryId + '&From=' + data.From + '&IsActive=' + data.IsActive + '&ManagerId=' + data.ManagerId + '&RoleIds=' + data.RoleIds + '&SearchText=' + data.SearchText + '&StateId=' + data.StateId + '&To=' + data.To + '&departmentIds=' + data.departmentIds + '&designationIds='+ data.designationIds + '&branchIds='+ data.branchIds)
			.pipe(
				map(res => res)
			);
	}

	// export to excel
	fileExport(user): Observable<any> {
		const para:any =
		{
			CityId: user.CityId,
			CountryId : user.CountryId,
			From :1,
			IsActive :user.IsActive,
			ManagerId : user.ManagerId,
			RoleIds :user.RoleIds,
			SearchText :user.SearchText,
			StateId:user.StateId,
			branchIds : user.branchIds,
			departmentIds : user.departmentIds,
			designationIds : user.designationIds,
			To : -1

		};
		
		return this.http.get('api/Users/Export?', { params :para,responseType: 'arraybuffer' })
			.pipe(
				map((res) => {
					return res;
				}),
				tap(
					data => this.excelService.saveAsExcelFile(data, 'Users'),
					error => console.log(error),
				),
				catchError(err => {
					return null;
				})
			);
	}

	// file upload
	fileUpload(file: any): Observable<any> {
		return this.http.post<any>('api/CRM/BulkUploadUserWithManager', file);
	}

	userDeactivate(userId: number): Observable<any> {
		return this.http.put('api/Users/' + userId + '/Deactivate', userId);
	}

	userActivate(userId: number): Observable<any> {
		return this.http.put('api/Users/' + userId + '/activate', userId);
	}

	// getCountry(): Observable<any> {
	// 	if (!this.countryList) {
	// 		this.countryList = this.http.get<any>('api/GetCountryList')
	// 			.pipe(map(resp => {
	// 				return resp.data;
	// 			}),
	// 				publishReplay(1),
	// 				refCount()
	// 			);
	// 	}
	// 	return this.countryList
	// }

	getRoles(): Observable<any> {
		return this.http.get('api/Roles')
			.pipe(
				map(res => res)
			);
	}

	getStateList(countryId: number): Observable<any> {
		return this.http.get('api/GetStateList/?CountryId=' + countryId)
			.pipe(
				map(res => res)
			);
	}

	getCityList(stateId: number): Observable<any> {
		return this.http.get('api/GetCityList?StateId=' + stateId)
			.pipe(
				map(res => res)
			);
	}

	getLocality(CityId): Observable<any> {
        return this.http.get<any>('/api/GetLocalityList?CityId=' + CityId)
            .pipe(map(res => {
                return res.data;
            }));
    }

	getUsers(): Observable<any> {
		return this.http.get('api/Users?From=1&To=-1')
			.pipe(
				map(res => res)
			);
	}

	getTerritory(): Observable<any> {
		return this.http.get('api/CRM/Territories?from=1&text=&to=-1')
			.pipe(
				map(res => res)
			);
	}

	addUser(userDetails): Observable<any> {
		return this.http.post('api/Users', userDetails)
			.pipe(
				map(res => res)
			);
	}

	deleteUser(Id): Observable<any> {
		return this.http.delete('api/Users/' + Id);
	}

	getUserDetails(Id: number): Observable<any> {
		return this.http.get('api/Users/' + Id)
			.pipe(
				map(res => res)
			);
	}

	updateUser(userDetails): Observable<any> {
		return this.http.put('api/Users/' + userDetails.UserId, userDetails)
			.pipe(
				map(res => res)
			);
	}

	assignManager(managerDetails): Observable<any> {
		return this.http.post('api/CRM/UpdateManager', managerDetails)
			.pipe(
				map(res => res)
			);
	}

	getTeamMembers(from, to, searchText) {
		const params = {
			From: from,
			To: to,
			Text: searchText
		}
		return this.http.post('api/CRM/NotTeamMembers', params)
			.pipe(
				map(res => res)
			);
	}

	assignTeam(teamDetails): Observable<any> {
		return this.http.post('api/CRM/AssignTeam', teamDetails)
			.pipe(
				map(res => res)
			);
	}

	getManagers(): Observable<any> {
		return this.http.get("api/Users/GetManagers").pipe(map((res) => res));
	}

	getManager(data): Observable<any> {
		return this.http.get('api/Users?From=' + data.From + '&SearchText=' + data.searchText + '&To=' + data.To)
			.pipe(
				map(res => res)
			);
	}

	deleteManager(forUserId,managerId):Observable<any>{
		const params={
			forUserId:forUserId,
			managerId:managerId
		};
		return this.http.put('api/Users/'+forUserId+'/DeleteManager/'+managerId,params)
		.pipe(
			map(res => res)
		);
	}

	//ShowBulkUploadUserFileName
	ShowBulkUploadUserFileName(loadtype: string,from: number, to: number, search: string):Observable<any>{
        return  this.http.get('api/Users/BulkUpload/FileName?loadtype='+loadtype+'&from=' +from + '&text=' + search + '&to=' + to)
        .pipe(map(resp=>{
            return resp;
        }));
	}

	//ShowBulkUploadViewReport
	ShowBulkUploadViewReport(FileId:number,from: number, to: number, search: string):Observable<any>{
		return this.http.get('api/Users/BulkUpload/UsersViewReport?Id='+FileId +'&from=' +from + '&text=' + search + '&to=' + to)
		.pipe(map(resp=>{
            return resp;
        }));
	}

	//BulkUploadUserReport
	BulkUploadUserReport(FileId):Observable<any>{
		return this.http.post('api/Users/BulkUpload/ExportUsersReport/'+FileId,FileId,{responseType:'arraybuffer'})
		.pipe(
			map((res) => {
				return res;
			}),
			tap(
				data => this.excelService.saveAsExcelFile(data, 'BulkUploadUsersReport'),
				error => console.log(error),
			),
			catchError(err => {
				return null;
			})
		);
	}

	//BulkUpload_User_File_Report
	BulkUploadUserFileReport(FileId):Observable<any>{
		return this.http.post('api/Users/BulkUpload/ExportUsersFile/'+FileId,FileId,{responseType:'arraybuffer'})
		.pipe(
			map((res) => {
				return res;
			}),
			tap(
				data => this.excelService.saveAsExcelFile(data, 'BulkUploadUsersFileReport'),
				error => console.log(error),
			),
			catchError(err => {
				return null;
			})
		);
	}
}


