import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ExcelService } from '../_base/crud/services/excel.service';

@Injectable({
	providedIn: 'root'
})
export class LeavesService {

	constructor(private http: HttpClient,private excelService: ExcelService) { }

	findLeaves(Data): Observable<any> {
		return this.http.get('api/LeaveRequests?From=' + Data.From + '&FromDate='+Data.startDate+'&IsTeam=true&StatusIds='+Data.StatusIds+'&To=' + Data.To + '&ToDate='+Data.endDate+'&UserIds='+Data.UserIds + '&LeaveTypeId='+Data.LeaveId)
			.pipe(
				map(res => res)
			);
	}
	findLeavesTwo(Data): Observable<any> {
		return this.http.get('api/LeaveRequests?From=' + Data.From + '&FromDate='+Data.startDate+'&IsTeam=false&StatusIds='+Data.StatusIds+'&To=' + Data.To + '&ToDate='+Data.endDate+'&UserIds='+Data.UserIds + '&LeaveTypeId='+Data.LeaveId)
			.pipe(
				map(res => res)
			);
	}
	deleteLeave(Id): Observable<any> {
		return this.http.delete('api/LeaveRequests/' + Id);
	}
	addLeave(leave): Observable<any> {
		return this.http.post('api/LeaveRequests', leave);
	}

	approve(Id,Action,Reason): Observable<any>{
		const params =
		{
			LeaveRequestId: Id,
			Action: Action,
			ReasonForRejection: Reason
		};
		return this.http.post('api/LeaveRequests/Approvals', params);
	}

	getLeaveTypes(): Observable<any>{
		return this.http.get('api/Leave/LeaveType').pipe(
			map(res => res)
		);
	}

	fileExport(leavesList): Observable<any> {
        const params =
        {
         'From': leavesList.From,
         'isTeam': true,
         'To': '-1',
		 'StatusIds':leavesList.StatusIds.length == 0 ? leavesList.StatusIds : (leavesList.StatusIds).join(','),
		 'FromDate': leavesList.startDate,
		 'ToDate': leavesList.endDate,
		 'LeaveTypeId': leavesList.LeaveId,
		 'UserIds' : leavesList.UserIds
        };
        return this.http.post('api/Leaves/ExportToExcel', params, { responseType: 'arraybuffer' })
            .pipe(
                map((res) => {
                    return res;
                }),
                tap(data => this.excelService.saveAsExcelFile(data, 'Leaves'),
                    error => console.log(error),
                ),
                catchError(err => {
                    return err;
                })
            );
    }
}
