import { Component, Inject, OnInit, Input, ChangeDetectionStrategy, forwardRef, ViewChild, ElementRef, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, FormArray, ValidatorFn, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { of, Subscription, Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
import { startWith, map } from 'rxjs/operators';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

export interface MultipleCheckListInputFormValues {
	searchMultipleCheckValue: number;
	searchMultipleCheckText: string;
}

@Component({
	selector: 'kt-search-multiple-check-list',
	templateUrl: './search-multiple-check-list.component.html',
	styleUrls: ['./search-multiple-check-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SearchMultipleCheckListComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => SearchMultipleCheckListComponent),
			multi: true,
		}
	]
})
export class SearchMultipleCheckListComponent implements OnInit {
	@ViewChild('listInput', { static: false }) listInput: ElementRef<HTMLInputElement>;
	@Input() placeholder: string;
	@Input() list: any = [];
	@Input() resValue: string = '';
	@Input() resText: string = '';
	@Input() isClear: boolean = false;
	visible = true;
	selectable = true;
	removable = true;
	addOnBlur = true;
	lists: any = [];
	form: FormGroup;
	filteredLists: Observable<string[]>;
	subscriptions: Subscription[] = [];

	get value(): MultipleCheckListInputFormValues {
		return this.form.value;
	}

	set value(value: MultipleCheckListInputFormValues) {
		if (value) { this.lists = value; }
		this.form.patchValue({
			searchMultipleCheckText: '',
			searchMultipleCheckValue: value,
		});
		this.onChange(value);
		this.onTouched();
	}

	constructor(private formBuilder: FormBuilder) {
		this.form = this.formBuilder.group({
			searchMultipleCheckText: [],
			searchMultipleCheckValue: [],
		});
		this.subscriptions.push(
			this.form.valueChanges.subscribe(value => {
				this.onChange(value);
				this.onTouched();
			})
		);
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges): void {

		if(this.isClear){
			this.lists = [];
		}
		this.filteredLists = this.form.get('searchMultipleCheckText').valueChanges.pipe(
			startWith(null),
			map((fruit: string | null) => fruit ? this._filter(fruit) : this.list.slice()));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	registerOnChange(fn) {
		this.onChange = fn;
	}

	writeValue(value) {
		if (value) {
			this.value = value;
		}
	}

	registerOnTouched(fn) {
		this.onTouched = fn;
	}

	validate(_: FormControl) {
		return this.form.valid ? null : { image: { valid: false, }, };
	}

	remove(fruit: string): void {
		const index = this.lists.indexOf(fruit);
		if (index >= 0) {
			this.lists.splice(index, 1);
		}
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		if (!this.lists.includes(event.option.value)) {
			this.lists.push(event.option.value);
		}
		this.form.patchValue({
			searchMultipleCheckValue: this.lists
		})
		this.listInput.nativeElement.value = '';
		this.form.controls['searchMultipleCheckText'].setValue(null);
	}

	private _filter(value: any): string[] {
		const filterValue = value;
		return this.list.filter(fruit => fruit[this.resText].toLowerCase().indexOf(filterValue) === 0);
	}
}