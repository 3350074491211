import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanDeactivate } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BillingComponent } from '../../../views/pages/billing/billing.component';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsGuard implements CanDeactivate<BillingComponent> {

  constructor(private _cookieService: CookieService, private router: Router) { }

  canDeactivate(component: BillingComponent): boolean {
    let value = this._cookieService.get('expiry')
    if (value == 'true') {
      this.router.navigate(['/billing'])
      return false
    } else {
      return true
    }
  }
}
