import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, ElementRef, ViewEncapsulation } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
// Material
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ExpenseReportPreviewDialogComponent } from '../../../../pages/expense/expense-report-preview-dialog/expense-report-preview-dialog.component';

@Component({
  selector: 'kt-image-slider',
  templateUrl: './image-slider.component.html',
  // styleUrls: ['./image-slider.component.scss'],
  styles: [
    `
      .remove{
        position: absolute;
        z-index: 9999;
        left: 92%;
        margin-top: -15px;
        color: gray;
        cursor: pointer;
        background-color: #f2f2f2;
        width: 22px;
        border-radius: 50%;
        padding: 2px 17px 5px 9px;
        font-size: 18px;
        text-align: center;
    }
    
    .img-slider .carousel-inner{
        overflow: visible!important;
    }
    
    .carousel{
        outline: none!important;
    }
      `
  ],
  providers: [NgbCarouselConfig],
  encapsulation: ViewEncapsulation.None
})
export class ImageSliderComponent implements OnInit {

  @Input() imageUrls: Array<any>;
  @Input() height: number;
  @Input() width: number;
  @Input() action: any;
  @Output() RemoveImageIds = new EventEmitter<number>();
  @Input() download: boolean;
  @Input() zoom: boolean;
  @Input() rotate: boolean;
  imgHeight:any;
  state: string = 'default';
  openPreview:boolean = false;
  ImageIds: number[] = [];

  constructor(config: NgbCarouselConfig, private eleref: ElementRef, private dialog: MatDialog) {
    config.interval = 0;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = false;
    config.showNavigationIndicators = false;
  }

  count: number = 1;
  classIndex: number = 0;
  lastClassIndex: number = 0;

  ngOnInit() {
    this.imgHeight = this.height
    console.log(this.action);
    console.log(this.height);
    
  }

  rotateImage(i) {
    this.classIndex = i;
    if (this.classIndex != this.lastClassIndex) {
      this.count = 1;
    }
    if (this.count <= 4) {
      switch (this.count) {
        case 1:
          this.eleref.nativeElement.querySelector(".imageRotate_" + i).style.transform = 'rotate(90deg)';
          break;
        case 2:
          this.eleref.nativeElement.querySelector(".imageRotate_" + i).style.transform = 'rotate(180deg)';
          break;
        case 3:
          this.eleref.nativeElement.querySelector(".imageRotate_" + i).style.transform = 'rotate(270deg)';
          break;
        case 4:
          this.eleref.nativeElement.querySelector(".imageRotate_" + i).style.transform = 'rotate(0deg)';
          break;
      }
      this.count = this.count + 1;
    }
    if (this.count > 4) {
      this.count = 1;
    }
    this.lastClassIndex = this.classIndex;
  }

  ImagePreview(value) {
    var ImageArray = [];
    this.openPreview = true;
    for (let i = 0; i < value.length; i++) {
      if (value[i].ImagePath.includes('https://')) {
        ImageArray.push(value[i].ImagePath)
      } else {
        var file = value[i].file;
        var reader = new FileReader();
        reader.onload = (e: any) => {
          var binaryData = e.target.result;
          var base64String = btoa(binaryData);
          var imagePath = base64String;
          ImageArray.push(imagePath);
        }
        reader.readAsBinaryString(file);
      }
    }
    setTimeout(() => {
      this.openPreview = false;
      this.dialog.open(ExpenseReportPreviewDialogComponent, { data: ImageArray, width: '800px' });
    }, 1500)

  }

  removeImage(Id: number) {
    this.RemoveImageIds.emit(Id);
  }
  zoomIn() {
    this.imgHeight = this.imgHeight * 1.5
  }
  zoomOut() {
    if (this.imgHeight < 200)
      return

    else {
      this.imgHeight = this.imgHeight / 1.5
    }
  }
}
