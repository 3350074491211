// Angular
import { Component, Inject, OnInit, ChangeDetectionStrategy, Input, forwardRef, OnDestroy, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import { SingleCheckListDialogComponent } from '../single-check-list-dialog/single-check-list-dialog.component';

export interface SingleCheckListInputFormValues {
	singleCheckInputValue: number;
	singleCheckInputText: string;
}

@Component({
	selector: 'kt-single-check-list-input',
	templateUrl: './single-check-list-input.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SingleCheckListInputComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => SingleCheckListInputComponent),
			multi: true,
		}
	]

})
export class SingleCheckListInputComponent implements ControlValueAccessor, OnChanges, OnDestroy {

	@ViewChild('imageFileInput', { static: true }) imageFileInput: ElementRef;
	@Input() placeholder: string;
	@Input() list: any = [];
	@Input() resValue: string = '';
	@Input() resText: string = '';
	@Input() customer: any;
	displaySelectedListValue: boolean = false;

	form: FormGroup;
	subscriptions: Subscription[] = [];

	get value(): SingleCheckListInputFormValues {
		return this.form.value;
	}

	set value(value: SingleCheckListInputFormValues) {
		this.form.setValue(value);
		this.onChange(value);
		this.onTouched();
	}

	constructor(
		private dialog: MatDialog,
		private formBuilder: FormBuilder,
		private translate: TranslateService,
	) {
		this.form = this.formBuilder.group({
			singleCheckInputValue: '',
			singleCheckInputText: ''
		});

		this.subscriptions.push(
			this.form.valueChanges.subscribe(value => {
				this.onChange(value);
				this.onTouched();
			})
		);
	}

	ngOnChanges() {
		this.form.patchValue({
			singleCheckInputValue: this.customer.UserId,
			singleCheckInputText: this.customer.UserName
		});
		this.displaySelectedListValue = true;
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	registerOnChange(fn) {
		this.onChange = fn;
	}

	writeValue(value) {
		if (value) {
			this.value = value;
		}
	}

	registerOnTouched(fn) {
		this.onTouched = fn;
	}

	validate(_: FormControl) {
		return this.form.valid ? null : { image: { valid: false, }, };
	}

	/**
	 * Open select list
	 */
	openList() {
		const title: string = this.translate.instant('Users');
		const list = this.list;
		const value = this.resValue;
		const text = this.resText;
		const submitButtonText = this.translate.instant('Done');

		const dialogRef = this.dialog.open(SingleCheckListDialogComponent, {
			data: { title, list, submitButtonText, text, value },
			width: '370px'
		});
		dialogRef.afterClosed().subscribe(res => {
			if (!res)
				return;
			this.form.patchValue({
				singleCheckInputValue: res[this.resValue],
				singleCheckInputText: res[this.resText],
			});
			this.displaySelectedListValue = true;
		});
	}

	/**
	 * Remove select list value
	 */
	removeSelectedListValue() {
		this.form.patchValue({
			singleCheckInputValue: '',
			singleCheckInputText: '',
		});
		this.displaySelectedListValue = false;
	}
}