import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpportunitiesService {
  private lostreasonList =new BehaviorSubject<any>(null)
moveLostReason=this.lostreasonList.asObservable()

private opportunityId=new BehaviorSubject<any>(null)
getOpportunitiesId=this.opportunityId.asObservable()

private SelectedReasonList =new BehaviorSubject<any>(null)
selectedReasonsLists=this.SelectedReasonList.asObservable()

  constructor(private http: HttpClient) { }

  getCustomerList(params): Observable<any> {
    return this.http.post('api/CRM/Leads', params)
      .pipe(map(
        res => res
      ))
  }

  getTagList(): Observable<any> {
    return this.http.get('api/CRM/Tags')
      .pipe(map(
        res => res
      ))
  }

  leads$:Observable<any>;

  getLeads(params): Observable<any> {
    if(!this.leads$){
      this.leads$ = this.http.post('api/CRM/Leads', params).pipe(shareReplay(1))
    }
    return this.leads$
  }

  getTeam(): Observable<any> {
    const params = {
      From: 1,
      To: -1,
      Text: '',
    }
    return this.http.post('api/CRM/MyTeam', params)
  }

  getTag(): Observable<any> {
    return this.http.get('api/CRM/Tags')
  }

  create(Data): Observable<any> {
    return this.http.post('api/CRM/Opportunity', Data)
  }

  update(Id, Data): Observable<any> {
    return this.http.post('api/CRM/Opportunity/' + Id, Data)
  }

  getData(params): Observable<any> {
    console.log(params)
    return this.http.get('api/CRM/OpportunitiesByStatus?AssignedToUserIds=' + params.AssignedToUserIds + '&FromDate=' + params.FromDate + '&LeadIds=' + params.LeadIds + '&TagIds=' + params.TagIds + '&ToDate=' + params.ToDate+'&dateType='+params.DateType)
  }

  getVisitData(Id: number): Observable<any> {
    return this.http.get('api/CRM/Opportunities/' + Id)
  }

  delete(Id): Observable<any> {
    return this.http.delete('api/CRM/Opportunity/' + Id)
  }

  updateOpportunitiesDrag(opportunitiesDetails): Observable<any> {

    return this.http.post('api/CRM/Opportunity/' + opportunitiesDetails.Id, opportunitiesDetails)
      .pipe(
        map(res => res)
      );
  }
  getCustomerStatus(): Observable<any> {
    return this.http.get<any>('api/CRM/LeadStatus')
      .pipe(map(resp => {
        return resp.data;
      }));
  }
  findProducts(from: number, to: number, search: string): Observable<any> {
    const params =
    {
      'from': from,
      'to': to,
      'text': search
    };
    return this.http.get('api/CRM/MasterProducts?From=' + from + '&Text=' + search + '&To=' + to)
      .pipe(
        map(res => res)
      );
  }
  getAllLostReason(): Observable<any> {
    return this.http.get<any>('api/CRM/OpportunityReasons')
  }

  getSelectedReason(id:any): Observable<any> {
    return this.http.get<any>(`api/CRM/Opportunities/${id}`)
  }

  createLostReason(reason) {
    return this.http.post('api/CRM/Opportunity', reason)
      .pipe(
        map(res => res)
      );
  }

  getMyData(): Observable<any> {
		return this.http.get<any>('api/CRM/OpportunitySetting');
	}

  getLostReasonListinMove(opportunityStatusId:number){
    this.lostreasonList.next(opportunityStatusId)
  }

  getOpportunityId(Id:number){
    this.opportunityId.next(Id)
  }

  createSelectedReasonsList(Value:any){
    this.SelectedReasonList.next(Value)
  }
}
