import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { finalize, takeUntil, tap, map, startWith, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { AuthNoticeService, AuthService } from '../../../../core/auth';
import { AddressService } from '../../../../core/_base/crud';
import { Subject, Observable } from 'rxjs';
import { LayoutConfigModel, LayoutConfigService } from '../../../../core/_base/layout';
import { type } from 'jquery';

@Component({
	selector: 'kt-register',
	templateUrl: './register.component.html',
	encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit, OnDestroy {
	model: LayoutConfigModel;
	registerForm: FormGroup;
	loading = false;
	errors: any = [];
	countryList = [];
	emailMessages: string;
	private unsubscribe: Subject<any>;
	filteredOptions: any[] = []
	filterArray = []
	@ViewChild('expenseApprovers', { static: false }) expenseApprovers;
	captchaConfig: any = {
		type: 1,
		length: 6,
		cssClass: 'custom',
		back: {
			stroke: "#2F9688",
			solid: "#e8f0fe"
		},
		font: {
			color: "#000000",
			size: "20px"
		}
	};
	captchaResult: any;

	constructor(
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private auth: AuthService,
		private address: AddressService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private layoutConfigService: LayoutConfigService
	) {
		this.unsubscribe = new Subject();
	}

	ngOnInit() {
		this.model = this.layoutConfigService.getConfig();
		this.initRegisterForm();
		this.getCountryList();
	}

	getResult(event) {
		this.captchaResult = event;
	}

	ngOnDestroy(): void {
		this.authNoticeService.setNotice('');
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
		this.captchaResult = '';
	}

	getCountryList() {
		this.address.getCountry()
			.pipe(map(data => {
				this.countryList = data;
				this.filteredOptions = data;
				this.filterArray = data;
				const toSelect = this.countryList.find(c => c.CountryId == 101);
				this.registerForm.get('countrycode').setValue(toSelect);
				this.cdr.detectChanges();
			}))
			.subscribe();
	}

	initRegisterForm() {
		this.registerForm = this.fb.group({
			name: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			countrycode: [null, Validators.required],
			countryfilter: [this.countryList],
			mobileno: ['', Validators.compose([
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(10)
			])
			],
			emailid: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320)
			]),
			],
		});
		this.setCountryWiseMobileNoValidators();

		//	this.registerForm.controls.countryfilter.valueChanges.subscribe(res=>
		//		res ? this.filteredOptions = this._filter(res) : this.filteredOptions = this.countryList

		//	 );

	}

	submit() {
		const controls = this.registerForm.controls;
		if (this.registerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		if (this.captchaResult == "true") {
			this.loading = true;
			this.registerForm.value.countrycode = controls['countrycode'].value.CountryCode;
			this.auth.register(this.registerForm.value).pipe(
				tap(user => {
					if (user.Status == 200) {
						//this.store.dispatch(new Register({ authToken: user.accessToken }));
						// pass notice message to the login page
						//this.authNoticeService.setNotice(this.translate.instant('AUTH.REGISTER.SUCCESS'), 'success');
						this.auth.changeEmailOTPMessage(this.registerForm.value.mobileno, user.data);
						this.router.navigateByUrl('/auth/reset-password');
					} else {
						this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
					}
				}),
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				})
			).subscribe();
		} else if(this.captchaResult === "false"){
			this.authNoticeService.setNotice("Please Enter Correct Captcha", 'danger');
		}else if(this.captchaResult === "empty"){
			// console.log("capta empty");
			this.authNoticeService.setNotice("Please Enter Captcha", 'danger');
		}

	}

	setCountryWiseMobileNoValidators() {
		const mobileNoControl = this.registerForm.get('mobileno');

		this.registerForm.get('countrycode').valueChanges
			.subscribe(data => {
				if (data.CountryCode == '+91') {
					mobileNoControl.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(10)]);
				} else {
					mobileNoControl.setValidators([Validators.minLength(6)]);
				}
				mobileNoControl.updateValueAndValidity();
			});
	}

	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.registerForm.controls[controlName];
		if (!control) {
			return false;
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	filterValue(searchText) {
		if (searchText != '') {
			this.filterArray = this.countryList.filter(option => { return option.Name.toLowerCase().startsWith(searchText.toLowerCase()) })

		}
		else {
			this.filterArray = this.countryList
		}
	}

	_filter(value) {
		let filterValue: string;
		if (typeof value == "object" && value.Name) {
			filterValue = value.Name
		} else {
			filterValue = value
		}
		//const filterValue = value.toLowerCase();
		if (filterValue) {
			return this.countryList.filter(option => option.Name.toLowerCase().startsWith(filterValue.toLowerCase()));
		}
	}
	getoptionText(option) {
		return option.Name;
	}


}