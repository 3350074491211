import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[AppDateFormatter]',
  exportAs: 'AppDateFormatter'
})
export class AppDateFormatterDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value) {
    this.el.nativeElement.value = this.formatDate(value);
  }

  private formatDate(dateString: string): string {
    const date = new Date(dateString);
    return `${this.formatMonth(date.getMonth())} ${date.getDate()}`;
  }

  private formatMonth(month: number): string {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return months[month];
  }
}