import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Person } from './myreferrals.model';
import { ExcelService } from '../_base/crud/services/excel.service';
import { environment } from '../../../environments/environment.prod'

@Injectable({
	providedIn: 'root'
})

export class MyreferralsService {

	public person: Person[] = [
		{ value: false, viewValue: 'Individual' },
		{ value: true, viewValue: 'Company' },
	];

	constructor(private http: HttpClient,
		private excelService: ExcelService) { }

	getMyReferral(referralList): Observable<any> {

		const params = {
			'userId': referralList.userId == '' ? 0 : referralList.userId,
			'from': referralList.from,
			'to': referralList.to,
			'search': referralList.search == '' ? null : referralList.search,
			'statusIds': referralList.statusIds == '' ? null : referralList.statusIds,
			'fromDate': referralList.fromDate == '' ? null : referralList.fromDate,
			'toDate': referralList.toDate == '' ? null : referralList.toDate,
			'userStatus': true
		}
		return this.http.post('referral/ReferralUser/ReferralUsers', params)
			.pipe(
				map(res => res)
			);
	}

	getReferralDetails(Id: number): Observable<any> {
		return this.http.get('referral/ReferralUser/' + Id)
			.pipe(
				map(res => res)
			);
	}

	addreferral(params: any): Observable<any> {
		return this.http.post('referral/ReferralUser/ReferralUser', params);
	}
	// removed
	// editReferal(id:number):Observable<any>{
	// 	return this.http.get('referral/ReferralUserDetails/'+id)
	// }

	deletereferral(userId): Observable<any> {
		return this.http.delete('referral/ReferralUser/' + userId, {})
			.pipe(
				map(res => res)
			);
	}

	updateReferral(params: any, referralUserId: number) {
		return this.http.put('referral/ReferralUser/ReferralUser/' + referralUserId, params)
			.pipe(
				map(res => res)
			);
	}


	getreferrallink(): Observable<any> {
		return this.http.get('referral/ReferralRedirect/SignUp');
	}


	fileExport(referralFilterList): Observable<any> {
		const params = {
			'from': 1,
			'to': -1,
			'search': referralFilterList.search == '' ? null : referralFilterList.search,
			'statusIds': referralFilterList.statusIds == '' ? null : referralFilterList.statusIds,
			'fromDate': referralFilterList.fromDate == '' ? null : referralFilterList.fromDate,
			'toDate': referralFilterList.toDate == '' ? null : referralFilterList.toDate,
			'userStatus': true
		}
		//
		return this.http.post('referral/ReferralUser/Export', params, { responseType: 'arraybuffer' })
			.pipe(
				map((res) => {
					return res;
				}),
				tap(
					data => {

						this.excelService.saveAsExcelFile(data, 'Referral')
					},
					error => console.log(error),
				),
				catchError(err => {
					return null;
				})
			);
	}

	// fileExport2(referralFilterList) : Observable<any>{
	// 	const params = {
	// 		'from': referralFilterList.from,
	// 		'to': referralFilterList.to,
	// 		'search': referralFilterList.search,
	// 		'statusIds': referralFilterList.paymentStatusId,
	// 		'fromDate': referralFilterList.fromDate,
	// 		'toDate': referralFilterList.toDate,
	// 		'myProperty': referralFilterList.myProperty,
	// 		'referralCode': referralFilterList.referralCode
	// 	}
	// 	return this.http.get('referral/api/mybusiness/Export?dto.from=' + params.from + '&dto.to=' + params.to + '&dto.search=' + params.search + '&dto.paymentStatusId=' + params.statusIds + '&dto.fromDate=' + params.fromDate + '&dto.toDate=' + params.toDate+'&dto.referralCode=' + params.referralCode,{ responseType: 'arraybuffer' })
	// 	.pipe(
	// 		map((res) => {
	// 			return res;
	// 		}),
	// 		tap(
	// 			data => this.excelService.saveAsExcelFile(data, 'Business'),
	// 			error => console.log(error),
	// 		),
	// 		catchError(err => {
	// 			return null;
	// 		})
	// 	);
	// }

	getreferralList(referralCode) {
		return this.http.get('referral/api/myreferral/GetReferralByReferralCode?referralCode=' + referralCode)
			.pipe(
				map(res => res)
			);
	}

	getCompanyByReferralCode(customer) {
		return this.http.get('api/CRM/Leads' + customer)
			.pipe(
				map(res => res)
			);
	}

	addCustomer(customer) {
		return this.http.post('referral/api/myreferral/AddCustomer', customer);
	}

	editCustomer(customer) {
		return this.http.post('referral/api/myreferral/EditCustomer', customer);
	}





	// reject(userId, myReferralId, reason): Observable<any> {
	// 	return this.http.post('referral/api/myreferral/Reject?userId=' + userId + '&myReferralId=' + myReferralId + '&reason=' + reason, {});
	// }

	// approved(userId, myReferralId) {
	// 	return this.http.post('referral/api/myreferral/Approved?userId=' + userId + '&myReferralId=' + myReferralId, {});
	// }
	// // /referral/ReferralUser/ChangeReferralUserRequestStatus/{userId}
	changereferral(referralUserId, statusId, reason) {
		return this.http.get('referral/ReferralUser/' + referralUserId + '/Status' + '?userId=' + referralUserId + '&statusId=' + statusId + '&reason=' + reason);
	}


	getMockArray(): Person[] {
		return this.person;
	}



	getStatus(status) {
		return this.http.get('referral/api/referralStatus/Get', status)
			.pipe(
				map(res => res)
			)
	}

	getReasons(myReferralId) {
		return this.http.get('referral/api/referralRejection/Get?myReferralId=' + myReferralId)
			.pipe(
				map(res => res)
			);
	}

	getPaymentStatus() {
		return this.http.get('referral/api/referralPaymentStatus/Get')
			.pipe(
				map(res => res)
			);
	}

	getReferralDashboard(referralFilterList) {
		const params = {
			'from': referralFilterList.from,
			'to': referralFilterList.to,
			'search': referralFilterList.search,
			'statusIds': referralFilterList.statusIds,
			'fromDate': referralFilterList.fromDate,
			'toDate': referralFilterList.toDate,
			'myProperty': referralFilterList.myProperty,
			'userStatus': referralFilterList.userStatus
		}
		return this.http.get('referral/api/myreferral/GetMyReferralDashboard?dto.from=' + params.from + '&dto.to=' + params.to + '&dto.search=' + params.search + '&dto.statusIds=' + params.statusIds + '&dto.fromDate=' + params.fromDate + '&dto.toDate=' + params.toDate + '&dto.userStatus=' + params.userStatus)
			.pipe(
				map(res => res)
			);
	}
	userDeactivate(userId: number): Observable<any> {
		return this.http.put('api/Users/' + userId + '/Deactivate', userId);
	}

	userActivate(userId: number): Observable<any> {
		return this.http.put('api/Users/' + userId + '/activate', userId);
	}

	getIFSCCode(ifsc: any): Observable<any> {
		return this.http.get('referral/ReferralUser/GetIFSCDetails?code=' + ifsc);
	}
}
