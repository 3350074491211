import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AuthNoticeService, AuthService } from '../../../../core/auth';
import { LayoutConfigModel, LayoutConfigService } from '../../../../core/_base/layout';

@Component({
	selector: 'kt-forgot-password',
	templateUrl: './forgot-password.component.html',
	encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	model: LayoutConfigModel;
	forgotPasswordForm: FormGroup;
	loading = false;
	errors: any = [];
	private unsubscribe: Subject<any>;
	captchaConfig: any = {
		type: 1,
		length: 6,
		cssClass: 'custom',
		back: {
			stroke: "#2F9688",
			solid: "#e8f0fe"
		},
		font: {
			color: "#000000",
			size: "20px"
		}
	};
	captchaResult: any;

	constructor(
		private authService: AuthService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private layoutConfigService: LayoutConfigService
	) {
		this.unsubscribe = new Subject();
	}

	ngOnInit() {
		this.model = this.layoutConfigService.getConfig();
		this.initForgotPasswordForm();
	}
	getResult(event) {
		this.captchaResult = event;
	}
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
		this.captchaResult = '';
		this.authNoticeService.setNotice('');
	}

	initForgotPasswordForm() {
		this.forgotPasswordForm = this.fb.group({
			email_mobile: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(320)
			])]
		});
	}

	submit() {
		const controls = this.forgotPasswordForm.controls;
		if (this.forgotPasswordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		if (this.captchaResult == "true") {
			this.loading = true;
			const email_mobile = controls['email_mobile'].value;
			this.authService.generateLoginOTP(email_mobile).pipe(
				tap(response => {
					if (response.success) {
						// this.authNoticeService.setNotice(this.translate.instant('AUTH.FORGOT.SUCCESS'), 'success');
						// this.router.navigateByUrl('/auth/login');
						this.authService.changeEmailOTPMessage(response.email, '');
						this.router.navigateByUrl('/auth/reset-password');
					} else {
						this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.NOT_FOUND', { name: this.translate.instant('AUTH.INPUT._EMAILMOBILENO') }), 'danger');
					}
				}),
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				})
			).subscribe();
		} else if(this.captchaResult === "false"){
			this.authNoticeService.setNotice("Please Enter Correct Captcha", 'danger');
		}else if(this.captchaResult === "empty"){
			// console.log("capta empty");
			this.authNoticeService.setNotice("Please Enter Captcha", 'danger');
		}

	}

	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.forgotPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}
		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}
}