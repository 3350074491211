export class UserDetail {
    data: {
        City: string;
        ContactNo: string;
        Country: string;
        CountryCode: string;
        CreatedDate: string;
        Dob: string;
        Email: string;
        Gender: string;
        IsActive: boolean
        MobileNo: string;
        ModifiedDate: string;
        Name: string;
        State: string;
        StreetAddress: string;
        UserId: number;
        UserImage: string;
        ZipCode: number;
    }

    clear() {
        this.data.City = '';
        this.data.ContactNo = '';
        this.data.Country = '';
        this.data.CountryCode = '';
        this.data.CreatedDate = '';
        this.data.Dob = '';
        this.data.Email = '';
        this.data.Gender = '';
        this.data.IsActive = false;
        this.data.MobileNo = '';
        this.data.ModifiedDate = '';
        this.data.Name = '';
        this.data.State = '';
        this.data.StreetAddress = '';
        this.data.UserId = undefined;
        this.data.UserImage = '';
        this.data.ZipCode = undefined;
    }
}
