import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, takeUntil, tap, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { AuthNoticeService, AuthService, Register, User } from '../../../../core/auth/';
import { Subject } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { LayoutConfigModel, LayoutConfigService } from '../../../../core/_base/layout';

@Component({
  selector: 'kt-reset-password',
  templateUrl: './reset-password.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  model: LayoutConfigModel;
  resetPasswordForm: FormGroup;
  loading = false;
  errors: any = [];
  emailMessages: string;
  textMessages: string;
  private unsubscribe: Subject<any>;

  constructor(
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private router: Router,
    private auth: AuthService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private layoutConfigService: LayoutConfigService
  ) {
    this.unsubscribe = new Subject();
    this.auth.currentEmailOTP.subscribe(data => {
      this.emailMessages = data.email;
      this.textMessages = data.textMessagae;
    })
    if (this.emailMessages) {
      const initialNotice = `OTP has been sent to Email Id / Mobile No - 
        <b>${this.emailMessages}</b> click 
        <b><span class='kt-pointer' (click)="generateLoginWithOTP(emailMessages)"> here
        </span></b>
         to resend.`;
      this.authNoticeService.setNotice(initialNotice, 'info');
    } else
      this.router.navigate(['/auth/login']);
  }

  ngOnInit() {
    this.model = this.layoutConfigService.getConfig();
    this.initResetPasswordForm();
  }

  ngOnDestroy(): void {
    this.authNoticeService.setNotice('');
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  initResetPasswordForm() {
    this.resetPasswordForm = this.fb.group({
      otp: ['', Validators.compose([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4)
      ])],
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(100)
      ])],
      confirmPassword: ['', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(100)
      ])],
      emailOrMobileNo: [this.emailMessages]
    }, {
      validator: ConfirmPasswordValidator.MatchPassword
    });
  }

  submit() {
    const controls = this.resetPasswordForm.controls;
    if (this.resetPasswordForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    this.loading = true;
    this.auth.resetPassword(this.resetPasswordForm.value).pipe(
      tap(user => {
        if (user.success) {
          this.authNoticeService.setNotice(this.translate.instant('AUTH.RESET.SUCCESS'), 'success');
          this.router.navigateByUrl('/auth/login');
        } else {
          this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_OTP'), 'danger');
        }
      }),
      takeUntil(this.unsubscribe),
      finalize(() => {
        this.loading = false;
        this.cdr.markForCheck();
      })
    ).subscribe();
  }

  // generateLoginOTP() {
  //   console.log(this.emailMessages);
  //   this.auth.generateLoginOTP(this.emailMessages).subscribe();
  // }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.resetPasswordForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}