import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
	selector: "input[charactersOnly]",
	exportAs: "charactersOnly",
})
export class CharactersDirective {
	constructor(private _el: ElementRef) {}

	@HostListener("input", ["$event"]) onInputChange(event) {
		const initialValue = this._el.nativeElement.value;
		this._el.nativeElement.value = initialValue.replace(/[^a-zA-Z]*/g, "");
		if (initialValue !== this._el.nativeElement.value) {
			event.stopPropagation();
		}
	}
}
