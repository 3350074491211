import { BaseModel } from '../../_base/crud';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';
import { CompanyDetail } from './company-detail.model';
import { UserDetail } from './user-detail.model';

export class User extends BaseModel {
    success: boolean = false;
    userId: number;
    name: string;
    countrycode: string;
    mobileno: string;
    emailid: string;
    id: number;
    username: string;
    password: string;
    email: string;
    accessToken: string;
    refreshToken: string;
    roles: number[];
    pic: string;
    fullname: string;
    occupation: string;
    companyName: string;
    phone: string;
    UserTypeId: number;
    ImeiNo: string;
    OsType: string;
    DeviceToken: string;
    MobileBrand: string;
    MobileModel: string;
    MobileName: string;
    MobileCarrier: string;
    AppVersion: string;
    address: Address;
    socialNetworks: SocialNetworks;
    CompanyDetail: CompanyDetail;
    userDetail: UserDetail;

    clear(): void {
        this.success = false;
        this.userId = undefined;
        this.name = '';
        this.countrycode = '';
        this.mobileno = '';
        this.emailid = '';
        this.id = undefined;
        this.username = '';
        this.password = '';
        this.email = '';
        this.roles = [];
        this.fullname = '';
        this.accessToken = 'access-token-' + Math.random();
        this.refreshToken = 'access-token-' + Math.random();
        this.pic = './assets/media/users/default.jpg';
        this.occupation = '';
        this.companyName = '';
        this.phone = '';
        this.address = new Address();
        this.address.clear();
        this.socialNetworks = new SocialNetworks();
        this.socialNetworks.clear();
        this.CompanyDetail = new CompanyDetail();
        this.CompanyDetail.clear();
        this.userDetail = new UserDetail();
        this.userDetail.clear();
    }
}