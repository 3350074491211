import { Component, OnInit, ChangeDetectorRef, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { NgbDropdownConfig, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'kt-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [NgbDropdownConfig]
})
export class TimePickerComponent implements OnInit {

  constructor(
    private config: NgbDropdownConfig,
    private chRef: ChangeDetectorRef,
    private dialog: MatDialog,
  ) {
    config.autoClose = false;
    config.placement = 'bottom-center';
  }

  @Input('time') punchTime: any;
  @Input() isSchedule: boolean;
  @Output() selectedTime = new EventEmitter;
  showPicker: boolean = true;

  time = {
    hour: 0, 
    minute: 0
  };
  meridian = true;

  ngOnInit() {
    let currentTime = this.punchTime.split(':');
    this.time.hour = parseInt(currentTime[0]);
    this.time.minute = parseInt(currentTime[1]);
    this.chRef.detectChanges();
  }

  setTime(time){
    this.selectedTime.emit(time);
  }

}
