// Angular
import { Component, Inject, OnInit, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormControl, FormGroup, FormBuilder, FormArray, ValidatorFn } from "@angular/forms";
import { of } from "rxjs";

@Component({
	selector: "kt-multiple-check-list-dialog",
	templateUrl: "./multiple-check-list-dialog.component.html",
	styles: [".bodyHeight{ overflow: auto !important; height: 200px !important; }"],
})
export class MultipleCheckListDialogComponent implements OnInit {
	form: FormGroup;
	listData: any = [];
	selectedList: any = [];
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
	@Input() value: string = "";
	@Input() text: string = "";
	selectedData = {
		formattedText: "",
		formattedArray: [],
	};
	selectData = [];

	constructor(public dialogRef: MatDialogRef<MultipleCheckListDialogComponent>, private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any) {
		this.form = this.fb.group({
			list: new FormArray([]),
		});

		// synchronous list
		this.listData = this.data.list;
		this.selectData = this.data.selectedCollectionList;
		this.addCheckboxes();

		// async list
		// of(this.getOrders()).subscribe(list => {
		// 	this.listData = list;
		// 	this.addCheckboxes();
		// });
	}

	get formData() {
		return this.form.get("list") as FormArray;
	}

	private addCheckboxes() {
		this.listData.forEach((o, i) => {
			const control = new FormControl();
			if (this.selectData.includes(this.listData[i])) {
				control.setValue(true);
			}
			(this.form.controls.list as FormArray).push(control);
		});
	}

	ngOnInit() {
		this.viewLoading = true;
		setTimeout(() => {
			this.viewLoading = false;
		});
		this.text = this.data.text;
		this.value = this.data.value;
	}

	onNoClick(): void {
		const params = {
			selectedData: this.selectedData,
			selectedList: this.selectedList,
		};

		this.dialogRef.close(params);
	}

	submit() {
		this.selectedList = this.form.value.list.map((v, i) => (v ? this.listData[i] : null)).filter((v) => v !== null);
		this.selectedData.formattedText = this.selectedList.map((e) => e[this.data.text]).join(", ");
		this.selectedData.formattedArray = this.selectedList.map((e) => e[this.data.value]);
		this.viewLoading = true;
		this.loadingAfterSubmit = true;
		const params = {
			selectedData: this.selectedData,
			selectedList: this.selectedList,
		};
		setTimeout(() => {
			this.dialogRef.close(params);
		});
	}
}

function minSelectedCheckboxes(min = 1) {
	const validator: ValidatorFn = (formArray: FormArray) => {
		const totalSelected = formArray.controls.map((control) => control.value).reduce((prev, next) => (next ? prev + next : prev), 0);
		return totalSelected >= min ? null : { required: true };
	};
	return validator;
}
