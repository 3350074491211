import { BaseModel } from '../../../_base/crud';

export class AppointmentModel extends BaseModel {
	LeadId: number;
	UserId: number;
	Date: Date;
	LeadIdsWithDate: any

	clear() {
		this.LeadId = 0;
		this.UserId = 0;
		this.Date = new Date();
		this.LeadIdsWithDate = '';
	}
}