import { mergeMap, tap, catchError, finalize } from 'rxjs/operators';
// RxJS
import { delay, distinctUntilChanged, skip, filter, take, map } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';

import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AttendanceService } from '../_services/attendance.service';
import { AttendanceModel } from '../_models/attendance.model';
import { AuthService } from '../../../core/auth';

export class AttendanceDataSource extends BaseDataSource {
	
	private loadingSubject = new BehaviorSubject<boolean>(false);
	private isPreloadTextViewedSubject = new BehaviorSubject<boolean>(true);

	public loading$ = this.loadingSubject.asObservable();
	public isPreloadTextViewed$ = this.isPreloadTextViewedSubject.asObservable();

	currentUser: any;
	claimsData: any = [];

	constructor(private attendanceService: AttendanceService, private authService: AuthService) {
		super();
		this.authService.getCurrentUser().subscribe(x => { this.currentUser = x; });
	}

	loadMyClaims(data) {
		this.loadingSubject.next(true);

		this.attendanceService.getCheckInClaims(data)
			.pipe(
				map(
					claims => {
						this.paginatorTotalSubject.next(claims.data.TotalRecords);
						this.entitySubject.next(claims.data.Claims);
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
			.subscribe();
	}

	loadTeamClaims(data) {
		this.claimsData = [];
		this.loadingSubject.next(true);

		this.attendanceService.getCheckInClaims(data)
			.pipe(
				map(
					claims => {
						if(claims.data != ''){
							this.entitySubject.next(claims.data.Claims);
							this.paginatorTotalSubject.next(claims.data.TotalRecords);
						}
						else{
							claims = [];
							this.paginatorTotalSubject.next(claims.length);
							this.entitySubject.next(claims);
						}
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
			.subscribe();
	}

	loadUserSettingsReport(data) {
		this.loadingSubject.next(true);

		this.attendanceService.getUserSettingsReport(data)
			.pipe(
				map(
					usersettings => {
						if(usersettings.data != ''){
							this.entitySubject.next(usersettings.data.UserSettingList);
							this.paginatorTotalSubject.next(usersettings.data.TotalRecords);
						}
						else{
							usersettings = [];
							this.paginatorTotalSubject.next(usersettings.length);
							this.entitySubject.next(usersettings);
						}
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			).subscribe();
	}
}