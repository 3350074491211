import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, tap, catchError } from 'rxjs/operators';
import { ExcelService } from '../../_base/crud/services/excel.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private http: HttpClient, private excelService: ExcelService) { }
  load = new BehaviorSubject<boolean>(false)
  $load = this.load.asObservable();

  findMyTask(from, to, title, userId, isArchive, userIds, priority, StatusIds, FromDate, ToDate, SortColumn, SortOrder): Observable<any> {
    const params = {
      From: from,
      To: to,
      Title: title,
      UserId: userId,
      IsArchive: isArchive,
      UserIds: userIds,
      Priority: priority,
      TaskStatus: StatusIds,
      FromDueDate: FromDate,
      ToDueDate: ToDate,
      SortByDueDate: '',
      SortColumn: SortColumn,
      SortOrder: SortOrder
    }
    return this.http.post('api/Task/UserTasksAssignedToMe', params)
      .pipe(
        map(res => res)
      );
  }

  findCC(from, to, title, userId, isArchive, userIds, StatusIds,priority): Observable<any> {
    const params = {
      From: from,
      To: to,
      Title: title,
      UserId: userId,
      IsArchive: isArchive,
      UserIds: userIds,
      TaskStatus: StatusIds,
      Priority: priority
    }
    return this.http.post('api/Task/OwnerTasks', params)
      .pipe(
        map(res => res)
      );
  }

  findAssignedByMe(from, to, title, userId, isArchive, userIds, priority, StatusIds, FromDate, ToDate, SortColumn, SortOrder): Observable<any> {
    const params = {
      From: from,
      To: to,
      Title: title,
      UserId: userId,
      IsArchive: isArchive,
      UserIds: userIds,
      Priority: priority,
      TaskStatus: StatusIds,
      FromDueDate: FromDate,
      ToDueDate: ToDate,
      SortByDueDate: '',
      SortColumn: SortColumn,
      SortOrder: SortOrder
    }
    return this.http.post('api/Task/UserTasksAssignedByMe', params)
      .pipe(
        map(res => res)
      );
  }

  findArchiveList(from, to, title, userId, isArchive, userIds): Observable<any> {
    const params = {
      From: from,
      To: to,
      Title: title,
      UserId: userId,
      IsArchive: isArchive,
      UserIds: userIds
    }
    return this.http.post('api/Task/UserTasksAssignedByMe', params)
      .pipe(
        map(res => res)
      );
  }

  archiveTask(taskId: number, isArchive: boolean): Observable<any> {
    const params = {
      IsArchive: isArchive,
      TaskId: taskId
    }
    return this.http.post('api/Task/Archive', params)
      .pipe(
        map(res => res)
      );
  }

  acceptTask(taskId: number): Observable<any> {
    const params = {
      TaskId: taskId,
      TaskStatusValue: 0
    }
    return this.http.post('api/Task/UpdateTaskStatus', params)
      .pipe(
        map(res => res)
      );
  }

  completeTask(taskId: number): Observable<any> {
    const params = {
      TaskId: taskId,
      TaskStatusValue: 100
    }
    return this.http.post('api/Task/UpdateTaskStatus', params)
      .pipe(
        map(res => res)
      );
  }

  getTaskCoverage(taskId: number): Observable<any> {
    return this.http.get('api/Task/StatusReport?taskId=' + taskId)
      .pipe(
        map(res => res)
      );
  }

  getPartialCompleteStatus(): Observable<any> {
    return this.http.get('api/Task/UserTaskStatusMaster')
      .pipe(
        map(res => res)
      );
  }

  partialCompleteTask(taskId: number, taskStatusValue: number): Observable<any> {
    const params = {
      TaskId: taskId,
      TaskStatusValue: taskStatusValue
    }
    return this.http.post('api/Task/UpdateTaskStatus', params)
      .pipe(
        map(res => res)
      );
  }

  deleteTask(taskId: number): Observable<any> {
    return this.http.get('api/Task/DeleteTask?taskId=' + taskId)
      .pipe(
        map(res => res)
      );
  }

  deleteTaskStatus(taskId: number): Observable<any> {
    return this.http.get('api/Task/DeleteTaskStatusMaster?id=' + taskId)
      .pipe(
        map(res => res)
      );
  }

  addTaskStatus(Value): Observable<any> {
    return this.http.post('api/Task/AddTaskStatusMaster', Value)
      .pipe(
        map(res => res)
      );
  }

  getCompanyMembers(from, to, text): Observable<any> {
    return this.http.get('api/CompanyMembers?from=' + from + '&text=' + text + '&to=' + to)
      .pipe(
        map(res => res)
      );
  }

  addTask(taskDetails): Observable<any> {
    return this.http.post('api/Task/AssignTask', taskDetails)
      .pipe(
        map(res => res)
      );
  }

  updateTaskDetails(taskDetails): Observable<any> {
    return this.http.post('api/Task/UpdateTaskDetails', taskDetails)
      .pipe(
        map(res => res)
      );
  }

  updateOwnerTask(taskId, taskOwners): Observable<any> {
    const params = {
      Id: taskId,
      TaskOwners: taskOwners,
    }
    return this.http.post('api/Task/AddOwnersToTask', params)
      .pipe(
        map(res => res)
      );
  }

  addUsersExistingTask(taskId, taskOwners): Observable<any> {
    const params = {
      Id: taskId,
      IntercomGroupIds: [],
      UserIds: taskOwners
    }
    return this.http.post('api/Task/AddUsersToExistingTask', params)
      .pipe(
        map(res => res)
      );
  }

  removeUsersExistingTask(taskId, taskOwners): Observable<any> {
    const params = {
      Id: taskId,
      UserIds: taskOwners
    }
    return this.http.post('api/Task/RemoveUsersFromExistingTask', params)
      .pipe(
        map(res => res)
      );
  }

  takeUserList(from, to, name, taskId): Observable<any> {
    const params = {
      From: from,
      Name: name,
      TaskId: taskId,
      To: to
    }
    return this.http.post('api/Task/TaskUserList', params)
      .pipe(
        map(res => res)
      );
  }

  removeOwnerTask(taskId, taskOwners): Observable<any> {
    const params = {
      Id: taskId,
      TaskOwners: taskOwners,
    }
    return this.http.post('api/Task/RemoveOwnersFromExistingTask', params)
      .pipe(
        map(res => res)
      );
  }

  removeOwner(taskId: number, TaskOwners: any): Observable<any> {
    const params = {
      Id: taskId,
      TaskOwners: TaskOwners
    }
    return this.http.post('api/Task/RemoveOwnersFromExistingTask', params)
      .pipe(
        map(res => res)
      );
  }

  getTaskDetails(taskId: number): Observable<any> {
    return this.http.get('api/Task/UserTaskDetails?taskId=' + taskId)
      .pipe(
        map(res => res)
      );
  }

  getComments(from, to, text, taskId): Observable<any> {
    let userIds = [];
    const params = {
      From: from,
      To: to,
      Text: text,
      TaskId: taskId,
      UserIds: userIds
    }
    return this.http.post('api/Task/GetTaskComments', params)
      .pipe(
        map(res => res)
      );
  }

  addComment(comment, taskId, isActive, multimedia): Observable<any> {
    let params;
    if (multimedia == undefined) {
      params = {
        Comment: comment,
        IsActive: isActive,
        TaskId: taskId,
        UserId: 0,
        MentionUserId: -1,
        Latitude: 0,
        Longitude: 0,
        Location: '',
      }
    }
    else {
      params = {
        Comment: comment,
        IsActive: isActive,
        TaskId: taskId,
        UserId: 0,
        MentionUserId: -1,
        Latitude: 0,
        Longitude: 0,
        Location: '',
        MultimediaData: multimedia.MultimediaData,
        MultimediaExtension: multimedia.MultimediaExtension,
        MultimediaFileName: multimedia.MultimediaFileName,
        MultimediaType: multimedia.MultimediaType
      }
    }
    return this.http.post('api/Task/CommentOnTask', params)
      .pipe(
        map(res => res)
      );
  }

  fileExport(data, tabIndex): Observable<any> {
    if (tabIndex == '2' || tabIndex == '3') {
      return this.http.post('api/Task/UserTasksAssignedByMe/Export', data, { responseType: 'arraybuffer' })
        .pipe(
          map((res) => {
            return res;
          }),
          tap(
            data => this.excelService.saveAsExcelFile(data, 'Task'),
            error => console.log(error),
          ),
          catchError(err => {
            return null;
          })
        );
    } else {
      return this.http.post('api/Task/UserTasksAssignedToMe/Export', data, { responseType: 'arraybuffer' })
        .pipe(
          map((res) => {
            return res;
          }),
          tap(
            data => this.excelService.saveAsExcelFile(data, 'Task'),
            error => console.log(error),
          ),
          catchError(err => {
            return null;
          })
        );
    }

  }

  getCustomerList(params): Observable<any> {
    return this.http.post('api/CRM/Leads', params)
      .pipe(map(
        res => res
      ))
  }

}
