import { BaseModel } from '../../../_base/crud';

export class QuickTaskModel extends BaseModel {
	LeadId: number;
	ActionPointId: number;
	ActionPointText: string;
	NextActionDate: Date;
	IsAddQuickTaskOnly: boolean;
	AssignTaskToUserId: number;
	ProductIds: string;

	clear() {
		this.LeadId = 0;
		this.ActionPointId = 0;
		this.ActionPointText = '';
		this.NextActionDate = new Date();
		this.IsAddQuickTaskOnly = false;
		this.AssignTaskToUserId = 0;
		this.ProductIds = '';
	}
}