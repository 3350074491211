import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'textFormat'
})
export class TextFormatPipe implements PipeTransform {

	transform(objectValue: any, text: any): any {
		return objectValue[text];
	}
}