// Angular
import { Component, Inject, OnInit, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'kt-single-check-list-dialog',
	templateUrl: './single-check-list-dialog.component.html',
	styles: [`.sticky-top{
		position: sticky;
		top: -10px;
		z-index: 1020;
	
	}`]
})
export class SingleCheckListDialogComponent implements OnInit {
	filterArray: string[] = []
	selectedListData = new FormControl('');
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
	@Input() value: string = '';
	@Input() text: string = '';

	constructor(
		public dialogRef: MatDialogRef<SingleCheckListDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit() {
		this.filterArray = this.data.list
		this.viewLoading = true;
		setTimeout(() => {
			this.viewLoading = false;
		});
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	submit() {
		if (this.selectedListData.value.length === 0) {
			return;
		}

		this.viewLoading = true;
		this.loadingAfterSubmit = true;
		setTimeout(() => {
			this.dialogRef.close(this.selectedListData.value);
		});
	}
	filterValue(searchText) {
		if (searchText != '') {
			if (this.data.title == 'Sales Target') {
				this.filterArray = this.data.list.filter(option => { return option.FieldName.toLowerCase().startsWith(searchText.toLowerCase()) })
			}else if(this.data.title == 'Select Time Zone'){
				this.filterArray = this.data.list.filter(option => { return option.Name.toLowerCase().startsWith(searchText.toLowerCase()) })
			}
			else if(this.data.title == 'Select Currency'){
				this.filterArray = this.data.list.filter(option => { return option.CountryName.toLowerCase().startsWith(searchText.toLowerCase()) })
			}
			else {
				this.filterArray = this.data.list.filter(option => { return option.UserName.toLowerCase().startsWith(searchText.toLowerCase()) })
			}
		}
		else {
			this.filterArray = this.data.list
		}
	}
}