import { AuthGateway } from "../gateway/auth-gateway";
import { Observable } from "rxjs";
import { User } from '../auth/_models/user.model';

export class AuthUsecase {
    constructor(private authGateway:AuthGateway) { }

    login(username,password):Observable<User>{
        return this.authGateway.login(username,password)
    }
    register(user:User):Observable<User>{
        return this.authGateway.register(user)
    }

    resetPassword(user:User):Observable<User>{
        return this.authGateway.resetPassword(user)
    }
    generateLoginOTP(user:User):Observable<User>{
        return this.authGateway.generateLoginOTP(user)
    }

    loginWithOTP(user:User):Observable<User>{
        return this.authGateway.loginWithOTP(user)
    }
    
}