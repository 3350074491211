import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'customStringToDate'
})
export class CustomStringToDatePipe implements PipeTransform {
    // adding a default value in case you don't want to pass the format then 'yyyy-MM-dd' will be used
    transform(date: Date | string, day: number, format: string = 'd MMM y'): string {
        let dateString = date;
        dateString = dateString.toString();
        let dateArray = dateString.split(',');
        let newDateArray: string[] = [];
        dateArray.forEach(element => {
            let newDate = new Date(element);
            newDate.setDate(newDate.getDate() - day);
            let customDate = new DatePipe('en-us').transform(newDate, format);
            newDateArray.push(customDate);
        });
        let finalDate = newDateArray.join(' , ');
        return finalDate;
    }
}