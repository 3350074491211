import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'totalHours'
})
export class TotalHoursPipe implements PipeTransform {

    transform(maxdate: Date | string, mindate: Date | string): string {
        var time = new Date(maxdate).getTime() - new Date(mindate).getTime();
        return this.msToTime(time)
    }

    msToTime(seconds) {
        var milliseconds = seconds % 1000;
        seconds = (seconds - milliseconds) / 1000;
        var secs = seconds % 60;
        seconds = (seconds - secs) / 60;
        var minutes = seconds % 60;
        var hours = (seconds - minutes) / 60;
        return hours + (hours > 1 ? 'hrs' : 'hr') + ' ' + minutes + (minutes > 1 ? 'mins' : 'min') + ' ' + secs + (secs > 1 ? 'secs' : 'sec');
    }
}