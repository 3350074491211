import { catchError, finalize } from 'rxjs/operators';
// RxJS
import { map } from 'rxjs/operators';
// CRUD
import { BaseDataSource } from '../../../_base/crud';
import { BehaviorSubject, of } from 'rxjs';
import { CustomersService } from '../_services';

export class CustomersDataSource extends BaseDataSource {

	private loadingSubject = new BehaviorSubject<boolean>(false);
	private isPreloadTextViewedSubject = new BehaviorSubject<boolean>(true);

	public loading$ = this.loadingSubject.asObservable();
	public isPreloadTextViewed$ = this.isPreloadTextViewedSubject.asObservable();

	constructor(private customersService: CustomersService) {
		super();
	}

	loadCustomers(customer) {
		this.loadingSubject.next(true);

		this.customersService.findCustomers(customer)
			.pipe(
				map(
					customers => {
						this.entitySubject.next(customers.data.Leads);
						this.paginatorTotalSubject.next(customers.data.TotalRecords);
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
			.subscribe();
	}

	loadCustomersBulkReport(loadtype,from,to,search){
		this.loadingSubject.next(true);
		this.customersService.bulkreport(loadtype,from,to,search)
		.pipe(
			map(
				report => {
					this.entitySubject.next(report.data.CustomersReport);
					this.paginatorTotalSubject.next(report.data.TotalRecords);
				}
			),
			catchError(() => of([])),
			finalize(() => {
				this.loadingSubject.next(false);
				this.isPreloadTextViewedSubject.next(false);
			})
		)
		.subscribe();
	}

	viewCustomerBulkReport(id,from,to,search){
		this.loadingSubject.next(true);
		this.customersService.ViewReport(id,from,to,search)
		.pipe(
			map(
				report => {
					this.entitySubject.next(report.Items);
					this.paginatorTotalSubject.next(report.TotalRecords);
				}
			),
			catchError(() => of([])),
			finalize(() => {
				this.loadingSubject.next(false);
				this.isPreloadTextViewedSubject.next(false);
			})
		)
		.subscribe();
	}
}