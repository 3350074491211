import { Component, OnInit ,Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { PreviewDialogComponent } from '../../details/preview/preview.dialog.component';
@Component({
  selector: 'kt-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss']
})
export class FormDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<FormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog) { }
  displayData: any;
  formSection:any;

  ngOnInit() {
    this.displayData = this.data.data;
    this.formSection = this.data.section;

  }
  previewImage(Image) {
    this.dialog.open(PreviewDialogComponent, { data:  [Image],width:'700px'});
  }

}
