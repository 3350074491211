import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
    // adding a default value in case you don't want to pass the format then 'yyyy-MM-dd' will be used
    transform(date: Date | string, day: number, format: string = 'd MMM y'): string {
        if (!date) {
            return '';
        }
        date = new Date(date);  // if orginal type was a string
        date.setDate(date.getDate() - day);
        return new DatePipe('en-US').transform(date, format);
    }
}