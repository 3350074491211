export class ProductMasterModel {
	Status: number;
	LeadId: number;
	Id: number;
	From: number;
	To: number;
	ProductId: number;
	SKU: string;
	ProductName: string;
	MRP: number;
	DealerPrice: number;
	OfferPrice: number;
	AvailableQty: number;
	TotalQty: number;
	Comments: string;
	CreatedBy: string;
	Date: Date;
	Title: string;
	Category: string;
	Description: string;

	clear() {
		this.Status = 0;
		this.LeadId = 0;
		this.Id = 0;
		this.From = 0;
		this.To = 0;
		this.ProductId = 0;
		this.SKU = '';
		this.ProductName = '';
		this.MRP = 0;
		this.DealerPrice = 0;
		this.OfferPrice = 0;
		this.AvailableQty = 0;
		this.TotalQty = 0;
		this.Comments = '';
		this.CreatedBy = '';
		this.Date = new Date();
		this.Title = '';
		this.Category = '';
		this.Description = '';
		
	}
}