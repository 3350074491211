import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OpportunitiesService } from '../../../../../core/crm/opportunities/_services/opportunities.service'

@Component({
  selector: 'kt-add-reason-dialog',
  templateUrl: './add-reason-dialog.component.html',
  styleUrls: ['./add-reason-dialog.component.scss']
})
export class AddReasonDialogComponent implements OnInit {
 checked:boolean=false
  reasonForm : FormGroup
  reasonList:any=[]
  reasonData:any=[]
  checkedIds:any=[]
  viewLoading:boolean;
  noRecords:boolean=false
  selectedReason=[]
  reasonCopyList: any;

  constructor(
    private fb : FormBuilder,
    private opportunitiesService: OpportunitiesService,
    public dialogRef: MatDialogRef<AddReasonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any=[]
  ) { }

  ngOnInit() {
    this.getAllLostReasons()
    this.reasonForm = this.fb.group({
      check : ['']
    });
  
  for(let i in this.data.checkedList){
    if(this.data.checkedList[i].ReasonId==undefined){
      this.selectedReason.push(this.data.checkedList[i])
    }
    else{
    this.selectedReason.push(this.data.checkedList[i].ReasonId)
  }
  }
  }

  searchMember(searchValue) {
    this.viewLoading = true;
    if (searchValue != '') {
      this.reasonList = this.reasonCopyList.filter(item => { return item.Reason.includes(searchValue) });
    }
    else{
      this.reasonList=this.reasonCopyList
    }
    this.viewLoading = false;
    if (this.reasonList.length > 0) {
      this.noRecords = false;
    }
    else {
      this.noRecords = true;
    }
  }

  checkedMember(event, reasonId: number) {
    if (event.checked) {
      this.selectedReason.push({
        ReasonId: reasonId
      });
    }
    else {
      if(!event.checked){
      let index: number = 0;
      for (let i = 0; i < this.selectedReason.length; i++) {
        if(this.selectedReason[i].ReasonId==undefined){
          index = this.selectedReason.findIndex(x => x == reasonId);
          break 
        }
        else{
        index = this.selectedReason.findIndex(x => x.ReasonId == reasonId);
        break
      }
      }
     this.selectedReason.splice(index, 1);
       }
    }
  }

  getAllLostReasons(){
    this.opportunitiesService.getAllLostReason().subscribe(result=>{
       this.reasonList=result.data
       if(this.reasonList.length>0){
        this.noRecords=false
       }
       else{
        this.noRecords=true
       }
      this.reasonCopyList=result.data
    })
  }

  onSubmit() {
    const reasons = this.selectedReason;
    this.dialogRef.close({ reasons, isEdit: true }) 
  }

  onNoClick() {
    this.dialogRef.close();
  }
}