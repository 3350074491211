// Angular
import { Component, Inject, OnInit, ChangeDetectionStrategy, Input, forwardRef, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// CRUD
import { LayoutUtilsService } from '../../../../../../core/_base/crud/utils/layout-utils.service';
import { MatDialog } from '@angular/material';
import { MultipleCheckListDialogComponent } from '../multiple-check-list-dialog/multiple-check-list-dialog.component';

// describes what the return value of the form control will look like
export interface MultipleCheckListInputFormValues {
	multipleCheckInputValue: number;
	multipleCheckInputText: string;
}

@Component({
	selector: 'kt-multiple-check-list-input',
	templateUrl: './multiple-check-list-input.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => MultipleCheckListInputComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => MultipleCheckListInputComponent),
			multi: true,
		}
	]

})
export class MultipleCheckListInputComponent implements ControlValueAccessor, OnDestroy {

	@ViewChild('imageFileInput', { static: true }) imageFileInput: ElementRef;
	@Input() placeholder: string;
	@Input() title: string;
	@Input() list: any = [];
	@Input() resValue: string = '';
	@Input() resText: string = '';
	@Input() isClear: boolean = false;
	selectedCollectionList: any = [];

	form: FormGroup;
	subscriptions: Subscription[] = [];

	get value(): MultipleCheckListInputFormValues {
		return this.form.value;
	}

	set value(value: MultipleCheckListInputFormValues) {
		this.form.setValue(value);
		this.onChange(value);
		this.onTouched();
	}

	constructor(
		private dialog: MatDialog,
		private formBuilder: FormBuilder,
		private translate: TranslateService,
		// private layoutUtilsService: LayoutUtilsService,
		// @Inject(forwardRef(() => LayoutUtilsService)) layoutUtilsService
	) {
		// create the inner form
		this.form = this.formBuilder.group({
			multipleCheckInputValue: [],
			multipleCheckInputText: []
		});

		this.subscriptions.push(
			// any time the inner form changes update the parent of any change
			this.form.valueChanges.subscribe(value => {
				this.onChange(value);
				this.onTouched();
			})
		);
	}

	ngOnChanges(){
		if(this.isClear){
			this.form.reset();
			this.selectedCollectionList = [];
		}
	}

	ngOnDestroy() {
		// this.form.reset();
		// this.form.controls['multipleCheckInputText']
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	registerOnChange(fn) {
		this.onChange = fn;
	}

	writeValue(value) {
		if (value) {
			this.value = value;
		}
	}

	registerOnTouched(fn) {
		this.onTouched = fn;
	}

	// communicate the inner form validation to the parent form
	validate(_: FormControl) {
		return this.form.valid ? null : { image: { valid: false, }, };
	}

	/**
	 * Open select list
	 */
	openList() {
		const title: string = this.translate.instant(this.title);
		const list = this.list;
		const value = this.resValue;
		const text = this.resText;
		const submitButtonText = this.translate.instant('Done');
		const selectedCollectionList = this.selectedCollectionList;

		const dialogRef = this.dialog.open(MultipleCheckListDialogComponent, {
			data: { title, list, submitButtonText, text, value, selectedCollectionList },
			width: '370px'
		});
		dialogRef.afterClosed().subscribe(res => {
			if (!res)
				return;
			this.selectedCollectionList = res.selectedList;
			this.form.patchValue({
				multipleCheckInputValue: res.selectedData.formattedArray,
				multipleCheckInputText: res.selectedData.formattedText,
			});
		});
	}

}