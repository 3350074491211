export class DashboardStats {
    PeopleCheckedIn: number;
    TotalCalls: number;
    TotalExpenseClaims: number;
    TotalLeadsAdded: number;
    TotalOpportunitiesValue: number;
    TotalSales: number;
    TotalTeamMembers: number;
    TotalUnitSales: number;
    TotalJoinVisit:number;
    TotalVisits: number;
    TotalLeaves: number;
    TotalIdle: number;
    TotalVisitForm: number;
    TotalDynamicForm: number;
}