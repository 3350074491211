export class CustomerModel {
	Status: number;
	LeadId: number;
	ReferredBy: string;
	Id: number;
	From: number;
	To: number;
	UserName: string;
	LeadName: string;
	Email: string;
	MobileNo: string;
	PersonDesignation: string;
	PersonName: string;
	RefNo: string;
	ContactNo: string;
	Address: string;
	CountryId: number;
	StateId: number;
	CityId: number;
	LocalityId: number;
	PinCode: string;
	LeadSourceId: number;
	LeadStageId: number;
	TerritoryId: number;
	Website: string;
	OtherInfo: string;
	FrontView: string;
	BackView: string;
	RemoveFrontView: boolean;
	RemoveBackView: boolean;
	LeadTypeId: number;
	ParentLeadId: number;
	IsVisibleToAll: boolean;
	LeadTags: any;
	LeadContacts: any;
	UserId: number;
	Message: string;
	Latitude : any;
	Longitude : any;
	CustomFieldValues: any;
	CustomFields: any;
	ReferredById:number;
	clear() {
		this.Status = 0;
		this.LeadId = 0;
		this.Id = 0;
		this.From = 0;
		this.To = 0;
		this.LeadName = '';
		this.Email = '';
		this.MobileNo = '';
		this.PersonDesignation = '';
		this.PersonName = '';
		this.UserName = '';
		this.RefNo = '';
		this.ContactNo = '';
		this.Address = '';
		this.CountryId = 0;
		this.StateId = 0;
		this.CityId = 0;
		this.LocalityId = 0;
		this.PinCode = '';
		this.LeadSourceId = 0;
		this.LeadStageId = 0;
		this.TerritoryId = 0;
		this.Website = '';
		this.OtherInfo = '';
		this.FrontView = '';
		this.BackView = '';
		this.RemoveFrontView = false;
		this.RemoveBackView = false;
		this.LeadTypeId = 0;
		this.ParentLeadId = 0;
		this.IsVisibleToAll = false;
		this.LeadTags = '';
		this.LeadContacts = '';
		this.UserId = 0;
		this.Message = '';
		this.Latitude =0;
	    this.Longitude =0;
		this.CustomFieldValues = '';
		this.CustomFields = '';
	}
}

export class ReferralCustomerModel
{
	CompanyName : string;
    InternalCompanyId: number;
    Tax : number;
    TotalReason: number;
    ContactPerson : string;
    MobileNo : string;
    EmailId : string;
    ReferralCode : number;
    StatusId : string;  
    Address: string;
	CountryId: number;
	StateId: number;
	CityId: number;
	LocalityId: number;
	Pincode: string;
    IsCompany: boolean;
    BankName: string;
    Id:number;
    BankDetailId: number;
    IFSCCode:string;
    AccountNumber:string;
    AccountHolderName:string;
    AccountType:string;
    MyReferralId:number;
    Reason:string;
    IsInternal: boolean;
    CreatedBy: number;
    ReferredBy: string;
    Percentage: number;
  }