import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { MyreferralsService } from '../../../../../core/myreferrals/myreferrals.service';
import { AuthService } from '../../../../../core/auth';
import { AddressService } from '../../../../../core/_base/crud';
import { map } from 'rxjs/operators';
import { ReferralCustomerModel} from '../../../../../core/crm/customers/_models/customer.model';




@Component({
  selector: 'kt-referral-customer-edit',
  templateUrl: './referral-customer-edit.component.html',
  styleUrls: ['./referral-customer-edit.component.scss']
})
export class ReferralCustomerEditComponent implements OnInit {
  viewdata: any;
  referralCustomerForm : FormGroup  
  countryList: any;
  stateList: any;
  cityList: any;
  hasFormErrors: boolean = false;
  referralCustomerId: any;
  currentUser: any;
  viewLoading: boolean = false;


  constructor(
    private dialogRef : MatDialogRef<ReferralCustomerEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		// private customersService: CustomersService,
		// private customDatePipe: CustomDatePipe,
    private addressService : AddressService,
		private dialog: MatDialog,
		private myreferralsService : MyreferralsService,
  ) { }

  ngOnInit() {
    //debugger
    this.viewdata = this.data.referralCustomer;
    console.log(this.viewdata);
    this.createForm();
    this.getCountryList();
    this.authService.getPermissions().subscribe(x => this.currentUser = x.currentUser);  }


  createForm(){
    this.referralCustomerForm = this.fb.group({
      CompanyName: ['',[Validators.required, Validators.maxLength(40), Validators.pattern("^[a-z A-Z]*$")]],
			RefNo: [''],
      ReferBy:[''],
      ContactPerson: [''],
			MobileNo: [''],
			EmailId: [''],
			Address: [''],
			CountryId: [''],
			StateId: [''],
			CityId: [''],
			Pincode: [''],
		});
    this.patchFormValue();
  }


  patchFormValue(){
    this.referralCustomerForm.patchValue(this.viewdata);
  }


  onUpdate(){
    //debugger
    const controls = this.referralCustomerForm.controls;
    const _referralCustomer = new ReferralCustomerModel;
    _referralCustomer.CompanyName = controls['CompanyName'].value;
    _referralCustomer.ContactPerson = controls['ContactPerson'].value;
    _referralCustomer.MobileNo = controls['MobileNo'].value;
    _referralCustomer.EmailId = controls['EmailId'].value;
    _referralCustomer.Address = controls['Address'].value;
    _referralCustomer.CountryId = controls['CountryId'].value;
    _referralCustomer.StateId = controls['StateId'].value;
    _referralCustomer.CityId = controls['CityId'].value;
    _referralCustomer.Pincode = controls['Pincode'].value;
    _referralCustomer.Id = this.viewdata.Id;
    // _referralCustomer.ModifiedBy = this.currentUser.userId;

    var updateReferralCustomer =_referralCustomer

    this.myreferralsService.editCustomer(updateReferralCustomer)
    .pipe(map(res => {
      if (res) {
        this.dialogRef.close({ res, isEdit: true });
      }
    }))
    .subscribe();
  }
  // openMap() {
	// 	let address = this.customerForm.controls['Address'].value
	// 	const dialogRef = this.dialog.open(AddressDialogComponent, {
	// 		width: '550px',
	// 		data: {
	// 			address: address, action: ''
	// 		}
	// 	});

	// 	dialogRef.afterClosed().subscribe(result => {
	// 		if (!result)
	// 			return
	// 		this.customerForm.controls['Address'].patchValue(result.res)
	// 	});

	// }

  onSubmit(){
    //debugger
    this.hasFormErrors = false;
    const controls = this.referralCustomerForm.controls;
    /** check form */
    if (this.referralCustomerForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.hasFormErrors = true;
      return;
    }
   this.onUpdate()
  }

  getCountryList() {
		this.authService.GetCountry()
			.pipe(map(res => {
				if (res) {
					this.countryList = res;
					if (this.viewdata.CountryId > 0) {
						const toSelect = this.countryList.find(c => c.CountryId == this.viewdata.CountryId);
						this.referralCustomerForm.get('CountryId').setValue(toSelect.CountryId);
						this.getStateList(toSelect.CountryId);
					}
				}
			}))
			.subscribe();
	}

	getStateList(CountryId) {
		this.addressService.getState(CountryId)
			.pipe(map(res => {
				if (res) {
					this.stateList = res;
					if (this.viewdata.StateId > 0) {
						const toSelect = this.stateList.find(c => c.StateId == this.viewdata.StateId);
						this.referralCustomerForm.get('StateId').setValue(toSelect.StateId);
						this.getCityList(toSelect.StateId);
					}
				}
			}))
			.subscribe();
	}

	getCityList(StateId) {
		this.addressService.getCity(StateId)
			.pipe(map(res => {
				if (res) {
					this.cityList = res;
					if (this.viewdata.CityId > 0) {
						const toSelect = this.cityList.find(c => c.CityId == this.viewdata.CityId);
						this.referralCustomerForm.get('CityId').setValue(toSelect.CityId);
					}
				}
			}))
			.subscribe();
	}

  closeModal() {
    this.dialogRef.close();
  }

}
