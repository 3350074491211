export class Tags {
	TagName: string;
	TagId: number;
	IsActive: boolean;

	clear() {
		this.TagName = '';
		this.TagId = 0;
		this.IsActive = false;
	}
}