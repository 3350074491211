// Angular
import { Component, EventEmitter, Output, Input } from '@angular/core';

/**
 * Sample context menu dropdown
 */
@Component({
	selector: 'kt-context-menu-dashboard',
	templateUrl: './context-menu-dashboard.component.html',
	styleUrls: ['./context-menu-dashboard.component.scss']
})
export class ContextMenuDashboardComponent {

	@Input() reportType: string | number;
	@Input() dateRange: string | number;
	@Output() dateEvent = new EventEmitter();
	@Output() reportEvent = new EventEmitter();

	getDate(DateValue, reportType) {
		this.dateEvent.emit({ DateValue, reportType });
	}

	reports(reportType) {
		this.reportEvent.emit(reportType);
	}
}