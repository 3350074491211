// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { Logout } from '../../../../core/auth/_actions/auth.actions';
import { environment } from '../../../../../environments/environment';

//import { debug } from 'util';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {

	constructor(
		private toastrService: ToastrService,
		private _cookieService: CookieService,
		private router: Router,
		private store: Store<AppState>,
	) { }
	// intercept request and add token
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// tslint:disable-next-line:no-debugger
		// modify request
		// let currentUser = JSON.parse(localStorage.getItem('currentUser'))
		// console.log(request.url.includes('referral/'));
		if(request.url.includes('referral/')){
			let token = localStorage.getItem('referraltoken')
			// console.log(token, localStorage.getItem('referraltoken'))
			if (token) {
				request = request.clone({
					url: `${environment.referralBaseUrl}${request.url}`,
					setHeaders: {
						Authorization: `Bearer ${token}`
					}
				});
			} else {
				request = request.clone({
					url: `${environment.referralBaseUrl}${request.url}`
				});
			}
		}else{
			if (this._cookieService.get('currentUser') && localStorage.getItem('Permissions')) {
				let currentUser = JSON.parse(this._cookieService.get('currentUser'))
				if (currentUser && currentUser.accessToken) {
					request = request.clone({
						setHeaders: {
							Authorization: `${currentUser.accessToken}`
						}
					});
				}
			}
		}



		return next.handle(request).pipe(
			tap(
				event => {
					if (event instanceof HttpResponse) {
						;
						// http response status code
						if (event.body.Status == 703) {
							this.toastrService.error("Access Denied")
							this.router.navigate(['/dashboard'])
						}
						if (event.body.Status == 401) {
							this.store.dispatch(new Logout());
							this.router.navigate(['/auth/login'])
						}
						if (event.body.Status == 500) {
							this.toastrService.error("Internal Server Error")
							this.store.dispatch(new Logout());
							this.router.navigate(['/auth/login'])
						}
					}
				},
				error => {
					// http response status code
					// console.error('status code:');
					// tslint:disable-next-line:no-debugger
					if (error.error){
						const errorMessage = error.error.Message ? error.error.Message : 'Something Went Wrong';
						this.toastrService.error(errorMessage);
					} 
					

				}
			)
		);
	}
}