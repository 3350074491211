import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../../../core/users/_services/users.service'
@Component({
  selector: 'kt-view-user-profile',
  templateUrl: './view-user-profile.component.html',
  styleUrls: ['./view-user-profile.component.scss']
})
export class ViewUserProfileComponent implements OnInit {
  public currentUser : any=[];
  constructor(private userService:UserService) { }

  ngOnInit() {
    this.userService.getMyProfile().subscribe((res=>{
      this.currentUser=res['data']
    }));
  }

}
