// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'kt-select-list-dialog',
	templateUrl: './select-list-dialog.component.html'
})
export class SelectListDialogComponent implements OnInit {
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
	constructor(
		public dialogRef: MatDialogRef<SelectListDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit() {
		this.viewLoading = true;
		setTimeout(() => {
			this.viewLoading = false;
		});
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onSelectClick(seletcedValue): void {
		if (!seletcedValue) {
			return;
		}

		this.viewLoading = true;
		this.loadingAfterSubmit = true;
		setTimeout(() => {
			this.dialogRef.close(seletcedValue);
		});
	}
}