// Angular
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
	MatAutocompleteModule,
	MatButtonModule,
	MatCardModule,
	MatCheckboxModule,
	MatDatepickerModule,
	MatDialogModule,
	MatIconModule,
	MatInputModule,
	MatMenuModule,
	MatNativeDateModule,
	MatPaginatorModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatRadioModule,
	MatSelectModule,
	MatSnackBarModule,
	MatSortModule,
	MatTableModule,
	MatTabsModule,
	MatTooltipModule,
	MatChipsModule,
	MatSlideToggleModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSliderModule
} from '@angular/material';
// NgBootstrap
import { NgbDropdownModule, NgbTabsetModule, NgbTooltipModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// import { AddressDialogComponent } from './address-dialog/address-dialog.component';
import { AddressDialogComponent } from '../pages/crm/customers/address-dialog/address-dialog.component';

// Core module
import { CoreModule } from '../../core/core.module';
// CRUD Partials
import {
	ActionNotificationComponent,
	AlertComponent,
	DeleteEntityDialogComponent,
	MoveReasonDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent,
	FileUploadDialogComponent,
	SingleCheckListDialogComponent,
	SingleCheckListInputComponent,
	MultipleCheckListDialogComponent,
	MultipleCheckListInputComponent,
	SelectListDialogComponent,
	SelectListInputComponent,
	ImageUploadComponent,
	ImageUploadDialogComponent,
	TagComponent,
	ContactComponent,
	DateTimePickerComponent,
	FilterComponent,
	SearchMultipleCheckListComponent,
} from './content/crud';
// Layout partials
import {
	ContextMenuDashboardComponent,
	CustomerActionComponent,
	ContextMenu2Component,
	ContextMenuComponent,


	QuickPanelComponent,
	ScrollTopComponent,

	SearchResultComponent,
	SplashScreenComponent,
	StickyToolbarComponent,
	Subheader1Component,
	Subheader2Component,
	Subheader3Component,
	Subheader4Component,
	Subheader5Component,
	SubheaderSearchComponent,
	SubheaderCustomComponent,

	UserProfileComponent
} from './layout';
// General
import { NoticeComponent } from './content/general/notice/notice.component';
import { PortletModule } from './content/general/portlet/portlet.module';
// Errpr
import { ErrorComponent } from './content/general/error/error.component';
// Extra module
import { WidgetModule } from './content/widgets/widget.module';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
import { ImageSliderComponent } from './content/crud/image-slider/image-slider.component';
import { ConfirmationDialogComponent } from './content/crud/confirmation-dialog/confirmation-dialog.component';
import { TimePickerComponent } from './content/crud/time-picker/time-picker.component';
import { UserPunchComponent } from './layout/topbar/user-punch/user-punch.component';
//Angular Google Maps
import { AgmCoreModule } from '@agm/core';
import { UserPunchoutDialogComponent } from './layout/topbar/user-punch/user-punchout-dialog/user-punchout-dialog.component';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../partials/layout/format-datepicker';
import { GlobalMapComponent } from '../../views/pages/crm/team/team-actions/_subs/global-map/global-map.component';
import { DatePipe } from '@angular/common';
import { TeamService } from '../../core/crm/team';
import { MapsAPILoader } from '@agm/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { ButtonWithSpinnerComponent } from './content/crud/button-with-spinner/button-with-spinner.component';
import { CustomerEditDialogComponent } from '../pages/crm/customers/customer-edit/customer-edit.dialog.component'
import { CustomerTimelineComponent } from '../pages/crm/customers/customer-timeline/customer-timeline.component';
import { DetailsComponent} from '../pages/crm/customers/customer-timeline/_subs/details/details.component';
import { PreviewDialogComponent } from '../pages/crm/customers/customer-timeline/_subs/details/preview/preview.dialog.component'
import {PastMeetPreviewDialogComponent } from  '../pages/dashboard360/customer-view-report/_subs/past-meeting-view/form-dialog/preview/past-meet-preview.dialog.component'
import { MailsComponent} from '../pages/crm/customers/customer-timeline/_subs/mails/mails.component';
import { MailsInstructionsComponent} from '../pages/crm/customers/customer-timeline/_subs/mails/_subs/mails-instructions.component';
import { TimelineComponent} from '../pages/crm/customers/customer-timeline/_subs/timeline/timeline.component';
import { DialogComponent} from '../pages/crm/customers/customer-timeline/_subs/timeline/dialog/dialog.component';
import { MatExpansionModule, MatSidenavModule } from '@angular/material'
import { ImageViewerModule } from 'ng2-image-viewer';
import { AngularImageViewerModule } from 'angular-x-image-viewer';
import {CustomersService} from '../../core/crm/customers';
import { ModalHeaderComponent } from './content/crud/modal-header/modal-header.component';
import {UsersEditComponent} from '../pages/users/users-edit/users-edit.component';
import { TaskDetailsDialogComponent } from '../pages/task/task-details-dialog/task-details-dialog.component';
import { AddTaskDialogComponent } from '../pages/task/add-task-dialog/add-task-dialog.component';
import {ShowUsersDialogComponent} from '../pages/task/show-users-dialog/show-users-dialog.component';
import { ProductDetailsDialogComponent } from '../pages/crm/product-master/product-details-dialog/product-details-dialog.component';
import { TruncatePipe } from '../../core/_base/layout/pipes/truncate-text.pipe';
import { UserPunchinDialogComponent } from './layout/topbar/user-punch/user-punchin-dialog/user-punchin-dialog.component';
import { PreviewComponent } from '../pages/dashboard360/customer-view-report/_subs/past-meeting-view/preview/preview.component';
import { FormDialogComponent } from '../pages/dashboard360/customer-view-report/_subs/past-meeting-view/form-dialog/form-dialog.component';

import { AddOpportunitiesDialogComponent } from '../pages/crm/opportunities/add-opportunities/add-opportunities.dialog.component';
import { DuplicateEntityDialogComponent } from './content/crud/duplicate-entity-dialog/duplicate-entity-dialog.component';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
	declarations: [
		DetailsComponent,
		PreviewDialogComponent,
		PastMeetPreviewDialogComponent,
		MailsComponent,
		AddressDialogComponent,
		MailsInstructionsComponent,
		TimelineComponent,
		DialogComponent,
		ScrollTopComponent,
		NoticeComponent,
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		MoveReasonDialogComponent,
		UpdateStatusDialogComponent,
		AlertComponent,
		FileUploadDialogComponent,
		SingleCheckListDialogComponent,
		SingleCheckListInputComponent,
		MultipleCheckListInputComponent,
		SelectListInputComponent,
		MultipleCheckListDialogComponent,
		SelectListDialogComponent,
		ImageUploadComponent,
		ImageUploadDialogComponent,
		TagComponent,
		ContactComponent,
		DateTimePickerComponent,
		FilterComponent,
		SearchMultipleCheckListComponent,
		FormDialogComponent,

		// topbar components
		ContextMenuDashboardComponent,
		CustomerActionComponent,
		ContextMenu2Component,
		ContextMenuComponent,
		QuickPanelComponent,
		ScrollTopComponent,
		SearchResultComponent,
		SplashScreenComponent,
		StickyToolbarComponent,
		Subheader1Component,
		Subheader2Component,
		Subheader3Component,
		Subheader4Component,
		Subheader5Component,
		SubheaderSearchComponent,
		SubheaderCustomComponent,

		UserProfileComponent,


		ErrorComponent,

		ImageSliderComponent,

		ConfirmationDialogComponent,

		TimePickerComponent,

		UserPunchComponent,

		UserPunchoutDialogComponent,

		ButtonWithSpinnerComponent,
		CustomerTimelineComponent,
		CustomerEditDialogComponent,
		AddOpportunitiesDialogComponent,
		ModalHeaderComponent,
		UsersEditComponent,
		TaskDetailsDialogComponent,
		AddTaskDialogComponent,
		ShowUsersDialogComponent,
		ProductDetailsDialogComponent,
		UserPunchinDialogComponent,
		PreviewComponent,
		DuplicateEntityDialogComponent
	],
	exports: [
		CustomerTimelineComponent,
		CustomerEditDialogComponent,
		AddOpportunitiesDialogComponent,
		DetailsComponent,
		PreviewDialogComponent,
		PastMeetPreviewDialogComponent,
		MailsComponent,
		MailsInstructionsComponent,
		TimelineComponent,
		DialogComponent,
		MatExpansionModule,
		ImageViewerModule,
		AngularImageViewerModule,
		MatSidenavModule,
		NgxMatSelectSearchModule,
		PreviewComponent,
		WidgetModule,
		PortletModule,
		TimePickerComponent,
		ScrollTopComponent,
		NoticeComponent,
		ActionNotificationComponent,
		MoveReasonDialogComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		AlertComponent,
		FileUploadDialogComponent,
		SingleCheckListDialogComponent,
		SingleCheckListInputComponent,
		MultipleCheckListInputComponent,
		SelectListInputComponent,
		MultipleCheckListDialogComponent,
		SelectListDialogComponent,
		ImageUploadComponent,
		ImageUploadDialogComponent,
		TagComponent,
		ContactComponent,
		DateTimePickerComponent,
		FilterComponent,
		SearchMultipleCheckListComponent,
		ImageSliderComponent,
		FormDialogComponent,
		// topbar components
		ContextMenuDashboardComponent,
		CustomerActionComponent,
		ContextMenu2Component,
		ContextMenuComponent,
		QuickPanelComponent,
		ScrollTopComponent,
		SearchResultComponent,
		SplashScreenComponent,
		StickyToolbarComponent,
		Subheader1Component,
		Subheader2Component,
		Subheader3Component,
		Subheader4Component,
		Subheader5Component,
		SubheaderSearchComponent,
		SubheaderCustomComponent,

		UserProfileComponent,

		UserPunchComponent,

		ConfirmationDialogComponent,
		ErrorComponent,

		ButtonWithSpinnerComponent,
		ModalHeaderComponent,
		UsersEditComponent,
		TaskDetailsDialogComponent,
		AddTaskDialogComponent,
		ShowUsersDialogComponent,
		ProductDetailsDialogComponent
	],
	imports: [
		MatSidenavModule,
		MatExpansionModule,
		ImageViewerModule,
		AngularImageViewerModule,
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		InlineSVGModule,
		CoreModule,
		PortletModule,
		WidgetModule,
		// angular material modules
		MatSliderModule,
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
		MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule,
		MatChipsModule,
		MatSlideToggleModule,
		NgxMatSelectSearchModule,

		// ng-bootstrap modules
		NgbDropdownModule,
		NgbTabsetModule,
		NgbTooltipModule,
		NgbModule,
		MatSelectInfiniteScrollModule,
		InfiniteScrollModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyDjXD71h3mA6_g0s27spZfEup9onPbqHZc',
			libraries: ['places']
		})
	],
	entryComponents: [
		UserPunchoutDialogComponent,
		UserPunchinDialogComponent,
		SingleCheckListDialogComponent,
		PreviewDialogComponent,
		DialogComponent,
		CustomerEditDialogComponent,
		UsersEditComponent,
		TaskDetailsDialogComponent,
		AddTaskDialogComponent,
		ShowUsersDialogComponent,
		ProductDetailsDialogComponent,
		AddressDialogComponent,
		PreviewComponent,
		FormDialogComponent,
		PastMeetPreviewDialogComponent,
	],
	providers: [
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'kt-mat-dialog-container__wrapper',
				height: 'auto',
				width: '600px'
			}
		},
		{ provide: DateAdapter, useClass: AppDateAdapter },
		{ provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
		GlobalMapComponent,
		DatePipe,
		TruncatePipe,
		TeamService,
		FormBuilder,
		CustomersService
	]
})
export class PartialsModule {
}
