import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'kt-user-punchout-dialog',
  templateUrl: './user-punchout-dialog.component.html',
  styleUrls: ['./user-punchout-dialog.component.scss']
})
export class UserPunchoutDialogComponent implements OnInit {

  @ViewChild('report', {static: false}) report: ElementRef;
  isError: boolean = false;
  isValid: boolean = false;
  reportData: any;
  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private dialogRef: MatDialogRef<UserPunchoutDialogComponent>,
  ) { }

  ngOnInit() {
  }

  onSubmit(){
    this.reportData = this.report.nativeElement.value;
    let reportData = this.reportData;
    if(this.reportData == ''){
      this.isError = true;
      return;
    }
    else if(this.reportData.length > 500){
      this.isValid = true;
      return;
    }
    else{
      this.isError = false;
      this.isValid = false;
      this.dialogRef.close({ reportData, isEdit: true});
    }
  }

  onNoClick(){
    this.dialogRef.close();
  }

}
