import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { Observable, from } from 'rxjs';
import { CountryModel, StateModel, CityModel, LocalityModel } from '..';

@Injectable({
    providedIn: 'root'
})
export class AddressService {
    countryList: any;
    // stateList: State[];
    // cityList: City[];
    // localityList: Locality[];

    constructor(
        private http: HttpClient
    ) { }

    /*********** Country **********/
    getCountry(): Observable<any> {
        if (!this.countryList) {
            this.countryList = this.http.get<any>('api/GetCountryList')
                .pipe(map(resp => {
                    return resp.data;
                }),
                    publishReplay(1),
                    refCount()
                );
        }
        return this.countryList
    }

    /*********** State **********/
    getState(CountryId): Observable<any> {
        return this.http.get<any>('api/GetStateList/?CountryId=' + CountryId)
            .pipe(map(resp => {
                return resp.data;
            }));
    }

    /*********** City **********/
    getCity(StateId): Observable<any> {
        return this.http.get<any>('api/GetCityList?StateId=' + StateId)
            .pipe(map(resp => {
                return resp.data;
            }));
    }

    /*********** Locality **********/
    getLocality(CityId): Observable<any> {
        return this.http.get<any>('api/GetLocalityList?CityId=' + CityId)
            .pipe(map(resp => {
                return resp.data;
            }));
    }

    /*********** Members **********/
    myTeam(): Observable<any> {
        const params =
        {
            'from': 1,
            'to': -1,
            'text': ''
        };
        return this.http.post('api/CRM/MyTeam', params)
            .pipe(map(res => res));
    }
}