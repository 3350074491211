import { Component, OnInit, Inject, ElementRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AddMembersDialogComponent } from '../add-members-dialog/add-members-dialog.component';
import { TaskService } from '../../../../core/task';
import { MytaskComponent } from '../_subs/mytask/mytask.component';
import { map } from 'rxjs/operators';
import { CustomDatePipe } from '../../../../core/_base/layout/index';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// Toastr
import { ToastrService } from 'ngx-toastr';
import { AuthService, User } from '../../../../core/auth/index';

@Component({
  selector: 'kt-add-task-dialog',
  templateUrl: './add-task-dialog.component.html',
  styleUrls: ['./add-task-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AddTaskDialogComponent implements OnInit {

  @ViewChild('imageFileInput', { static: false }) imageFileInput: ElementRef;
  myComponent: MytaskComponent;
  viewLoading: boolean = false;
  selectedIndex: number = 0;
  isDisabled: boolean = false;
  addTaskForm: FormGroup;
  IntercomGroupIds: any = [];
  isActive: boolean = true;
  taskOwners: any = [];
  userIds: any = [];
  memberLength: number = 0;
  userLength: number = 0;
  displayFileName: string = '';
  imageName: string = '';
  imageExt: string = '';
  tabDisable: boolean = false;
  taskOwnerCount: number = 0;
  assignedToCount: number = 0;
  currentUser: any;
  userDetails: any;
  indexOfTab: number;
  current = new Date();
  taskDetails: any;
  leadData;
  leadFilter: any;
  pageData = {
    From: 1,
    To: -1,
    Text: '',
  }
  customerList;

  constructor(
    public dialogRef: MatDialogRef<AddTaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private taskService: TaskService,
    private customDatePipe: CustomDatePipe,
    private authService: AuthService,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private chRef: ChangeDetectorRef
  ) {
    dialogRef.disableClose = true;
    this.authService.getCurrentUser().subscribe(x => { this.currentUser = x; });
  }

  ngOnInit() {
    this.getCustomerList();
    this.createForm();
    if (this.data.Action == 'Edit') {
      this.taskService.getTaskDetails(this.data.UserId)
        .pipe(
          map(res => {
            this.taskDetails = res.data
            let userData = res.data.AssignedToUserIds;
            if (userData.includes(this.currentUser.userId)) {
              this.indexOfTab = 1;
            }
            else {
              this.indexOfTab = 0;
            }
            if (this.indexOfTab == 1) {
              this.selectedIndex = this.indexOfTab;
              this.tabDisable = true;
              this.patchFormValue();
              this.chRef.detectChanges();
            }
            else {
              this.selectedIndex = this.indexOfTab;
              this.tabDisable = false;
              this.patchFormValue();
              this.chRef.detectChanges();
            }
          })
        ).subscribe();
    } else if (this.data.Action == 'Add') {
      this.createForm();
    }

  }

  createForm() {
    this.addTaskForm = this.fb.group({
      Id: [''],
      AssignedBy: [this.data.UserId],
      AssignedToUserId: [''],
      AssignedDate: [''],
      CompletedDate: [''],
      Description: ['', Validators.required],
      IntercomGroupIds: [this.IntercomGroupIds],
      IsActive: [this.isActive],
      Latitude: [''],
      Location: [''],
      Longitude: [''],
      Image: [''],
      MultimediaData: [''],
      MultimediaExtension: [''],
      MultimediaFileName: [''],
      MultimediaType: [''],
      Priority: [''],
      TaskEndDateDisplay: ['', Validators.required],
      TaskEndDate: [''],
      TaskDisplayOwners: [''],
      TaskOwners: [''],
      TaskStatus: [''],
      Title: ['', Validators.required],
      UserDisplayIds: ['', Validators.required],
      UserIds: [''],
      LeadId:['']
    })
  }
  patchFormValue() {
    const controls = this.addTaskForm.controls;
    if (this.data.Action == 'Add') {
      controls.Id.disable();
    }
    if (this.data.Action == 'Edit' && this.selectedIndex == 1) {
      controls.Id.enable();
      // this.taskService.getTaskDetails(this.data.UserId)
      //   .pipe(map(res => {
      // let userData = this.taskDetails.AssignedToUserIds;
      this.addTaskForm.patchValue(this.taskDetails);
      this.taskOwnerCount = this.taskDetails.TaskOwnerCount;
      controls.Id.setValue(this.taskDetails.TaskId);
      controls.TaskEndDateDisplay.setValue((new Date(this.taskDetails.TaskEndDate)).toISOString());
      this.taskOwners = this.taskDetails.TaskOwnerIds;
      if (this.taskDetails.TaskOwnerCount == 1) {
        controls.TaskDisplayOwners.setValue(this.taskDetails.TaskOwnerCount + ' User')
      }
      if (this.taskDetails.TaskOwnerCount > 1) {
        controls.TaskDisplayOwners.setValue(this.taskDetails['TaskOwnerCount'] + ' Users');
      }
      let imageName = (/[^/]*$/.exec(this.taskDetails.MultimediaName)[0]);
      let fileExt = (/[^.]*$/.exec(this.taskDetails.MultimediaName)[0]);
      if (fileExt == 'pdf' || fileExt == 'doc' || fileExt == 'docx' || fileExt == 'xls' || fileExt == 'xlsx' || fileExt == 'txt' || fileExt == 'jpeg' || fileExt == 'jpg' || fileExt == 'png' || fileExt == 'svg') {
        this.displayFileName = imageName;
      }
      // })).subscribe();
    }
    if (this.data.Action == 'Edit' && this.selectedIndex == 0) {
      controls.Id.enable();
      // this.taskService.getTaskDetails(this.data.UserId)
      //   .pipe(map(res => {
      // let userData = this.taskDetails['AssignedToUserIds'];
      this.addTaskForm.patchValue(this.taskDetails);
      this.assignedToCount = this.taskDetails.AssignedToUserCount;
      this.taskOwnerCount = this.taskDetails.TaskOwnerCount;
      controls.Id.setValue(this.taskDetails.TaskId);
      controls.TaskEndDateDisplay.setValue((new Date(this.taskDetails.TaskEndDate)).toISOString());
      this.taskOwners = this.taskDetails.TaskOwnerIds;
      this.userIds = this.taskDetails.AssignedToUserIds;
      this.userDetails = this.taskDetails.AssignedToUserIds;
      if (this.taskDetails.TaskOwnerCount == 1) {
        controls.TaskDisplayOwners.setValue(this.taskDetails.TaskOwnerCount + ' User')
      }
      if (this.taskDetails.TaskOwnerCount > 1) {
        controls.TaskDisplayOwners.setValue(this.taskDetails.TaskOwnerCount + ' Users');
      }
      if (this.taskDetails.AssignedToUserCount == 1) {
        controls.UserDisplayIds.setValue(this.taskDetails.AssignedToUserCount + ' User')
      }
      if (this.taskDetails.AssignedToUserCount > 1) {
        controls.UserDisplayIds.setValue(this.taskDetails.AssignedToUserCount + ' Users');
      }
      let imageName = (/[^/]*$/.exec(this.taskDetails.MultimediaName)[0]);
      let fileExt = (/[^.]*$/.exec(this.taskDetails.MultimediaName)[0]);
      if (fileExt == 'pdf' || fileExt == 'doc' || fileExt == 'docx' || fileExt == 'xls' || fileExt == 'xlsx' || fileExt == 'txt' || fileExt == 'jpeg' || fileExt == 'jpg' || fileExt == 'png' || fileExt == 'svg') {
        this.displayFileName = imageName;
      }
      // })).subscribe();
    }
  }

  openMembers(controlName) {
    const controls = this.addTaskForm.controls;
    let controlname;
    let params;
    if (controlName == 'UserIds') {
      controls.UserDisplayIds.clearValidators();
      controls.UserDisplayIds.updateValueAndValidity();
      controlname = controls.UserDisplayIds;
      params = { usersIds: this.userIds, controlname: 'UserIds', Action: this.data.Action };
    }
    else {
      controlname = controls.TaskDisplayOwners;
      params = { usersIds: this.taskOwners, controlname: 'TaskOwner', Action: this.data.Action };
    }
    const dialogRef = this.dialog.open(AddMembersDialogComponent, { data: params, width: '400px' });
    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return
      if (controlName == 'UserIds') {
        this.userIds = [];
        this.assignedToCount = 0
        res.members.forEach(result => {
          if (!this.userIds.includes(result.UserId)){
            this.userIds.push(result.UserId);
            this.assignedToCount+=1;
          }
        })
        if (this.data.Action == 'Edit' && controlName == 'UserIds') {
          this.taskService.addUsersExistingTask(this.data.UserId, this.userIds)
            .pipe(
              map(res => {
                const _successmessage = this.translate.instant('User Updated Successfully');
                if (res.Status == 200) {
                  this.toastrService.success(_successmessage);
                }
              })
            ).subscribe();
        }
      }
      else {
        this.taskOwners = [];
        this.taskOwnerCount = 0
        res.members.forEach(result => {
          if (!this.taskOwners.includes(result)){
            this.taskOwnerCount+=1;
              this.taskOwners.push(result);
          }

        })
        if (this.data.Action == 'Edit') {
          this.taskService.updateOwnerTask(this.data.UserId, this.taskOwners)
            .pipe(
              map(res => {
                const _successmessage = this.translate.instant('User Updated Successfully');
                if (res.Status == 200) {
                  this.taskOwners = res.data.TaskOwnerIds;
                  this.toastrService.success(_successmessage);
                }
              })
            ).subscribe();
        }
      }
      this.memberLength = this.taskOwners.length;
      if (this.taskOwners.length <= 1 && controlname == controls.TaskDisplayOwners) {
        controlname.setValue(this.taskOwners.length + ' User');
      }
      if (this.taskOwners.length > 1 && controlname == controls.TaskDisplayOwners) {
        controlname.setValue(this.taskOwners.length + ' Users');
      }
      this.userLength = this.userIds.length;
      if (this.userIds.length <= 1 && controlname == controls.UserDisplayIds) {
        controlname.setValue(this.userIds.length + ' User');
      }
      if (this.userIds.length > 1 && controlname == controls.UserDisplayIds) {
        controlname.setValue(this.userIds.length + ' Users');
      }
    });
  }

  tabChange() {
    this.createForm();
    if (this.data.Action == 'Edit') {
      this.patchFormValue()
    }
  }

  openFile() {
    this.imageFileInput.nativeElement.click();
  }

  handleFileSelect(inputValue: any): void {
    if (inputValue.files[0] && inputValue.files[0].size < 5000000) {
      var file = inputValue.files[0];
      this.displayFileName = file.name;
      this.imageName = this.displayFileName.replace(/\\/g, "/");
      this.imageName = (/[^/]*$/.exec(this.imageName)[0]);
      this.imageExt = (/[^.]*$/.exec(this.imageName)[0]);
      this.imageName = this.imageName.substring(0, this.imageName.lastIndexOf('.'));
      var reader = new FileReader();
      reader.onload = (e: any) => {
        var binaryData = e.target.result;
        var base64String = btoa(binaryData);
        var imagePath = base64String;
        this.addTaskForm.patchValue({
          Image: imagePath
        });
      }
      reader.readAsBinaryString(file);
    } else if (inputValue.files[0] && inputValue.files[0].size > 5000000) {
      this.toastrService.error('File size is greater than 5MB');
      this.chRef.detectChanges();
    }
  }

  removeFile() {
    this.imageFileInput.nativeElement.value = '';
    this.displayFileName = '';
    this.addTaskForm.patchValue({
      Image: '',
      MultimediaData: '',
      MultimediaExtension: '',
      MultimediaFileName: '',
      MultimediaType: ''
    });
  }

  onSubmit() {
    const selectTab = this.selectedIndex;
    if (this.data.Action == 'Add') {
      const controls = this.addTaskForm.controls;
      if (this.selectedIndex == 1) {
        controls.UserDisplayIds.disable();
      }
      if (this.addTaskForm.invalid) {
        Object.keys(controls).forEach(controlName => {
          controls[controlName].markAsTouched();
        });
        return;
      }
      if (this.selectedIndex == 0) {
        controls.UserDisplayIds.setValidators(Validators.required);
        controls.UserDisplayIds.updateValueAndValidity();
        controls.UserIds.setValue(this.userIds);
      }
      if (this.selectedIndex == 1) {
        controls.UserDisplayIds.disable();
        this.userIds = [];
        this.userIds.push(this.data.UserId);
        controls.UserIds.setValue(this.userIds);
      }
      controls.TaskOwners.setValue(this.taskOwners);
      let imageData = controls.Image.value;
      controls.MultimediaData.setValue(imageData);
      controls.MultimediaExtension.setValue(this.imageExt);
      controls.MultimediaFileName.setValue(this.imageName);

      if (this.imageExt == 'jpeg' || this.imageExt == 'JPEG' || this.imageExt == 'jpg' ||
        this.imageExt == 'JPG' || this.imageExt == 'png' || this.imageExt == 'PNG' || this.imageExt == 'svg'
        || this.imageExt == 'SVG') {
        controls.MultimediaType.setValue('I');
      }
      else {
        if (this.imageExt) {
          controls.MultimediaType.setValue('D');
        } else {
          controls.MultimediaType.setValue('');
        }
      }

      let customDate = this.customDatePipe.transform(controls.TaskEndDateDisplay.value, 0, 'd MMM yyyy hh:mm a')
      controls.TaskEndDate.setValue(customDate);
      this.viewLoading = true;
      this.taskService.addTask(this.addTaskForm.value)
        .pipe(map(res => {
          if (res.Status == 200) {
            this.dialogRef.close({ res, selectTab, isEdit: false });
            this.viewLoading = false;
          }

        })).subscribe();
    }
    if (this.data.Action == 'Edit') {
      const controls = this.addTaskForm.controls;
      if (this.selectedIndex == 1) {
        controls.UserDisplayIds.disable();
		this.taskService.load.next(true)
      }
      if (this.addTaskForm.invalid) {
        Object.keys(controls).forEach(controlName => {
          controls[controlName].markAsTouched();
        });
        return;
      }
      if (this.selectedIndex == 0) {
        controls.UserDisplayIds.setValidators(Validators.required);
        controls.UserDisplayIds.updateValueAndValidity();
        controls.UserIds.setValue(this.userIds);
      }
      if (this.selectedIndex == 1) {
        controls.UserDisplayIds.disable();
        this.userIds = [];
        this.userIds.push(this.data.UserId);
        controls.UserIds.setValue(this.userIds);
      }
      controls.TaskOwners.setValue(this.taskOwners);
      let imageData = controls.Image.value;
      controls.MultimediaData.setValue(imageData);
      controls.MultimediaExtension.setValue(this.imageExt);
      controls.MultimediaFileName.setValue(this.imageName);
      controls.Location.setValue('');
      if (this.imageExt == 'jpeg' || this.imageExt == 'JPEG' || this.imageExt == 'jpg' ||
        this.imageExt == 'JPG' || this.imageExt == 'png' || this.imageExt == 'PNG' || this.imageExt == 'svg'
        || this.imageExt == 'SVG') {
        controls.MultimediaType.setValue('I');
      }
      else {
        if (this.imageExt) {
          controls.MultimediaType.setValue('D');
        } else {
          controls.MultimediaType.setValue('');
        }
      }

      let customDate = this.customDatePipe.transform(controls.TaskEndDateDisplay.value, 0, 'd MMM yyyy hh:mm a')
      controls.TaskEndDate.setValue(customDate);
      this.viewLoading = true;
      this.taskService.updateTaskDetails(this.addTaskForm.value)
        .pipe(map(res => {
          if (res.Status == 200) {
            this.dialogRef.close({ res, selectTab, isEdit: false });
            this.viewLoading = false;
		this.taskService.load.next(true)

          }

        })).subscribe();
    }
  }

  removeOwners(user: string) {
    const _successmessage = this.translate.instant('User Updated Successfully');
    if (user == 'Owner') {
      const params = {
        usersData: this.taskOwners,
        taskId: this.data.UserId,
        Action: 'Owner'
      }
      const dialogRef = this.dialog.open(AddMembersDialogComponent, { data: params, width: '400px' })
      dialogRef.afterClosed().subscribe(res => {
        if (!res)
          return
        this.toastrService.success(_successmessage);
        this.ngOnInit();
      })
    }
    else {
      const params = {
        usersData: this.userDetails,
        taskId: this.data.UserId,
        Action: 'User'
      }
      const dialogRef = this.dialog.open(AddMembersDialogComponent, { data: params, width: '400px' })
      dialogRef.afterClosed().subscribe(res => {
        if (!res)
          return
        this.toastrService.success(_successmessage);

        this.ngOnInit();
      })
    }
  }

  getCustomerList() {
    this.taskService.getCustomerList(this.pageData)
      .pipe(map(res => {
        this.customerList = res.data.Leads;
        this.leadFilter = this.customerList;
      }

      ))
      .subscribe()
  }

  //Search Customer
  searchManager(searchText, type) {
    if (type == 'lead') {
      if (searchText != '') {
        this.leadFilter = this.customerList.filter(item => {
          if (item.LeadName.toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
            return true;
          }
          return false;
        }
        );
      }
      else {
        this.leadFilter = this.customerList;
      }
    }
  }
  //End

  onNoClick() {
    //let params ={
     // TaskDisplayOwners:    this.addTaskForm.controls.TaskDisplayOwners.value,
     // UserDisplayIds : this.addTaskForm.controls.UserDisplayIds.value

   // }
    this.dialogRef.close();

  }

}
