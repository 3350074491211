import { catchError, finalize } from 'rxjs/operators';
// RxJS
import { map } from 'rxjs/operators';
// CRUD
import { BaseDataSource } from '../../_base/crud';
import { BehaviorSubject, of } from 'rxjs';
import { TaskService } from '../_services';

export class TaskDataSource extends BaseDataSource {
	
	private loadingSubject = new BehaviorSubject<boolean>(false);
	private isPreloadTextViewedSubject = new BehaviorSubject<boolean>(true);

	public loading$ = this.loadingSubject.asObservable();
	public isPreloadTextViewed$ = this.isPreloadTextViewedSubject.asObservable();

	constructor(private taskService: TaskService) {
		super();
	}

	loadMyTask(from, to, title, userId, isArchive, userIds, priority, StatusIds, FromDate, ToDate, SortColumn, SortOrder){
		this.loadingSubject.next(true);

		this.taskService.findMyTask(from, to, title, userId, isArchive, userIds, priority, StatusIds, FromDate, ToDate, SortColumn, SortOrder)
			.pipe(
				map(
					task => {
						this.paginatorTotalSubject.next(task.data.TotalCount);
						this.entitySubject.next(task.data.TaskList);
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
			.subscribe();
	}

	loadCC(from, to, title, userId, isArchive, userIds,StatusIds, priority){
		this.loadingSubject.next(true);

		this.taskService.findCC(from, to, title, userId, isArchive, userIds, StatusIds,priority)
			.pipe(
				map(
					task => {
						this.paginatorTotalSubject.next(task.data.TotalCount);
						this.entitySubject.next(task.data.TaskList);
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
			.subscribe();
	}

	loadAssignedByMe(from, to, title, userId, isArchive, userIds, priority, StatusIds, FromDate, ToDate, SortColumn, SortOrder){
		this.loadingSubject.next(true);

		this.taskService.findAssignedByMe(from, to, title, userId, isArchive, userIds, priority, StatusIds, FromDate, ToDate, SortColumn, SortOrder)
			.pipe(
				map(
					task => {
						this.paginatorTotalSubject.next(task.data.TotalCount);
						this.entitySubject.next(task.data.TaskList);
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
			.subscribe();
	}

	loadArchive(from, to, title, userId, isArchive, userIds){
		this.loadingSubject.next(true);

		this.taskService.findArchiveList(from, to, title, userId, isArchive, userIds)
			.pipe(
				map(
					task => {
						this.paginatorTotalSubject.next(task.data.TotalCount);
						this.entitySubject.next(task.data.TaskList);
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
			.subscribe();
	}
}