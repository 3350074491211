import { Component, OnInit, Inject, NgZone, ElementRef, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CustomerEditDialogComponent } from "../customer-edit/customer-edit.dialog.component";
import { MapsAPILoader } from "@agm/core";

@Component({
	selector: "kt-address-dialog",
	templateUrl: "./address-dialog.component.html",
	styleUrls: ["./address-dialog.component.scss"],
})
export class AddressDialogComponent implements OnInit {
	drag: boolean;
	hide: boolean;

	constructor(public dialogRef: MatDialogRef<CustomerEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private ngZone: NgZone, private mapsAPILoader: MapsAPILoader) {}
	@ViewChild("searchInput", { static: false }) public searchLocation: ElementRef;

	latitude = 0;
	longitude = 0;
	private geoCoder;
	address: string;
	mapType = "roadmap";
	selectedMarker;
	novalidatedata: boolean = false;

	ngOnInit() {
		if (this.data.address) {
			this.getCoOrdinates();
		}
		if (this.data.action == "preview") {
			this.drag = false;
			this.hide = true;
		} else {
			this.drag = true;
			this.hide = false;
		}

		this.mapsAPILoader.load().then(() => {
			if (this.data.address == "") {
				this.setCurrentLocation();
			}

			this.geoCoder = new google.maps.Geocoder();
			let autocomplete = new google.maps.places.Autocomplete(this.searchLocation.nativeElement, {
				types: [],
			});
			autocomplete.addListener("place_changed", () => {
				this.ngZone.run(() => {
					//get the place result
					let place: google.maps.places.PlaceResult = autocomplete.getPlace();

					//verify result
					if (place.geometry === undefined || place.geometry === null) {
						return;
					}

					//set latitude, longitude and zoom
					this.latitude = place.geometry.location.lat();
					this.longitude = place.geometry.location.lng();
					// if (this.data.address === this.searchLocation.nativeElement.value) {
					this.getAddress(this.latitude, this.longitude);
					// }
				});
			});
		});
	}

	selectMarker(event) {
		this.getAddress(event.coords.lat, event.coords.lng);
	}

	setCurrentLocation() {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition((position) => {
				this.latitude = position.coords.latitude;
				this.longitude = position.coords.longitude;
				this.getAddress(this.latitude, this.longitude);
			});
		}
	}

	getCoOrdinates() {
		this.mapsAPILoader.load().then(() => {
			this.geoCoder = new google.maps.Geocoder();
			this.geoCoder.geocode({ address: this.data.address }, (results, status) => {
				if (status == google.maps.GeocoderStatus.OK) {
					this.latitude = results[0].geometry.location.lat();
					this.longitude = results[0].geometry.location.lng();

					let params = {
						lat: results[0].geometry.location.lat(),
						lng: results[0].geometry.location.lng(),
					};
					this.searchLocation.nativeElement.value = this.data.address;
					this.address = this.data.address;
				} else {
				}
			});
		});
	}

	getAddress(latitude, longitude) {
		this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
			if (status === "OK") {
				if (results[0]) {
					this.novalidatedata = false;
					let add = results[0].address_components.length;
					this.address = results[0].formatted_address;
					this.searchLocation.nativeElement.value = this.address;
				} else {
					alert("No results found");
				}
			}
		});
	}

	close(value) {
		if (this.address === this.searchLocation.nativeElement.value) {
			if (value == "done") {
				this.dialogRef.close({ res: this.searchLocation.nativeElement.value, latitude: this.latitude, longitude: this.longitude });
			} else {
				this.dialogRef.close();
			}
		} else {

			if (value == 'done') {
				this.dialogRef.close({ res: this.searchLocation.nativeElement.value, latitude: 0, longitude: 0 });
			} else {
				this.dialogRef.close();
			}
			this.novalidatedata = true;
		}
	}
}
