// Angular
import { Component, Inject, OnInit, ChangeDetectionStrategy, Input, forwardRef, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, NativeDateAdapter, MatDateFormats } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

// describes what the return value of the form control will look like
export interface DateTimePickerFormValues {
	date: Date;
}
export const MY_FORMATS = {
	parse: {
		dateInput: 'LL',
	},
	display: {

		dateInput: 'll',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'lll',
		monthYearA11yLabel: 'MMM YYYY',
	},
};

@Component({
	selector: 'kt-date-time-picker',
	templateUrl: './date-time-picker.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DateTimePickerComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => DateTimePickerComponent),
			multi: true,
		},
		// `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
		// application's root module. We provide it at the component level here, due to limitations of
		// our example generation script.
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	]

})
export class DateTimePickerComponent implements ControlValueAccessor, OnDestroy {

	@Input() placeholder: string;
	@Input() isClear: boolean = false;
	form: FormGroup;
	subscriptions: Subscription[] = [];
	currentDate = new Date();

	get value(): DateTimePickerFormValues {
		return this.form.value;
	}

	set value(value: DateTimePickerFormValues) {
		this.form.setValue(value);
		this.onChange(value);
		this.onTouched();
	}

	constructor(private formBuilder: FormBuilder) {
		// create the inner form
		this.form = this.formBuilder.group({
			date: ['', Validators.required]
		});

		this.subscriptions.push(
			// any time the inner form changes update the parent of any change
			this.form.valueChanges.subscribe(value => {
				this.onChange(value);
				this.onTouched();
			})
		);
	}

	ngOnChanges(){
		if(this.isClear){
			this.form.reset();
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	registerOnChange(fn) {
		this.onChange = fn;
	}

	writeValue(value) {
		if (value) {
			this.value = value;
		}
	}

	registerOnTouched(fn) {
		this.onTouched = fn;
	}

	// communicate the inner form validation to the parent form
	validate(_: FormControl) {
		return this.form.valid ? null : { date: { valid: false } };
	}
}