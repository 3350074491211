import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'kt-duplicate-entity-dialog',
  templateUrl: './duplicate-entity-dialog.component.html',
  styleUrls: ['./duplicate-entity-dialog.component.scss']
})
export class DuplicateEntityDialogComponent implements OnInit {
   // Public properties
   viewLoading: boolean = false;

   /**
    * Component constructor
    *
    * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
    * @param data: any
    */
   constructor(
       public dialogRef: MatDialogRef<DuplicateEntityDialogComponent>,
       @Inject(MAT_DIALOG_DATA) public data: any
       
   ) {}

   /**
    * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

   /**
    * On init
    */
   ngOnInit() {
   }

   /**
    * Close dialog with false result
    */
   onNoClick(): void {
       this.dialogRef.close();
   }

   /**
    * Close dialog with true result
    */
   onYesClick(): void {
       /* Server loading imitation. Remove this */
       this.viewLoading = true;
       setTimeout(() => {
           this.dialogRef.close(true); // Keep only this row
       }, 2500);
   }
}
