import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { TaskService } from '../../../../core/task';
import { map } from 'rxjs/operators';
import { FilePreviewDialogComponent } from '../file-preview-dialog/file-preview-dialog.component';
import { AddTaskDialogComponent } from '../add-task-dialog/add-task-dialog.component';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// Toastr
import { ToastrService } from 'ngx-toastr';
import { AuthService, User } from '../../../../core/auth/index';
import { LayoutUtilsService } from '../../../../core/_base/crud';
import { PartialCompleteDialogComponent } from '../partial-complete-dialog/partial-complete-dialog.component';
import { ShowUsersDialogComponent } from '../show-users-dialog/show-users-dialog.component';
import { PreviewImageDialogComponent } from './preview-image/preview-image.dialog.component'
import { CustomersService } from '../../../../core/crm/customers';

@Component({
  selector: 'kt-task-details-dialog',
  templateUrl: './task-details-dialog.component.html',
  styleUrls: ['./task-details-dialog.component.scss']
})
export class TaskDetailsDialogComponent implements OnInit {

  taskDetails: any;
  commentDetails: any;
  imagePreview: any;
  isImage: boolean = false;
  imageName: string = '';
  imageExt: string = '';
  imagePath: any = '';
  MultimediaLink: any = '';
  currentUser: any;
  permisions: any;
  fileToUpload: any;
  isDoc:boolean = false;
  lrmId: number;
  customerStatusList = [];
  userName;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TaskDetailsDialogComponent>,
    private taskService: TaskService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private authService: AuthService,
    private chRef: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
    private translate: TranslateService,
    private customersService: CustomersService
  ) {
    this.authService.getPermissions().subscribe(x => {
      this.currentUser = x.currentUser;
      this.permisions = x.Permissions
      let temp = []
      this.permisions.forEach(element => {
        temp.push(element.SystemName)
      });
      this.permisions = temp
    })
  }

  ngOnInit() {
    this.taskService.getTaskDetails(this.data.taskId)
      .pipe(
        map(res => {
          this.taskDetails = res.data;
          this.MultimediaLink = this.taskDetails.MultimediaName;
          let fileExt = (/[^.]*$/.exec(this.MultimediaLink)[0]);
          if (this.taskDetails.MultimediaName.slice(-2) != 'NA' && (fileExt == 'pdf' || fileExt == 'doc' || fileExt == 'docx' || fileExt == 'xls' || fileExt == 'xlsx' || fileExt == 'txt' || fileExt == 'jpeg' || fileExt == 'jpg' || fileExt == 'png' || fileExt == 'svg')) {
            this.taskDetails.MultimediaName = (/[^/]*$/.exec(this.taskDetails.MultimediaName)[0]);
          }
          else {
            this.taskDetails.MultimediaName = '-';
          }
        })).subscribe();
    this.taskService.getComments(1, 100, '', this.data.taskId)
      .pipe(
        map(res => {
          this.commentDetails = res.data.Comments;
          this.commentDetails.reverse();
        })).subscribe();
  }

  addComment(comment: HTMLInputElement) {
    let Multimedia: any;
    if (comment.value != '' || this.isImage || this.isDoc) {
      if (this.isImage && this.imagePath != '') {
        Multimedia = {
          MultimediaData: this.imagePath,
          MultimediaExtension: this.imageExt,
          MultimediaFileName: this.imageName,
          MultimediaType: 'I'
        }
      }

      if(this.isDoc){
        Multimedia = {
          MultimediaData: this.imagePath,
          MultimediaExtension: this.imageExt,
          MultimediaFileName: this.imageName,
          MultimediaType: 'D'
        }
      }

      this.taskService.addComment(comment.value, this.data.taskId, true, Multimedia)
        .pipe(
          map(res => {
            if (res.Status == 200) {
              comment.value = '';
              this.isImage = false;
              this.imagePath = '';
              this.isDoc = false;
              this.ngOnInit();
            }
          })).subscribe();
      return false
    }
  }

  imageData(event) {
    this.imageName = event.target.value;
    this.imageName = this.imageName.replace(/\\/g, "/");
    this.imageName = (/[^/]*$/.exec(this.imageName)[0]);
    this.imageExt = (/[^.]*$/.exec(this.imageName)[0]);
    this.imageName = this.imageName.substring(0, this.imageName.lastIndexOf('.'));

    if (event.target.files[0] && event.target.files[0].size < 5000000) {
      if (this.imageExt == 'jpg' || this.imageExt == 'jpeg' || this.imageExt == 'png') {
        var filetoupload = event.target.files[0];
        var preview = new FileReader();
        preview.onload = (event: any) => {
          this.isImage = true
          this.imagePreview = event.target.result;
        }
        preview.readAsDataURL(filetoupload);
      }

      var file = event.target.files[0];
      var reader = new FileReader();
      reader.onload = (e: any) => {
        var binaryData = e.target.result;
        var base64String = btoa(binaryData);
        this.imagePath = base64String;
      }
      reader.readAsBinaryString(file);

      if(this.imageExt == 'doc' || this.imageExt == 'docx' || this.imageExt == 'pdf' || this.imageExt == 'xlsx'){
        this.isDoc = true;
      }
    }else if(event.target.files[0] && event.target.files[0].size > 5000000){
      this.toastrService.error('File size is greater than 5MB');
      this.chRef.detectChanges();
    }
  }

  removeImagePreview() {
    this.imagePath = '';
    this.isImage = false;
    this.isDoc = false;
  }

  showPreview(imagePath) {
    this.dialog.open(FilePreviewDialogComponent, { data: [imagePath] });
  }

  editTask(taskId: number) {
    const _successmessage = this.translate.instant('Task Updated Successfully');
    const params = {
      Action: 'Edit',
      Button: 'Done',
      // SelectedIndex: 0,
      UserId: taskId
    }
    const dialogRef = this.dialog.open(AddTaskDialogComponent, { data: params });
    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return
      this.toastrService.success(_successmessage);
      this.ngOnInit();
    })
  }

  acceptTask(taskId: number) {
    const _successMessage = this.translate.instant('Task Accepted Successfully');
    this.taskService.acceptTask(taskId)
      .pipe(
        map(res => {
          if (res.Status == 200)
            this.toastrService.success(_successMessage);
          this.ngOnInit();
        })
      ).subscribe();
  }

  completeTask(taskId: number) {
    const _title: string = this.translate.instant('COMPLETE TASK');
    const _description: string = this.translate.instant('Are you sure this Task is complete?');
    const _waitDesciption: string = this.translate.instant('Task is updating...');
    const _successMessage = this.translate.instant('Task Completed Successfully');

    const dialogRef = this.layoutUtilsService.confirmElement(_title, _description, _waitDesciption, 'Yes');
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.taskService.completeTask(taskId)
        .pipe(map(res => {
          if (res.Status == 200) {
            this.toastrService.success(_successMessage);
            this.ngOnInit();
          }
        }))
        .subscribe();
    });
  }

  partialCompleteTask(taskId: number, completePercentage: number) {
    const _successMessage = this.translate.instant('Partial Complete Task Updated Successfully');
    const params = {
      TaskId: taskId,
      TaskStatusValue: completePercentage
    }
    const dialogRef = this.dialog.open(PartialCompleteDialogComponent, { data: params, width: '450px' });
    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return
      this.toastrService.success(_successMessage);
    })
  }

  showUser(taskId, type) {
    let params = {
      Id: taskId,
      type: type
    }
    this.dialog.open(ShowUsersDialogComponent, { data: params, width: '400px' });
  }

  onNoClick() {
    this.dialogRef.close();
  }

  preview(imagePath) {
    this.dialog.open(PreviewImageDialogComponent, { data: [imagePath] })
  }

  /// CUSTOMER TIME LINE START//

  /**
   * get customer status
   */
   getCustomerStatus() {
    this.customersService.getCustomerStatus()
      .pipe(
        map(res => {
          if (res != '') {
            this.customerStatusList = res;
            this.chRef.detectChanges();
          }
        })
      )
      .subscribe();
  }

  /**
    * Redirect to timeline / edit page
    *
    * @param id
    */
  redirectCustomerDetail(id, name) {
    this.lrmId = id;
    this.userName = name;
    window.scrollTo(0, 0);
  }

  //END////
}
