import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatSelectModule ,MatAutocompleteModule, MatAutocomplete, MatIconModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CoreModule } from '../../../core/core.module';
import { InterceptService } from '../../../core/_base/crud/';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginWithOtpComponent } from './login-with-otp/login-with-otp.component';
import { AuthNoticeComponent } from './auth-notice/auth-notice.component';
import { AuthEffects, AuthGuard, authReducer, AuthService } from '../../../core/auth';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CaptchaComponent } from './captcha/captcha.component';

const routes: Routes = [  
	{
		path: '',
		component: AuthComponent,
		children: [
			{
				path: '',
				redirectTo: 'login',
				pathMatch: 'full'
			},
			{
				path: 'login',
				component: LoginComponent,
				data: { returnUrl: window.location.pathname }
			},
			{
				path: 'register',
				component: RegisterComponent
			},
			{
				path: 'reset-password',
				component: ResetPasswordComponent
			},
			{
				path: 'forgot-password',
				component: ForgotPasswordComponent,
			},
			{
				path: 'login-with-otp',
				component: LoginWithOtpComponent,
				data: { returnUrl: window.location.pathname }
			}
		]
	}
];

@NgModule({
	imports: [
		NgxMatSelectSearchModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		MatButtonModule,
		RouterModule.forChild(routes),
		MatInputModule,
		MatFormFieldModule,
		MatCheckboxModule,
		MatSelectModule,
		MatIconModule,
		MatAutocompleteModule,
		CoreModule,
		TranslateModule.forChild(),
		StoreModule.forFeature('auth', authReducer),
		EffectsModule.forFeature([AuthEffects])
	],
	providers: [
		InterceptService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptService,
			multi: true
		},
	],
	exports: [AuthComponent],
	declarations: [
		AuthComponent,
		LoginComponent,
		RegisterComponent,
		ResetPasswordComponent,
		ForgotPasswordComponent,
		LoginWithOtpComponent,
		AuthNoticeComponent,
		CaptchaComponent,
	]
})

export class AuthModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: AuthModule,
			providers: [
				AuthService,
				AuthGuard
			]
		};
	}
}