import { catchError, finalize } from 'rxjs/operators';
// RxJS
import { map } from 'rxjs/operators';
// CRUD
import { BaseDataSource } from '../_base/crud';
import { BehaviorSubject, of } from 'rxjs';
import { OrganizationalUnitService } from './organizational-unit.service';

export class OrganizationalUnitDatasource extends BaseDataSource {

	private loadingSubject = new BehaviorSubject<boolean>(false);
	private isPreloadTextViewedSubject = new BehaviorSubject<boolean>(true);
	public totalExpenseSubject = new BehaviorSubject<any>(0)

	public loading$ = this.loadingSubject.asObservable();
	public isPreloadTextViewed$ = this.isPreloadTextViewedSubject.asObservable();
	public totalExpense = this.totalExpenseSubject.asObservable();

	constructor(private OrganizationalUnitService: OrganizationalUnitService) {
		super();
	}

	loadDepartment(data)  {
		this.loadingSubject.next(true);
		this.isPreloadTextViewedSubject.next(true)
		this.entitySubject.next([])
		this.OrganizationalUnitService.getDeparment(data)
			.pipe(
				map(
					res => {
							this.paginatorTotalSubject.next(res.data.TotalRecords);
							this.entitySubject.next(res.data.Items);
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
			.subscribe();
	 }

	 loadDesignation(data) {
		this.loadingSubject.next(true);
		this.isPreloadTextViewedSubject.next(true)
		this.entitySubject.next([])
		this.OrganizationalUnitService.getDesignation(data)
			.pipe(
				map(
					res => {
							this.paginatorTotalSubject.next(res.data.TotalRecords);
							this.entitySubject.next(res.data.Items);
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
			.subscribe();
	 }

	 loadBranches(data) {
		this.loadingSubject.next(true);
		this.isPreloadTextViewedSubject.next(true)
		this.entitySubject.next([])
		this.OrganizationalUnitService.getBranches(data)
			.pipe(
				map(
					res => {
							this.paginatorTotalSubject.next(res.data.TotalRecords);
							this.entitySubject.next(res.data.Items);
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
			.subscribe();
	 }
}