import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { map } from 'rxjs/operators';
import { OpportunitiesService } from '../../../../../core/crm/opportunities/_services/opportunities.service'
@Component({
  selector: 'kt-addproductdialog',
  templateUrl: './add-product-dialog.component.html',
  styleUrls: ['./add-product-dialog.component.scss']
})
export class AddproductdialogComponent implements OnInit {
  productdata: any = [];
  productIds: any = [];
  product: any = [];
  productList: any = [];
  productCopyList: any = [];
  removeUserList: any = []
  showRemoveUser: boolean;
  previousFromRow: number = 0;
  previousToRow: number = 0;
  removeProductDetails: any;
  addRemove: boolean;
  lastRowIndex: number = 0;
  totalRecords: number = 0;
  viewLoading: boolean;
  noRecords: boolean;


  constructor(private opportunitiesService: OpportunitiesService,
    public dialogRef: MatDialogRef<AddproductdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { dialogRef.disableClose = true; }

  ngOnInit() {
    
    if (this.data.productsIds != [] && this.data.productsIds != undefined) {
      this.addRemove = true;
      this.getProducts(1, 100, '');
      this.previousFromRow = 1;
      this.previousToRow = 10;
    }
    else {
      this.getProducts(1, 10, '');
      this.previousFromRow = 1;
      this.previousToRow = 10;
    }
    

  }

  getProducts(from, to, search) {
    this.opportunitiesService.findProducts(from, to, search)
      .pipe(map(res => {
        if (res.Status == 200)
          this.productdata = res.data.Items
        Array.prototype.push.apply(this.productList, res.data.Items);
        this.productCopyList = res.data.Items;
        this.totalRecords = res.data.TotalRecords;
        this.lastRowIndex = this.totalRecords - 10;
        this.viewLoading = false;
        this.getChecked();
      }))
      .subscribe()
  }

  getChecked() {
    let param;
    if (this.data.productsIds != '') {
      this.data.productsIds.forEach(element => {
        for (let i = 0; i < this.productList.length; i++) {
          if (element.Id == this.productList[i].ProductId) {
            param = {
              Id: element.Id,
              ProductName: this.productList[i].Name
            }
            this.product.push(param);
          }
        }
      });
    }

    if (this.data.productsIds != '' && this.data.productsIds != undefined) {
      for (let i = 0; i < this.productList.length; i++) {
        for (let j = 0; j < this.data.productsIds.length; j++) {
          if (this.productList[i].ProductId == this.data.productsIds[j].Id) {
            this.productList[i].isChecked = true;
          }
        }
      }
    }

  }

  onscroll(event) {
    if (this.previousFromRow < this.totalRecords && this.previousToRow < this.totalRecords) {
      let pos = event.target.scrollTop + event.target.offsetHeight;
      let max = event.target.scrollHeight;
      if (pos >= max) {
        if (this.lastRowIndex + 10 < this.totalRecords || this.lastRowIndex + 10 == this.totalRecords) {
          this.getProducts(this.previousFromRow + 10, this.previousToRow + 10, '');
          this.previousFromRow += 10;
          this.previousToRow += 10;
        }
        return;
      }
      return;
    }
  }

  searchMember(searchValue) {
    this.viewLoading = true;
   
    
    if (searchValue != '') {
      this.productList = this.productCopyList.filter(item => { return item.Name.includes(searchValue) });
      
      if (this.product != '' && this.product != undefined) {
        for (let i = 0; i < this.productList.length; i++) {
          for (let j = 0; j < this.product.length; j++) {
            if (this.productList[i].ProductId == this.product[j].Id) {
              this.productList[i].isChecked = true;
            }
          }
        }
      }
    }
    else {
      this.productList = this.productCopyList;
      
      if (this.product != '' && this.product != undefined) {
        for (let i = 0; i < this.productList.length; i++) {
          for (let j = 0; j < this.product.length; j++) {
            if (this.productList[i].ProductId == this.product[j].Id) {
              this.productList[i].isChecked = true;
            }
          }
        }
      }
    }
    if (this.productList.length > 0) {
      this.noRecords = false;
    }
    else {
      this.noRecords = true;
    }

    this.viewLoading = false;
  }

  checkedMember(event, productId: number, member: any) {
    if (event.checked) {
      this.product.push({
        Id: productId,
        ProductName: member
      });

    }
    else {
      if (this.addRemove) {
        let index: number = 0;
        for (let i = 0; i < this.product.length; i++) {
          index = this.product.findIndex(x => x.ProductId == productId);
        }
        this.product.splice(index, 1);
      }
      else {
        let index: number = 0;
        for (let i = 0; i < this.product.length; i++) {
          index = this.product.indexOf(this.product[i].ProductId);
        }
        this.product.splice(index, 1);
      }
    }
  }
  onSubmit() {
    const products = this.product;
    this.dialogRef.close({ products, isEdit: true })
    
  }



  onNoClick() {
    this.dialogRef.close();
  }


}
