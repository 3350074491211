import { Component, OnInit, Inject, ElementRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TimelineComponent } from "../timeline.component";
import { CustomersService } from "../../../../../../../../core/crm/customers";
import { map } from "rxjs/operators";
import { ImageViewerConfig, CustomImageEvent } from "angular-x-image-viewer";
declare var $: any;
@Component({
	selector: "kt-dialog",
	templateUrl: "./dialog.component.html",
	styleUrls: ["./dialog.component.scss"],
})
export class DialogComponent implements OnInit {
	latitude: number;
	longitude: number;
	geoCoder;
	zoom: number = 18;
	img = [];
	isLoading: boolean = true;
	address;
	label: string='';
	constructor(public dialogRef: MatDialogRef<TimelineComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private customersService: CustomersService, private ele: ElementRef) {}

	ngOnInit() {
		if (this.data.label) {
			
			this.label = this.data.label;
		}
		if (this.data.showWindow == "image") {
			this.isLoading = true;
			this.getimg();
		} else {
			this.latitude = this.data.transaction.Latitude;
			this.longitude = this.data.transaction.Longitude;
			this.geoCoder = new google.maps.Geocoder();
			this.getAddress(this.latitude, this.longitude);
		}
	}
	ngAfterViewInit() {
		const repeat = this.ele.nativeElement.querySelector("span.fa.fa-undo.rotate") as HTMLElement;
		if (repeat) {
			repeat.style.transform = "rotate(180deg)";
		}
	}
	getimg() {
		this.customersService
			.getImages(this.data.transaction)
			.pipe(
				map((res) => {
					if (res.Status == 200) {
						this.img = res.data;
					}
				})
			)
			.subscribe();
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	getAddress(latitude, longitude) {
		this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
			if (status === "OK") {
				if (results[0]) {
					this.zoom = 18;
					this.address = results[0].formatted_address;
				} else {
					window.alert("No results found");
				}
			} else {
				window.alert("Geocoder failed due to: " + status);
			}
		});
	}
	imageIndexOne = 0;

	config: ImageViewerConfig = { btnIcons: { rotateClockwise: "fa fa-undo rotate" }, customBtns: [{ name: "print", icon: "fas fa-download" }] };

	handleEvent(event: CustomImageEvent) {
		const a = document.createElement("a");
		a.href = this.img[event.imageIndex];
		a.download = "title";
		document.body.appendChild(a);
		a.click();
	}
}
