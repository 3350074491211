import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
	selector: 'kt-mails-instructions',
	templateUrl: './mails-instructions.component.html',
	styles: ['.mailInitial{border-radius: 50%;color: white;text-align: center;height: 28px;width: 28px;font-size: 21px;vertical-align: middle;padding: 4px;}'],
})
export class MailsInstructionsComponent {
	constructor(public dialogRef: MatDialogRef<MailsInstructionsComponent>,
	) { }

	onNoClick() {
		this.dialogRef.close();
	}
}