import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { User } from '../_models/user.model';
import { Permission } from '../_models/permission.model';
import { Role } from '../_models/role.model';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { AuthGateway } from '../../gateway/auth-gateway';

const API_USERS_URL = 'api/users';
const API_PERMISSION_URL = 'api/permissions';
const API_ROLES_URL = 'api/roles';

@Injectable()
export class AuthService implements AuthGateway{
    refreshUrl$: Observable<any>;
    private refreshUrlSubject = new Subject<any>();
    private currentUserSubject: BehaviorSubject<User>;
    public Permissions: any
    public currentUser: Observable<User>;
    private emailOTPSubject: BehaviorSubject<any>;
    public currentEmailOTP: Observable<any>;
    public emailMessage = {};
    data: any;
    countryList: any;

    constructor(private http: HttpClient, private _cookieService: CookieService) {
        this.refreshUrl$ = this.refreshUrlSubject.asObservable();
        let x = this._cookieService.get('currentUser')
        if(x != ''){
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(this._cookieService.get('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        }
        this.emailOTPSubject = new BehaviorSubject<any>('');
        this.currentEmailOTP = this.emailOTPSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    getCurrentUser(): Observable<any> {
        // this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(this._cookieService.get('currentUser')));
        this.currentUser = JSON.parse(this._cookieService.get('currentUser'))
        return of(this.currentUser)
    }
    getPermissions(): Observable<any> {

        // this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        let user
        if (localStorage.getItem('Permissions') && this._cookieService.check('currentUser')) {
            user = {
                Permissions: JSON.parse(localStorage.getItem('Permissions')),
                currentUser: JSON.parse(this._cookieService.get('currentUser'))
            }
        }
        this.Permissions = user
        return of(this.Permissions)
    }
    getUrl(): Observable<any> {
        return of(this.data)
    }

    refreshUrl(data) {
        this.refreshUrlSubject.next(data);
        this.data = data
    }

    // Authentication/Authorization
    login(username: string, password: string): Observable<any> {
        return this.http.post<User>('api/account/authenticate', { username, password });
    }

    register(user: User): Observable<any> {
        return this.http.post<User>('api/WebUserRegister', user)
            .pipe(
                map((res: User) => {
                    return res;
                }),
                catchError(err => {
                    return null;
                })
            );
    }

    resetPassword(user: User): Observable<any> {
        return this.http.post<any>('api/Account/ResetPassword', user)
            .pipe(
                map((res: User) => {
                    return res;
                }),
                catchError(err => {
                    return null;
                })
            );
    }

    generateLoginOTP(username) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>('api/Account/GenerateLoginOTP', '"' + username + '"', httpOptions)
            .pipe(map(user => {
                return user;
            }));
    }

    loginWithOTP(user: User): Observable<any> {
        return this.http.post<any>('api/Account/LoginWithOTP', user)
            .pipe(
                map((res: User) => {
                    return res;
                }),
                catchError(err => {
                    return null;
                })
            );
    }

    generateUserOTP(username) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>('api/Account/GenerateUserOTP', '"' + username + '"', httpOptions)
            .pipe(map(user => {
                return user;
            }));
    }

    /*********** Change EmailOTP Messsage **********/
    changeEmailOTPMessage(email: string, messsage: string) {
        this.emailMessage = { email: email, textMessagae: messsage }
        this.emailOTPSubject.next(this.emailMessage)
    }

    getUserByToken(): Observable<User> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        const httpHeaders = new HttpHeaders();
        httpHeaders.append('Authorization', 'Bearer ' + userToken);
        return this.http.get<User>(API_USERS_URL, { headers: httpHeaders });
    }

    /*
     * Submit forgot password request
     *
     * @param {string} email
     * @returns {Observable<any>}
     */
    public requestPassword(email: string): Observable<any> {
        return this.http.get(API_USERS_URL + '/forgot?=' + email)
            .pipe(catchError(this.handleError('forgot-password', []))
            );
    }

    getAllUsers(): Observable<User[]> {
        return this.http.get<User[]>(API_USERS_URL);
    }

    getUserById(userId: number): Observable<User> {
        return this.http.get<User>(API_USERS_URL + `/${userId}`);
    }

    // DELETE => delete the user from the server
    deleteUser(userId: number) {
        const url = `${API_USERS_URL}/${userId}`;
        return this.http.delete(url);
    }

    // UPDATE => PUT: update the user on the server
    updateUser(_user: User): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(API_USERS_URL, _user, { headers: httpHeaders });
    }

    // CREATE =>  POST: add a new user to the server
    createUser(user: User): Observable<User> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<User>(API_USERS_URL, user, { headers: httpHeaders });
    }

    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
    // items => filtered/sorted result
    findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<QueryResultsModel>(API_USERS_URL + '/findUsers', queryParams, { headers: httpHeaders });
    }

    // Permission
    getAllPermissions(): Observable<Permission[]> {
        return this.http.get<Permission[]>(API_PERMISSION_URL);
    }

    getRolePermissions(roleId: number): Observable<Permission[]> {
        return this.http.get<Permission[]>(API_PERMISSION_URL + '/getRolePermission?=' + roleId);
    }

    // Roles
    getAllRoles(): Observable<Role[]> {
        return this.http.get<Role[]>(API_ROLES_URL);
    }

    getRoleById(roleId: number): Observable<Role> {
        return this.http.get<Role>(API_ROLES_URL + `/${roleId}`);
    }

    // CREATE =>  POST: add a new role to the server
    createRole(role: Role): Observable<Role> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<Role>(API_ROLES_URL, role, { headers: httpHeaders });
    }

    // UPDATE => PUT: update the role on the server
    updateRole(role: Role): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(API_ROLES_URL, role, { headers: httpHeaders });
    }

    // DELETE => delete the role from the server
    deleteRole(roleId: number): Observable<Role> {
        const url = `${API_ROLES_URL}/${roleId}`;
        return this.http.delete<Role>(url);
    }

    // Check Role Before deletion
    isRoleAssignedToUsers(roleId: number): Observable<boolean> {
        return this.http.get<boolean>(API_ROLES_URL + '/checkIsRollAssignedToUser?roleId=' + roleId);
    }

    findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        // This code imitates server calls
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<QueryResultsModel>(API_ROLES_URL + '/findRoles', queryParams, { headers: httpHeaders });
    }

    /*********** CountryList **********/
    GetCountry(): Observable<any> {
        if (!this.countryList) {
            this.countryList = this.http.get<any>('api/GetCountryList')
                .pipe(map(resp => {
                    return resp.data;
                }),
                    publishReplay(1),
                    refCount()
                );
        }
        return this.countryList
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
   *
   * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    referralLogin(username: string, password: string): Observable<any> {
        return this.http.post<User>('referral/ClientAccount/Authenticate', { username, password });
    }
}