import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { AttendanceService } from '../../../../../../core/attendance/_services/attendance.service';
import { FormBuilder, FormGroup ,Validators} from '@angular/forms';

@Component({
  selector: 'kt-user-punchin-dialog',
  templateUrl: './user-punchin-dialog.component.html',
  styleUrls: ['./user-punchin-dialog.component.scss']
})
export class UserPunchinDialogComponent implements OnInit {
  @ViewChild('report', {static: false}) report: ElementRef;
  isError: boolean = false;
  isValid: boolean = false;
  reportData: any;
  workstationParam={
		from:'1',
		to:'-1',
		search:'',
		isActive:true
	}
  workStation: any;
  setworkstation: FormGroup;
  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<UserPunchinDialogComponent>,
    private attendenceservice: AttendanceService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private fb:FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

 

  ngOnInit() {
    if(this.data.isPunchInWorkstation) {
      this.attendenceservice.getWorkstation(this.workstationParam).subscribe(res=>{
        if(res.Status==200){
        this.workStation = res.data.Items
        }
      }
      )
      this.createForm();
    }        
  }
  createForm(){
    this.setworkstation =this.fb.group({
      workstationId: ['', Validators.required]
    })
  }
  onSubmit() {
    if (this.data.isPunchInWorkstation) {
      var workstationId = this.setworkstation.value.workstationId;
      if(workstationId=='' || workstationId==null){
        this.isError = true;
        return;
      }
    }
    if (this.data.Ispunchreport) {
      var reportData = this.report.nativeElement.value;
      if (reportData == '' || reportData == null) {
        this.isError = true;
        return;
      }
      else if(reportData.length>500){
        this.isValid = true;
        return;
      }
    }

    if (this.data.isPunchInWorkstation || this.data.Ispunchreport) {
      console.log(4);
      this.isError = false;
      this.isValid = false;
      this.dialogRef.close({ reportData, workstationId, isEdit: true });
    }

    // else {
    //   console.log(5);
    //   this.isError = false;
    //   this.isValid = false;
    //   this.dialogRef.close({ reportData, workstationId, isEdit: true });
    // }
  }

  onNoClick(){
    this.dialogRef.close();
  }

}
