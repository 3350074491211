
// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddReasonDialogComponent } from '../../../../pages/crm/opportunities/add-reason-dialog/add-reason-dialog.component';
import { OpportunitiesService } from '../../../../../core/crm/opportunities/_services/opportunities.service';
import { map } from 'rxjs/operators';
import { DealsPipelineService } from '../../../../../core/crm/deals-pipeline-setting/service/deals-pipeline-setting.service';
import { OpportunitiesModel } from '../../../../../core/crm/opportunities/_models/opportunities.model';
import { TruncatePipe } from '../../../../../core/_base/layout/pipes/truncate-text.pipe';

@Component({
	selector: 'kt-move-reason-dialog',
	templateUrl: './move-reason-dialog.component.html',
	styleUrls: ['./move-reason-dialog.component.scss']
})
export class MoveReasonDialogComponent implements OnInit {
	// Public properties
	viewLoading: boolean = false;
	reasonList: any;
	reasonListLength: number
	opportunityForm: FormGroup;
	opportunityStatusId: string;
	opportunityData: OpportunitiesModel;
	opportunity: OpportunitiesModel;
	lostReason: any;
	selectedReason: any;
	leadId: number;
	hasFormErrors: boolean = false;
	isLostReasonRequired: boolean;

	pdfName: string = '';
	imageName: string = '';
	imagePath: string = '';
	filesizeError: boolean
	isProposalFileRequired: boolean;
	isWonFileRequired: boolean;
	btnDisable:boolean = false

	/**
	 * Component constructor
	 *
	 * @param dialogRef:MatDialogRef<AddReasonDialogComponent>, 
	 * @param data: any
	 */
	constructor(
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<AddReasonDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialog: MatDialog,
		private dealsPipelineService: DealsPipelineService,
		private opportunitiesService: OpportunitiesService,
		private truncatepipe: TruncatePipe,
	) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.opportunitiesService.moveLostReason.subscribe(resp => {
			this.opportunityStatusId = resp;
		})
		this.dealsPipelineService.getMyData().subscribe(res => {
			this.isLostReasonRequired = res.data.IsReasonRequired
			this.isProposalFileRequired = res.data.IsProposalAttachmentRequired
			this.isWonFileRequired = res.data.IsWonAttachmentRequired
			this.createForm();
		})
		this.createForm();
		this.opportunitiesService.getOpportunitiesId.subscribe(id => {
			this.leadId = id
		})
		this.opportunitiesService.getVisitData(this.leadId).subscribe(res => {
			this.opportunityData = res.data;
		}
		)
	}
	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.dialogRef.close();
	}

	getLostReason() {
		this.opportunitiesService.getAllLostReason().subscribe(
			res => this.reasonList = res.data
		);
	}

	createForm() {
		this.opportunityForm = this.fb.group({
			reason: [''],
			fileinput: [''],
		});
	}

	openReason() {
		if (this.reasonList != undefined) {
			var checkedList = this.reasonList
			var params = {
				checkedList
			}
		}
		else {
			params = null
		}
		const dialogRef = this.dialog.open(AddReasonDialogComponent, { data: params, width: '400px' });
		dialogRef.afterClosed().subscribe(res => {
			if (!res)
				return
			if (res) {
				this.reasonList = res.reasons;
				for (let i = 0; i < this.reasonList.length; i++) {
					if (this.reasonList[i].ReasonId == undefined) {
						let notFormatedData = this.reasonList[i]
						var temp = {                        //formatting the data as required for the POST method while posting checked lost Reason List
							ReasonId: notFormatedData
						}
						this.reasonList.splice(i, 1, temp)
					}
				}
				this.opportunityData.OpportunityReasons = this.reasonList;
				this.opportunityData.OpportunityStatusId = 5;
				delete this.opportunityData.OpportunityReason;
				delete this.opportunityData.AssignedToUserName;
				delete this.opportunityData.CreateDate;
				delete this.opportunityData.CreatedBy;
				delete this.opportunityData.Id;
				delete this.opportunityData.LeadName;
				delete this.opportunityData.Status;
				delete this.opportunityData.CreatedByUser;
				delete this.opportunityData.Tags;
				this.reasonListLength = this.reasonList.length;
				if (this.reasonListLength > 0) {
					this.opportunityForm.controls.reason.patchValue(`${this.reasonListLength} Reason Selected`);
				}
				else {
					this.opportunityForm.controls.reason.patchValue(null)
				}
			}
		});
	}

	allLostReason() {
		if (this.opportunityForm.valid) {
			if (this.reasonList == undefined) {
				return
			}
			console.log(this.opportunityData)
			this.opportunitiesService.update(this.leadId, this.opportunityData).pipe(map(res => {
				if (res.Status == 200) {
					this.dialogRef.close({ isEdit: false });
				}
			})).subscribe();
		}
	}

	Attachwonpdfdata() {
		if (this.opportunityForm.valid) {

			this.opportunityData.MultimediaData = this.imagePath
			this.opportunityData.MultimediaFileName = this.imageName
			if (this.opportunityStatusId == '3') {
				this.opportunityData.OpportunityStatusId = 3;
			} else {
				this.opportunityData.OpportunityStatusId = 4;
			}
			delete this.opportunityData.WonOpportunity;
			delete this.opportunityData.Quotation;
			delete this.opportunityData.OpportunityReason;
			delete this.opportunityData.AssignedToUserName;
			delete this.opportunityData.CreateDate;
			delete this.opportunityData.CreatedBy;
			delete this.opportunityData.Id;
			delete this.opportunityData.LeadName;
			delete this.opportunityData.Status;
			delete this.opportunityData.CreatedByUser;
			delete this.opportunityData.Tags;

			this.opportunitiesService.update(this.leadId, this.opportunityData).pipe(map(res => {
				if (res.Status == 200) {
					this.dialogRef.close({ isEdit: false });
				}
			})).subscribe();
		}
	}
	placeholdervalue() {
		if (this.isProposalFileRequired && this.opportunityStatusId == '3') {
			return 'Attach Proposal Pdf*'
		}
		else if (this.isWonFileRequired && this.opportunityStatusId == '4') {
			return 'Attach Won Pdf*'
		}
		else {
			return 'Attach Pdf'
		}
	}
	handleFileSelect(inputValue: any): void {
		if (inputValue.files[0] && inputValue.files[0].size <= 2000000) {
			this.filesizeError = false
			var file = inputValue.files[0];
			this.pdfName = this.truncatepipe.transform(file.name, ['24']);
			this.imageName = file.name.replace(/\\/g, "/");
			this.imageName = this.imageName.substring(0, this.imageName.lastIndexOf('.'));
			var reader = new FileReader();
			reader.onload = (e: any) => {
				var binaryData = e.target.result;
				var base64String = btoa(binaryData);
				this.imagePath = base64String;
				this.opportunityForm.patchValue({
					fileinput: this.pdfName
				});
			}
			reader.readAsBinaryString(file);
		}
		else if (inputValue.files[0] && inputValue.files[0].size > 2000000) {
			this.filesizeError = true;
		}
	}

	removeFile() {
		this.pdfName = ''
		this.opportunityForm.patchValue({
			fileinput: '',
		});
	}
	/**
 * Close dialog with true result
 */
	onYesClick(): void {
		/* Server loading imitation. Remove this */
		console.log('logsd',this.opportunityForm.controls.fileinput.value);
		if (this.opportunityStatusId == '3' && this.isProposalFileRequired) {
			this.opportunityForm.controls.fileinput.setValidators(Validators.required);
			this.opportunityForm.controls.fileinput.updateValueAndValidity();
		}
		if (this.opportunityStatusId == '4' && this.isWonFileRequired) {
			this.opportunityForm.controls.fileinput.setValidators(Validators.required);
			this.opportunityForm.controls.fileinput.updateValueAndValidity();
		}
		if (this.opportunityStatusId == '5' && this.isLostReasonRequired) {
			this.opportunityForm.controls.reason.setValidators(Validators.required);
			this.opportunityForm.controls.reason.updateValueAndValidity();
		}
		if (this.opportunityForm.invalid) {
			this.opportunityForm.markAllAsTouched();
			return
		}
		 this.viewLoading = true;
		setTimeout(() => {
			this.dialogRef.close(true);
		}, 2500);
		if (this.opportunityStatusId == '5') {
			this.allLostReason()
		} else if (this.opportunityStatusId == '3' || this.opportunityStatusId == '4') {
			this.Attachwonpdfdata()
		}
	}
	}