import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitString'
})
export class SplitStringPipe implements PipeTransform {

  transform(val: string): any {
    let valData = val.split(',')
    return valData;
  }

}
