import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "customCurrencyFormat",
})
export class CustomCurrencyFormatPipe implements PipeTransform {
	transform(value: any, ...args: any[]): string {
		if (value) {
			const currency = value.toLocaleString("en-IN");

			return currency;
		}
		return value;
	}
}
