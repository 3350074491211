export class TaskModel {
	RowNumber: number;
	TaskId: number;
	Title: string;
	Description: string;
	AssignedByUserId: number;
	AssignedByUserName: string;
	TaskEndDate: string;
	Priority: string;
	TaskStatus: number;
	MultimediaType: string;
	MultimediaName: string;
	AssignedToUserId: number;
	AssignedToUserName: string;
	TaskCommentCount: number;
	CreateDate: string;
	CompletedDate: string;
	IsArchive: boolean;
	Latitude: number;
	Longitude: number;
	Location: string;
	IntercomGroupId: number;
	MultimediaModifiedDate: string;
	CompletionPercentage: number;
	clear() {
		this.RowNumber = null;
		this.TaskId = null;
		this.Title =  "";
		this.Description = "";
		this.AssignedByUserId = null;
		this.AssignedByUserName = "";
		this.TaskEndDate = "";
		this.Priority = "";
		this.TaskStatus = null;
		this.MultimediaType = "";
		this.MultimediaName = "";
		this.AssignedToUserId = null;
		this.AssignedToUserName = "";
		this.TaskCommentCount = null;
		this.CreateDate = "";
		this.CompletedDate = "";
		this.IsArchive = null;
		this.Latitude = null;
		this.Longitude = null;
		this.Location = "";
		this.IntercomGroupId = null;
		this.MultimediaModifiedDate = "";
		this.CompletionPercentage = null;
	}
}