import { catchError, finalize } from 'rxjs/operators';
// RxJS
import { map } from 'rxjs/operators';
// CRUD
import { BaseDataSource } from '../../../_base/crud';
import { BehaviorSubject, of } from 'rxjs';
import { ProductmasterService } from '../_services';

export class ProductmasterDataSource extends BaseDataSource {

	private loadingSubject = new BehaviorSubject<boolean>(false);
	private isPreloadTextViewedSubject = new BehaviorSubject<boolean>(true);

	public loading$ = this.loadingSubject.asObservable();
	public isPreloadTextViewed$ = this.isPreloadTextViewedSubject.asObservable();

	constructor(private productmasterService: ProductmasterService) {
		super();
	}

	loadProducts(from, to, search) {
		this.loadingSubject.next(true);

		this.productmasterService.findProducts(from, to, search)
			.pipe(
				map(
					products => {
						this.paginatorTotalSubject.next(products.data.TotalRecords);
						this.entitySubject.next(products.data.Items);
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
			.subscribe();
	}

	loadChildProducts(masterProductId, text, to) {
		this.loadingSubject.next(true);

		this.productmasterService.getChildProduct(masterProductId, text, to)
			.pipe(
				map(
					products => {
						this.paginatorTotalSubject.next(products.data.TotalRecords);
						this.entitySubject.next(products.data.Items);
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
			.subscribe();
	}

	loadBulkUploadViewReport(File_Id,from,to,search){
		this.loadingSubject.next(true);

		this.productmasterService.BulkUploadViewReport(File_Id,from, to, search)
			.pipe(
				map(
					products => {
						this.paginatorTotalSubject.next(products.TotalRecords);
						this.entitySubject.next(products.Items);
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
			.subscribe();
	}

	loadBulkUploadFileName(loadtype,from,to,search){
		this.loadingSubject.next(true);

		this.productmasterService.ShowProductBulkUploadFileName(loadtype,from, to, search)
			.pipe(
				map(
					products => {
						this.paginatorTotalSubject.next(products.TotalRecords);
						this.entitySubject.next(products.Items);
					}
				),
				catchError(() => of([])),
				finalize(() => {
					this.loadingSubject.next(false);
					this.isPreloadTextViewedSubject.next(false);
				})
			)
			.subscribe();
	}
}