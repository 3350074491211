import { BaseModel } from '../../../_base/crud';

export class OpportunityModel extends BaseModel {
	LeadId: number;
	OpportunityName: string;
	OpportunityStatusId: number;
	AssignedToUserId: number;
	Value: number;
	CloseDate: Date
	OpportunityTags: string;

	clear() {
		this.LeadId = 0;
		this.OpportunityName = '';
		this.OpportunityStatusId = 0;
		this.AssignedToUserId = 0;
		this.Value = 0;
		this.CloseDate = new Date();
		this.OpportunityTags = '';
	}
}