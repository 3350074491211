import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { filter, finalize, switchMap, takeUntil, tap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import { AppState } from "../../../../core/reducers";
import { AuthNoticeService, AuthService, Login, Authenticate } from "../../../../core/auth";
import { LayoutConfigModel, LayoutConfigService } from "../../../../core/_base/layout";
import { ToastrService } from "ngx-toastr";
import { MyreferralsService } from "../../../../core/myreferrals/myreferrals.service";

@Component({
	selector: "kt-login",
	templateUrl: "./login.component.html",
	encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
	model: LayoutConfigModel;
	loginForm: FormGroup;
	loading = false;
	otpLoading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];
	private unsubscribe: Subject<any>;
	private returnUrl: any;
	data: any;
	hide = true;
	captchaConfig: any = {
		type: 1,
		length: 6,
		cssClass: "custom",
		back: {
			stroke: "#2F9688",
			solid: "#e8f0fe",
		},
		font: {
			color: "#000000",
			size: "20px",
		},
	};
	captchaResult: any = "empty";

	constructor(private router: Router, private auth: AuthService, private authNoticeService: AuthNoticeService, private translate: TranslateService, private store: Store<AppState>, private fb: FormBuilder, private cdr: ChangeDetectorRef, private route: ActivatedRoute, private layoutConfigService: LayoutConfigService, private toastrService: ToastrService, private myreferralsService: MyreferralsService) {
		this.unsubscribe = new Subject();
	}

	// group = new FormGroup({
	// 	email: new FormControl(),
	// 	password: new FormControl(),
	// 	captcha: new FormControl(),
	// })
	ngOnInit(): void {
		this.model = this.layoutConfigService.getConfig();
		this.initLoginForm();
		this.route.queryParams.subscribe((params) => {
			this.returnUrl = params["returnUrl"] || "/";
		});
		if (this.returnUrl == "/") {
			this.auth.getUrl().subscribe((data) => {
				this.data = data; // And he have data here too!
				let split = this.data.split("/")[2];
				let auth = this.data.split("/")[3];
				if (auth != "auth") {
					this.data = this.data.split(split)[1];
					this.returnUrl = this.data;
				}
			});
		}
	}

	getResult(event) {
		// console.log('captcha',event);
		this.captchaResult = event;
		// if(event === "true"){
		// 	console.log("catpta true")
		// 	this.authNoticeService.setNotice("correct capta", 'danger');
		// }else if(event === "false"){
		// 	console.log("catpta incorrect")
		// 	this.authNoticeService.setNotice("incorrect capta", 'danger');
		// }else if(event === "empty"){
		// 	console.log("capta empty");
		// 	this.authNoticeService.setNotice("please enter capta", 'danger');
		// }
	}
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
		this.loading = false;
		this.captchaResult = "";
	}

	initLoginForm() {
		this.loginForm = this.fb.group({
			username: ["", Validators.compose([Validators.required])],
			password: ["", Validators.compose([Validators.required])],
		});
	}

	submit() {
		const controls = this.loginForm.controls;
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
			return;
		}
		const authData = {
			username: controls["username"].value,
			password: controls["password"].value,
		};
		if (this.captchaResult === "true") {
			this.loading = true;
			// this.auth.login(authData.username, authData.password)
			// 	.pipe(
			// 		tap(user => {
			// 			if (user.success == true) {
			// 				user.accessToken = 'Basic ' + btoa(authData.username + ':' + authData.password);
			// 				this.store.dispatch(new Authenticate({ currentUser: user }));
			// 				this.router.navigateByUrl(this.returnUrl);
			// 				this.toastrService.success('Login Successfully')
			// 				// location.reload();
			// 				this.auth.referralLogin(authData.username, authData.password)
			// 				.pipe(
			// 					tap(res => {
			// 						console.log(res);

			// 						if (res.status == 200) {
			// 							res.accessToken = 'Basic ' + btoa(authData.username + ':' + authData.password);
			// 							this.store.dispatch(new Authenticate({ currentUser: res }));
			// 					}
			// 					})).subscribe();

			// 			} else {
			// 				this.authNoticeService.setNotice(user.errormessage, 'danger');
			// 			}
			// 		}),
			// 		takeUntil(this.unsubscribe),
			// 		finalize(() => {
			// 			this.loading = false;
			// 			this.cdr.markForCheck();
			// 		})
			// 	)
			// 	.subscribe();

			// First API call
			this.auth
				.login(authData.username, authData.password)
				.pipe(
					filter((firstApiResponse: any) => {
						// Apply a condition to the first API response
						// Only proceed to the second API call if the condition is met
						if (firstApiResponse.success === true) {
							return firstApiResponse.success === true;
						} else {
							this.toastrService.error(firstApiResponse.errormessage);
						}
					}),
					switchMap((firstApiResponse: any) => {
						// Handle the success case of the first API call
						firstApiResponse.accessToken = "Basic " + btoa(authData.username + ":" + authData.password);
						this.store.dispatch(new Authenticate({ currentUser: firstApiResponse }));
						// console.log(firstApiResponse.Permissions);
						this.router.navigateByUrl(this.returnUrl);
						this.toastrService.success("Login Successfully");
						// Second API call with the same username and password
						// 	return this.auth.referralLogin(authData.username, authData.password);
						// }),
						// switchMap((secondApiResponse: any) => {
						localStorage.setItem("referraltoken", firstApiResponse.referralToken);
						// Second API call with the same username and password
						return this.myreferralsService.getreferrallink();
					}),
					finalize(() => {
						this.loading = false;
						this.cdr.markForCheck();
					})
				)
				.subscribe((secondApiResponse) => {
					console.log(secondApiResponse);
					if (secondApiResponse.status === 200) {
						// console.log(secondApiResponse.data);

						localStorage.setItem("externalReferralLink", secondApiResponse.data);

						// secondApiResponse.accessToken = "Basic " + btoa(authData.username + ":" + authData.password);
						// this.store.dispatch(new Authenticate({ currentUser: secondApiResponse }));
					}
				});
			takeUntil(this.unsubscribe);
		} else if (this.captchaResult === "false") {
			this.authNoticeService.setNotice("Please Enter Correct Captcha", "danger");
		} else if (this.captchaResult === "empty") {
			// console.log("capta empty");
			this.authNoticeService.setNotice("Please Enter Captcha", "danger");
		}
	}

	generateLoginOTP() {
		const controlsOTP = this.loginForm.controls;
		if (!this.loginForm.value.username) {
			Object.keys(controlsOTP).forEach((controlName) => controlsOTP[controlName].markAsTouched());
			return;
		}
		if (this.captchaResult === "true") {
			const username = controlsOTP["username"].value;
			this.otpLoading = true;
			this.auth
				.generateLoginOTP(username)
				.pipe(
					tap((user) => {
						if (user.success) {
							this.auth.changeEmailOTPMessage(user.email, "");
							this.router.navigateByUrl("/auth/login-with-otp");
						} else {
							this.authNoticeService.setNotice(this.translate.instant("AUTH.VALIDATION.NOT_FOUND", { name: this.translate.instant("AUTH.INPUT.USERNAME") }), "danger");
						}
					}),
					takeUntil(this.unsubscribe),
					finalize(() => {
						this.otpLoading = false;
						this.cdr.markForCheck();
					})
				)
				.subscribe();
		} else if (this.captchaResult === "false") {
			this.authNoticeService.setNotice("Please Enter Correct Captcha", "danger");
		} else if (this.captchaResult === "empty") {
			// console.log("capta empty");
			this.authNoticeService.setNotice("Please Enter Captcha", "danger");
		}
	}

	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
