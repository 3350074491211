import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
// Material
import { MatDialogRef, MAT_DIALOG_DATA, MatSelect } from '@angular/material';
import { map, takeUntil, take } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { UserService } from '../../../../core/users/_services/users.service';
import { CustomDatePipe } from '../../../../core/_base/layout/index';
import { AuthService } from '../../../../core/auth/_services'
import { OrganizationalUnitService } from '../../../../core/orgnizational-units';
@Component({
  selector: 'kt-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.scss']
})
export class UsersEditComponent implements OnInit {
  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;
  // @ViewChild('filterBranches', { static: false }) filterBranches!: ElementRef;

  userEditForm: FormGroup;
  countryList: any = [];
  locality = [];
  roles = [];
  stateList = [];
  cityList = [];
  userList = [];
  DepartmentList: any = [];
  DesignationList:any = [];
  BranchesList:any = [];
  territoryList = [];
  editUserList = [];
  filterArrayCountry = [];
  filterArrayState = [];
  filterArrayCity = [];
  // filterArrayDepartment: any;
  // filterArrayDesignation = [];
  disabled: boolean = false;
  button: string = '';
  selectCountry: string = '+91';
  mobileLength: number = 10;
  tenDigitError: boolean = false;
  sixDigitError: boolean = false;
  errMsg: string = '';
  viewLoading: boolean = false;
  RemoveTerritoryList = [];
  localityList = [];
  dummyTerritoryList = [];
  RemoveRoleIds = [];
  RoleIds = [];
  currentUser;
  current = new Date();
  default: number = -1;
  roleType: any;
  currentDate: Date = new Date();
  public countryCodeFilterCtrl: FormControl = new FormControl();
  public filteredCountryCode: ReplaySubject<any[]> = new ReplaySubject<[]>(1);
  protected _onDestroy = new Subject<void>();
  leadFilter: any;
  teamFilter: any;
  teamData: any;
  leadData: any;
  LocalityId

  public departmentFilterCtrl: FormControl = new FormControl();
	public filterArrayDepartment: ReplaySubject<any[]> = new ReplaySubject<[]>(1);

  public designationFilterCtrl: FormControl = new FormControl();
	public filterArrayDesignation: ReplaySubject<any[]> = new ReplaySubject<[]>(1);

  public branchFilterCtrl: FormControl = new FormControl();
	public filterArrayBranches: ReplaySubject<any[]> = new ReplaySubject<[]>(1);


  //Organizational Unit data 
  filterData = {
    From: 1,
    Text: '',
    To: -1,
    IsActive: true
  };
  filterArrayBranchesData: any;
  filterArrayDepartmentData: any;
  filterArrayDesignationData: any;

  // isSearched:boolean=false;

  constructor(
    public dialogRef: MatDialogRef<UsersEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    public userService: UserService,
    private customDatePipe: CustomDatePipe,
    private authservice: AuthService,
    private organizationalUnitService: OrganizationalUnitService
  ) {
    this.authservice.getCurrentUser().subscribe(res => this.currentUser = res);
    this.userService.getRoles()
      .pipe(
        map(res => {
          this.roles = res.data;

        })
      ).subscribe();
  }

  ngOnInit() {
    if (this.data.Action == 'Deactivate') {
      this.button = 'OK'
      return
    }
    if (this.data.Action == 'Edit') {
      this.disabled = true;
      this.button = 'Update';
    }
    else {
      this.disabled = false;
      this.button = 'Add';
    }
    console.log('a')
    this.organizationalUnitService.getDeparment(this.filterData).pipe(map(res => {
      this.DepartmentList = res.data.Items;
      this.filterArrayDepartmentData = res.data.Items;
      if(this.filterArrayDepartmentData){
        this.filterArrayDepartment.next(this.filterArrayDepartmentData.slice());
      }
      // console.log(this.filterArrayDepartment,"this.filterArrayDepartment");
      
    })).subscribe()

    this.organizationalUnitService.getDesignation(this.filterData).pipe(map(res => {
      this.DesignationList = res.data.Items;
      this.filterArrayDesignationData = res.data.Items;
      this.filterArrayDesignation.next(this.filterArrayDesignationData.slice());
    }
    )).subscribe();

    this.organizationalUnitService.getBranches(this.filterData).pipe(map(res => {
      this.BranchesList = res.data.Items;
      this.filterArrayBranchesData = res.data.Items;
      if(this.filterArrayBranchesData){
        this.filterArrayBranches.next(this.filterArrayBranchesData.slice());
      }

      // console.log("this.filterArrayBranches", this.filterArrayBranches);

    }
    )).subscribe();

    this.authservice.GetCountry()
      .pipe(
        map(res => {
          this.countryList = res;
          this.filterArrayCountry = res;
          this.filteredCountryCode.next(this.countryList.slice());
          this.setInitialValue();
        })
      ).subscribe();

    this.userService.getUsers()
      .pipe(
        map(res => {
          this.userList = res.data.Items;
          this.leadData = res.data.Items
        })
      ).subscribe();

    this.userService.getTerritory()
      .pipe(
        map(res => {
          this.territoryList = res.data.Territory;
        })
      ).subscribe();

    this.countryCodeFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        // this.filterMemberMulti();
        this.filterCountryCode();
      });

    this.createForm();

    this.branchFilterCtrl.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.filterMemberMulti();
			});

      this.departmentFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterDepartment();
      });

      this.designationFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterDesigination();
      })


      // this.userEditForm.valueChanges.subscribe(res=>{
      //   // console.log(res.Branches);
      //   if(res.BranchId){
      //   this.filterArrayBranches.nativeElement.value = "";
      //   this.filterValueBranches(this.filterBranches.nativeElement.value)
      //   }
      // })

  }


  filterCountryCode() {
    if (!this.countryList) {
      return;
    }
    let search = this.countryCodeFilterCtrl.value;
    if (!search) {
      this.filteredCountryCode.next(this.countryList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCountryCode.next(
      this.countryList.filter(department => department.CountryCode.toLowerCase().indexOf(search) > -1)
    );
  }

  setInitialValue() {
    this.filteredCountryCode
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.multiSelect) {
          this.multiSelect.compareWith = (a, b) => a && b && a.UserId === b.UserId;
        }
      });
  }



  filterMemberMulti() {
    // console.log('innnn')
    if (!this.BranchesList) {
      return;
    }
    // get the search keyword
    let search = this.branchFilterCtrl.value;
    if (!search) {
      this.filterArrayBranches.next(this.BranchesList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the Country Code
    this.filterArrayBranches.next(
      this.BranchesList.filter(branches => branches.Name.toLowerCase().indexOf(search) > -1)
    );

  }

  filterDepartment() {
    // console.log('innnn')
    if (!this.DepartmentList) {
      return;
    }
    // get the search keyword
    let search = this.departmentFilterCtrl.value;
    if (!search) {
      this.filterArrayDepartment.next(this.DepartmentList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the Country Code
    this.filterArrayDepartment.next(
      this.DepartmentList.filter(department => department.Name.toLowerCase().indexOf(search) > -1)
    );

  }

  filterDesigination() {
    // console.log('innnn')
    if (!this.DesignationList) {
      return;
    }
    // get the search keyword
    let search = this.designationFilterCtrl.value;
    if (!search) {
      this.filterArrayDesignation.next(this.DesignationList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the Country Code
    this.filterArrayDesignation.next(
      this.DesignationList.filter(designation => designation.Name.toLowerCase().indexOf(search) > -1)
    );

  }

  createForm() {
    this.userEditForm = this.fb.group({
      UserId: [{ value: '', disabled: !this.disabled }],
      Name: ['', Validators.required],
      EmployeeNo: [''],
      CountryCode: [this.selectCountry, Validators.required],
      MobileNo: ['', [Validators.required]],
      ContactNo: [''],
      Email: ['', [Validators.required, Validators.email]],
      RoleId: [null, Validators.required],
      Gender: [''],
      DOB: [''],
      JoinDate: [''],
      EndDate: [''],
      CountryId: [0],
      StateId: [0],
      CityId: [0],
      LocalityId:[0],
      ZipCode: [''],
      StreetAddress: [''],
      ManagerId: [{ value: '', disabled: this.disabled }],
      TerritoryIds: [''],
      RemoveTerritoryIds: [''],
      RoleIds: [''],
      DepartmentId: [''],
      DesignationId: [''],
      BranchId: [''],
      AccessWebLogin: ['']
    })
    if (this.data.Action == 'Add') {
      this.userEditForm.controls['JoinDate'].setValue(this.currentDate);
      this.userEditForm.controls.AccessWebLogin.setValue(true);
    }
    if (this.data.Action == 'Edit') {
      // this.userEditForm.patchValue({
      //   DepartmentId: this.data.Department.Id
      // })
      // console.log(this.data,"data")
      this.userService.getUserDetails(this.data.Id)
        .pipe(
          map(res => {
            this.editUserList = res.data;
            if (res.data.CountryId > 0) {
              this.userService.getStateList(res.data.CountryId)
                .pipe(
                  map(res => {
                    this.stateList = res.data;
                    this.filterArrayState = res.data;
                  })
                ).subscribe();
              if (res.data.StateId > 0) {
                this.userService.getCityList(res.data.StateId)
                  .pipe(
                    map(res => {
                      this.cityList = res.data;
                      this.filterArrayCity = res.data;
                    })
                  ).subscribe();
                  if(res.data.CityId > 0){
                    this.userService.getLocality(res.data.CityId)
                    .pipe(
                      map(res => {
                        this.locality = res;
                        this.localityList = res;
                      })
                    ).subscribe(); 
                  }
              }
            }
            this.userEditForm.patchValue(this.editUserList);
            this.getRoles();
            this.RemoveTerritoryList = res.data.TerritoryIds;
            this.dummyTerritoryList = res.data.TerritoryIds;
            if (!(this.editUserList['Dob'] == "")) {
              this.userEditForm.controls.DOB.setValue(new Date(this.editUserList['Dob']));
            }
            if (!(this.editUserList['JoinDate'] == "")) {
              this.userEditForm.controls.JoinDate.setValue(new Date(this.editUserList['JoinDate']));
            }
            if (!(this.editUserList['EndDate'] == "")) {
              this.userEditForm.controls.EndDate.setValue(new Date(this.editUserList['EndDate'] ? this.editUserList['EndDate'] :null));
            }

            if (res.data.ZipCode == 0) {
              this.userEditForm.controls.ZipCode.setValue('');
            }
          })
        ).subscribe();
    }
  }

  mobileValidate() {
    const controls = this.userEditForm.controls;
    let mobilevalue = controls.MobileNo.value;
    if (mobilevalue.length == 0) {
      this.sixDigitError = false;
      this.tenDigitError = false;
    }
    if (controls.CountryCode.value == '+91' && mobilevalue.length > 0 && mobilevalue.length != 10) {
      controls.MobileNo.setValidators([Validators.required, Validators.minLength(10)]);
      controls.MobileNo.updateValueAndValidity();
      this.sixDigitError = false;
      this.tenDigitError = true;
      this.mobileLength = 10;
    }
    if (controls.CountryCode.value != '+91' && mobilevalue.length > 0 && mobilevalue.length != 6) {
      controls.MobileNo.setValidators([Validators.required, Validators.minLength(6)]);
      controls.MobileNo.updateValueAndValidity();
      this.tenDigitError = false;
      this.sixDigitError = true;
      this.mobileLength = 6;
    }
  }

  getState(countryId: number) {
    this.userService.getStateList(countryId)
      .pipe(
        map(res => {
          if (res.Status == 404) {
            this.userEditForm.controls['StateId'].patchValue(0);
            this.userEditForm.controls['CityId'].patchValue(0);
            this.userEditForm.controls['LocalityId'].patchValue(0);
          }
          else if (res.Status == 200) {
            this.stateList = res.data;
            this.filterArrayState = res.data;
          }
        })
      ).subscribe();
  }

  getCity(stateId: number) {
    this.userService.getCityList(stateId)
      .pipe(
        map(res => {
          if (res.Status == 404) {
            this.userEditForm.controls['CityId'].patchValue(0);
            return
          }
          else if (res.Status == 200) {
            this.cityList = res.data;
            this.filterArrayCity = res.data;
          }
        })
      ).subscribe();
  }

  getLocalityList(City) {
    this.userService.getLocality(City)
      .pipe(map(res => {
        if (res) {
          this.userEditForm.controls['LocalityId'].patchValue(0);
          this.locality = res
          this.localityList = res;
        }
      }))
      .subscribe();
  }

  // getLocality(){
  //   this.userService.getLocalityList()
  //     .pipe(
  //       map(res => {
  //         if (res.Status == 404) {
  //           this.userEditForm.controls['LocalityId'].patchValue(0);
  //           return
  //         }
  //         else if (res.Status == 200) {
  //           this.cityList = res.data;
  //           this.filterArrayCity = res.data;
  //         }
  //       })
  //     ).subscribe();
  // }

  onSubmit() {
    this.viewLoading = true;
    var dob = '';
    var joinDate = '';
    var endDate = '';
    if (this.data.Action == 'Deactivate') {
      this.dialogRef.close({ Deactivate: true });
    } else {
      const controls = this.userEditForm.controls;

      if (this.userEditForm.invalid) {
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );
        this.viewLoading = false;
        return;
      }


      if (this.data.Action == 'Add') {
        if (!(this.userEditForm.controls['DOB'].value == "")) {
          dob = this.customDatePipe.transform(this.userEditForm.controls['DOB'].value, 0, 'MM/dd/yyyy');
          this.userEditForm.controls.DOB.setValue(dob);
          this.userEditForm.get('DOB').setErrors(null);
        }
        if (!(this.userEditForm.controls['JoinDate'].value == "")) {
          joinDate = this.customDatePipe.transform(this.userEditForm.controls['JoinDate'].value, 0, 'MM/dd/yyyy');
          this.userEditForm.controls.JoinDate.setValue(joinDate);
          this.userEditForm.get('JoinDate').setErrors(null);
        }

        if (controls.DOB.value == '') {
          controls.DOB.disable();
        }
        if (controls.ManagerId.value == '') {
          controls.ManagerId.setValue(-1);
        }
        this.userService.addUser(this.userEditForm.value)
          .pipe(map(res => {
            if (res.Status == 200) {
              this.dialogRef.close({ res, isEdit: true });
            }
            else {
              if (!(dob == '')) {
                this.userEditForm.controls.DOB.setValue(new Date(dob));
              }
              if (!(joinDate == '')) {
                this.userEditForm.controls.JoinDate.setValue(new Date(joinDate));
              }
              this.errMsg = res.Message;
            }
            this.viewLoading = false;
          })).subscribe();
      }

      if (this.data.Action == 'Edit') {
        console.log(this.userEditForm.controls['EndDate'].value)
        this.RemoveTerritoryList = this.RemoveTerritoryList.filter(val => !controls.TerritoryIds.value.includes(val));
        this.userEditForm.controls.RemoveTerritoryIds.setValue(this.RemoveTerritoryList);
        this.userEditForm.controls.RoleIds.setValue(this.RoleIds);
        if (!(this.userEditForm.controls['DOB'].value == "")) {
          dob = this.customDatePipe.transform(this.userEditForm.controls['DOB'].value, 0, 'MM/dd/yyyy');
          this.userEditForm.controls.DOB.setValue(dob);
          this.userEditForm.get('DOB').setErrors(null);
        }
        if (!(this.userEditForm.controls['JoinDate'].value == "")) {
          joinDate = this.customDatePipe.transform(this.userEditForm.controls['JoinDate'].value, 0, 'MM/dd/yyyy');
          this.userEditForm.controls.JoinDate.setValue(joinDate);
          this.userEditForm.get('JoinDate').setErrors(null);
        }
        // if (!(this.userEditForm.controls['EndDate'].value == "")) // {
        //   endDate = this.customDatePipe.transform(this.userEditForm.controls['EndDate'].value, 0, 'MM/dd/yyyy');
        //   this.userEditForm.controls.EndDate.setValue(endDate);
        //   this.userEditForm.get('EndDate').setErrors(null);
        // }

        if (this.editUserList['RoleId'] == 1) {
          this.userEditForm.controls.RoleId.enable();
        }
        console.log(this.userEditForm.value,)
        this.userService.updateUser(this.userEditForm.value)
          .pipe(map(res => {
            if (res.Status == 200) {
              this.dialogRef.close({ res, isEdit: true });
            }
            else {
              if (!(dob == '')) {
                this.userEditForm.controls.DOB.setValue(new Date(dob));
              }
              if (!(joinDate == '')) {
                this.userEditForm.controls.JoinDate.setValue(new Date(joinDate));
              }
              if (!(endDate == '')) {
                this.userEditForm.controls.EndDate.setValue(new Date(endDate ? endDate :null));
              }
              this.errMsg = res.Message;
            }
            this.viewLoading = false;
          })).subscribe();
      }
    }

  }

  getRoles() {
    this.roles.filter(item => {
      if (item.Id == this.editUserList['RoleId']) {
        this.roleType = item.Name;
      }
    })
    if (this.roleType == 'Administrator' && this.currentUser.roles.CompanyOwner) {
      this.userEditForm.controls.RoleId.enable();
    } else if (this.roleType == 'Administrator') {
      this.userEditForm.controls.RoleId.disable();
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }
  filterValueCountry(searchCountry) {
		if (searchCountry != '') {
		  this.filterArrayCountry = this.countryList.filter(option => { return option.Name.toLowerCase().startsWith(searchCountry.toLowerCase()) })
	
		}
		else {
		  this.filterArrayCountry = this.countryList
		}
    }
    filterValueState(searchState) {
      if (searchState != '') {
        this.filterArrayState = this.stateList.filter(option => { return option.Name.toLowerCase().startsWith(searchState.toLowerCase()) })
    
      }
      else {
        this.filterArrayState = this.stateList
      }
      }
      filterValueCity(searchCity) {
      if (searchCity != '') {
        this.filterArrayCity = this.cityList.filter(option => { return option.Name.toLowerCase().startsWith(searchCity.toLowerCase()) })
    
      }
      else {
        this.filterArrayCity = this.cityList
      }
      }
      filterValueLocality(searchLocality){
        if (searchLocality != '') {
          this.localityList = this.locality.filter(option => { return option.LocalityName.toLowerCase().startsWith(searchLocality.toLowerCase()) })
    
        }
        else {
          this.localityList = this.locality
        }
      }
           
      searchManager(searchText) {
        if (searchText != '') {
          this.userList = this.leadData.filter(item => { console.log(item,'item')
            if (item.Name.toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
              return true;
            }
            return false;
          }
          );
        }
        else {
          this.userList = this.leadData;
        }
     
    }

    removeLastDate(){
      this.userEditForm.controls.EndDate.setValue(null)
    }
}
