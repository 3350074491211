import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { PastMeetPreviewDialogComponent } from "./preview/past-meet-preview.dialog.component";
import _ from "lodash";
@Component({
	selector: "kt-form-dialog",
	templateUrl: "./form-dialog.component.html",
	styleUrls: ["./form-dialog.component.scss"],
})
export class FormDialogComponent implements OnInit {
	constructor(public dialogRef: MatDialogRef<FormDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) {}
	displayData: any;
	formSection: any;
  notSelectedSingle: boolean = false;

	ngOnInit() {
		this.displayData = this.data.data;
    this.formSection = this.data.section;
    console.log(this.formSection.Controls);
    // setTimeout(() => {
      let allControlOptions;
      
    
		for (let i = 0; i < this.formSection.length; i++) {
      const singleControls = this.formSection[i].Controls.filter((control) => control.ControlType === "Single");
      console.log(singleControls);
      if (singleControls ) {
  
        // for(let )
        for (let j = 0; j < singleControls.length; j++) {
          // this.notSelectedSingle = false;
           allControlOptions = singleControls[j].ControlOptions;
           if (allControlOptions) {
               const areAllSingleControlsSelectedOptionsIdZero = allControlOptions.every((control) => control.SelectedOptionId === 0);
             if (areAllSingleControlsSelectedOptionsIdZero) {
               this.notSelectedSingle = true;
               }
             
           }
          }
      }
      

			// for (let j = 0; j < this.formSection.Controls; j++){
			//   console.log(this.formSection.Controls);
			//   // const singleControls = data[0].Controls.filter(control => control.ControlType === 'Single');
			//   if (this.formSection.Controls.ControlType == 'Single') {
			//     let controls = this.formSection.Controls.ControlOptions;
			//     for (let k of controls) {
			//       if (k.SelectedOptionId) {

			//      }
			//     }
			//   }

			// }
      }
    // }, 2000);
	}
	previewImage(Image) {
		this.dialog.open(PastMeetPreviewDialogComponent, { data: [Image], width: "700px" });
	}
}
