import { ToastrService } from "ngx-toastr";
import { Component, OnInit, NgZone, ChangeDetectorRef, ViewRef, OnDestroy } from "@angular/core";
import { AttendanceService } from "../../../../../core/attendance";
import { AuthService } from "../../../../../core/auth";
import { map } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import { MapsAPILoader, MouseEvent } from "@agm/core";
import { MatDialog } from "@angular/material";
import { UserPunchoutDialogComponent } from "./user-punchout-dialog/user-punchout-dialog.component";
import { TotalHoursWithoutSecsPipe } from "../../../../../core/_base/layout";
import { GlobalMapComponent } from "../../../../../views/pages/crm/team/team-actions/_subs/global-map/global-map.component";
import { FormBuilder } from "@angular/forms";
import { TeamService } from "../../../../../core/crm/team";
import { UserPunchinDialogComponent } from "./user-punchin-dialog/user-punchin-dialog.component";

@Component({
	selector: "kt-user-punch",
	templateUrl: "./user-punch.component.html",
	styleUrls: ["./user-punch.component.scss"],
})
export class UserPunchComponent implements OnInit {
	currentUser: any;
	punchDetails: any;
	totalHours: any;
	currentDate = new Date();
	CheckInLatitude: any;
	CheckInLocation: any;
	CheckInLongitude: any;
	Report: string = "";
	geoCoder: any;
	interval: any;
	disbalePunchIN: boolean;
	clearClock: any;
	IsWebPunchIn: boolean = false;
	IsWebPunchOut: boolean = false;

	private globGlobalMapComponent = new GlobalMapComponent(this.teamService, this.datePipe, this.fb, this.chRef, this.mapsAPILoader);
	Ispunchreport: boolean = false;
	isPunchInWorkstation: boolean = false;

	constructor(
		private teamService: TeamService,
		private datePipe: DatePipe,
		private fb: FormBuilder,
		private attendanceService?: AttendanceService,
		private authService?: AuthService,
		private mapsAPILoader?: MapsAPILoader,
		private ngZone?: NgZone,
		private dialog?: MatDialog,
		private totalHoursPipe?: TotalHoursWithoutSecsPipe,
		private chRef?: ChangeDetectorRef,
		private toastrService?: ToastrService // private globGlobalMapComponent?: GlobalMapComponent,
	) {
		this.authService.getCurrentUser().subscribe((x) => {
			this.currentUser = x;
		});
	}

	ngOnInit() {
		this.clearClock = setInterval(() => {
			this.currentDate = new Date();
			this.chRef.detectChanges();
		}, 1000);

		this.attendanceService
			.getUserSetting(this.currentUser.userId)
			.pipe(
				map((res) => {
					this.punchDetails = res.data;
					this.IsWebPunchIn = res.data.IsWebPunchIn;
					this.IsWebPunchOut = res.data.IsWebPunchOut;

					if (this.punchDetails.CheckInTime != "" && this.punchDetails.CheckOutTime == "") {
						this.totalHours = this.totalHoursPipe.transform(new Date(), this.punchDetails.CheckInTime);
						this.totalPunchTimer(this.punchDetails.CheckInTime);
					}
					if (this.punchDetails.CheckInTime != "" && this.punchDetails.CheckOutTime != "") {
						this.pauseTimer();
						this.totalHours = this.totalHoursPipe.transform(new Date(this.punchDetails.CheckOutTime), new Date(this.punchDetails.CheckInTime));
					}
					this.chRef.detectChanges();
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		clearInterval(this.clearClock);
	}

	totalPunchTimer(CheckInTime) {
		this.interval = setInterval(() => {
			this.totalHours = this.totalHoursPipe.transform(new Date(), CheckInTime);
			if (!(this.chRef as ViewRef).destroyed) {
				this.chRef.detectChanges();
			}
		}, 1000);
	}

	pauseTimer() {
		clearInterval(this.interval);
	}

	punchIn() {
		// this.disbalePunchIN = false
		// this.mapsAPILoader.load().then(() => {
		//   this.setCurrentLocation('punchIn');
		//   this.geoCoder = new google.maps.Geocoder;
		//   this.disbalePunchIN = true
		//   this.chRef.detectChanges();
		//   this.globGlobalMapComponent.ngOnInit();
		// });
		this.attendanceService.geoUserGlobalSetting().subscribe((res: any) => {
			this.Ispunchreport = res.data.IsPunchInReport;
			this.isPunchInWorkstation = res.data.IsWorkstation;
			const params = {
				Ispunchreport: res.data.IsPunchInReport,
				isPunchInWorkstation: res.data.IsWorkstation,
			};
			if (this.Ispunchreport || this.isPunchInWorkstation) {
				const dialogRef = this.dialog.open(UserPunchinDialogComponent, { data: params, width: "400px", disableClose: true });
				dialogRef.afterClosed().subscribe((res) => {
				

					if (!res) return;
					this.disbalePunchIN = false;
					this.mapsAPILoader.load().then(() => {
						this.setCurrentLocation("punchIn", res);
						this.geoCoder = new google.maps.Geocoder();
						this.disbalePunchIN = true;
						this.chRef.detectChanges();
						this.globGlobalMapComponent.ngOnInit();
					});
				});
			} else {
				this.disbalePunchIN = false;
				this.mapsAPILoader.load().then(() => {
					this.setCurrentLocation("punchIn", res);
					this.geoCoder = new google.maps.Geocoder();
					this.disbalePunchIN = true;
					this.chRef.detectChanges();
					this.globGlobalMapComponent.ngOnInit();
				});
			}
		});
	}

	punchOut() {
		let params;
		const dialogRef = this.dialog.open(UserPunchoutDialogComponent, { width: "400px", disableClose: true });
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) return;
			this.mapsAPILoader.load().then(() => {
				this.setCurrentLocation("punchOut", res);
				this.geoCoder = new google.maps.Geocoder();

				this.chRef.detectChanges();
				this.globGlobalMapComponent.ngOnInit();
			});
		});
	}

	setCurrentLocation(action, data?) {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					this.CheckInLatitude = position.coords.latitude;
					this.CheckInLongitude = position.coords.longitude;
					if (action == "punchIn") {
						this.getAddress(this.CheckInLatitude, this.CheckInLongitude, action, data);
					} else {
						this.getAddress(this.CheckInLatitude, this.CheckInLongitude, action, data);
					}
				},
				(err) => {
					alert("Please Enable Your Location");
					this.disbalePunchIN = false;
				}
			);
		}
	}

	getAddress(latitude, longitude, action, data?) {
		this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
			if (status === "OK") {
				if (results[0]) {
					this.CheckInLocation = results[0].formatted_address;
					if (action == "punchIn") {
						const params = {
							CheckInLatitude: this.CheckInLatitude,
							CheckInLongitude: this.CheckInLongitude,
							CheckInLocation: this.CheckInLocation,
							PunchInReport: data.reportData,
							WorkstationId: data.workstationId,
						};
						this.attendanceService
							.userPunchIn(params)
							.pipe(
								map((res) => {
									if (res.Status == 200) {
										this.ngOnInit();
									} else if (res.Status == 500) {
										this.toastrService.error(res.data);
									}
								})
							)
							.subscribe();
					}
					if (action == "punchOut") {
						const params = {
							CheckOutLatitude: this.CheckInLatitude,
							CheckOutLongitude: this.CheckInLongitude,
							CheckOutLocation: this.CheckInLocation,
							Report: data.reportData,
						};
						this.attendanceService
							.userPunchOut(params)
							.pipe(
								map((res) => {
									console.log(res.data);
									if (res.Status == 200) {
										this.ngOnInit();
									} else if (res.Status == 500) {
										this.toastrService.error(res.data);
									} else {
										this.toastrService.error(res.Message);
									}
								})
							)
							.subscribe();
					}
				} else {
					alert("No results found");
				}
			}
		});
	}
}
