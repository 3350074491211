export class OpportunitiesModel {
    AssignedToUserId: number;
    AssignedToUserName: string;
    CloseDate: string;
    CreateDate: string;
    CreatedBy: number;
    CreatedByUser: string;
    Id: number;
    LeadId: number;
    LeadName: string;
    Notes: string;
    OpportunityName: string;
    OpportunityStatusId: number;
    Status: string;
    Value: number;
    OpportunityTags:any;
	OpportunityNumber:number;
    Tags: any;
    OpportunityProduct: any;
    OpportunityReasons: any;
    OpportunityReason: any;
    MultimediaData : any
    MultimediaFileName: any;
    Quotation : any
    WonOpportunity : any;
    IsFileAttached: any;
    Color:string
}
