import { BaseModel } from '../../../_base/crud';

export class CallLogModel extends BaseModel {
	LeadId: number;
	StatusId: number;
	StatusName: string;
	Note: string;
	VisitPurposeIds: string;
	VisitPurposeName: string;
	IsCallLog: boolean;
	ProductIds: string;

	clear() {
		this.LeadId = 0;
		this.StatusId = 0;
		this.StatusName = '';
		this.Note = '';
		this.VisitPurposeIds = '';
		this.VisitPurposeName = '';
		this.IsCallLog = false;
		this.ProductIds = '';
	}
}