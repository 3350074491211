import { Component, ElementRef, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { LayoutConfigService, SplashScreenService, TranslationService } from '../../../core/_base/layout';
import { AuthNoticeService } from '../../../core/auth';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../core/auth/_services/auth.service';

@Component({
	selector: 'kt-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [NgbCarouselConfig]
})
export class AuthComponent implements OnInit {
	today: number = Date.now();
	headerLogo: string;
	webAppSetting: any;
	images = [];
	showNavigationArrows = false;
	showNavigationIndicators = false;
	data: any;
	returnUrl: any;

	constructor(
		private el: ElementRef,
		private render: Renderer2,
		private layoutConfigService: LayoutConfigService,
		public authNoticeService: AuthNoticeService,
		private translationService: TranslationService,
		private splashScreenService: SplashScreenService,
		config: NgbCarouselConfig,
		private AuthService:AuthService,) {
		config.showNavigationArrows = true;
		config.showNavigationIndicators = true;
		config.interval = 10000;
		config.wrap = false;
		config.keyboard = false;
	}
	ngOnInit(): void {
		
		this.images = [
			{
				'image': '../../../../assets/media/login/login-page-slider1.jpg',
				'title': 'Daily Employee attendance tracking',
				'description': 'Application allows you to monitor your employee’s Check-In, Check-Out time and attendance from anywhere and at any time.'
			},
			{
				'image': '../../../../assets/media/login/login-page-slider2.jpg',
				'title': 'Simplify Lead Management',
				'description': 'Application enable fieldforce to manage leads, sales report analysis and sales forecasting for future marketing decisions.'
			},
			{
				'image': '../../../../assets/media/login/login-page-slider3.jpg',
				'title': 'Track sales and Daily Activity of Employees',
				'description': 'Field Force Connect Application enables tracking employees work activity and Daily performance monitoring.'
			},
		]

		this.translationService.setLanguage(this.translationService.getSelectedLanguage());
		this.webAppSetting = this.layoutConfigService.getCustomWebAppSetting();
		this.splashScreenService.hide();
		this.loadCSS('./assets/css/demo1/style.bundle.css');
	}

	private loadCSS(styleUrl: string) {
		return new Promise((resolve, reject) => {
			const styleElement = document.createElement('link');
			styleElement.href = styleUrl;
			styleElement.type = 'text/css';
			styleElement.rel = 'stylesheet';
			styleElement.onload = resolve;
			this.render.appendChild(this.el.nativeElement, styleElement);
		});
	}
}
