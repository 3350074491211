// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import { filter, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
// Auth actions
import { AuthActionTypes, Login, Logout, Register, UserLoaded, UserRequested, Authenticate } from '../_actions/auth.actions';
import { AuthService } from '../_services/index';
import { AppState } from '../../reducers';
import { environment } from '../../../../environments/environment';
import { isUserLoaded } from '../_selectors/auth.selectors';
import { CookieService } from 'ngx-cookie-service';
@Injectable()
export class AuthEffects {



    @Effect({ dispatch: false })
    authenticate$ = this.actions$.pipe(
        ofType<Authenticate>(AuthActionTypes.Authenticate),
        tap(action => {
            const cookie = this._cookieService.check('currentUser');
            if (!cookie) {
                var exdate=new Date();
                exdate.setDate(exdate.getDate() + 7)

                //document.cookie="Permissions="+ JSON.stringify(action.payload.currentUser['Permissions'])+";expires="+exdate.toUTCString()+"path=/";
                localStorage.setItem("Permissions",JSON.stringify(action.payload.currentUser['Permissions']));
                // this._cookieService.set('Permissions', JSON.stringify(action.payload.currentUser['Permissions']), 7, '/auth', 'localhost', true, 'None');
                
                let currentUser = {
                    userId: action.payload.currentUser['userId'],
                    CompanyDetail: action.payload.currentUser['CompanyDetail'],
                    roles: action.payload.currentUser['roles'],
                    accessToken: action.payload.currentUser['accessToken'],
                    userDetail: action.payload.currentUser['userDetail'],
                };
                document.cookie="currentUser="+ JSON.stringify(currentUser)+";expires="+exdate.toUTCString()+"path=/";
               // localStorage.setItem("currentUser",JSON.stringify(currentUser));
                // this._cookieService.set('currentUser', JSON.stringify(currentUser), 7, '/auth', 'localhost', true, 'None');
            }
        }),
    );

    @Effect({ dispatch: false })
    login$ = this.actions$.pipe(
        ofType<Login>(AuthActionTypes.Login),
        tap(action => {
            localStorage.setItem(environment.authTokenKey, action.payload.authToken);
            //this.store.dispatch(new UserRequested());
        }),
    );

    @Effect({ dispatch: false })
    logout$ = this.actions$.pipe(
        ofType<Logout>(AuthActionTypes.Logout),
        tap(() => {
            // this._cookieService.delete('Permissions')
            //this._cookieService.delete('currentUser')
            this._cookieService.deleteAll()
            //localStorage.clear();

            document.cookie = "currentUser=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            document.cookie = "Permissions=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          //  localStorage.removeItem('currentUser');
            localStorage.removeItem('Permissions');
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: this.returnUrl }, skipLocationChange: true });
        })
    );

    @Effect({ dispatch: false })
    register$ = this.actions$.pipe(
        ofType<Register>(AuthActionTypes.Register),
        tap(action => {
            localStorage.setItem(environment.authTokenKey, action.payload.authToken);
        })
    );

    @Effect({ dispatch: false })
    loadUser$ = this.actions$
        .pipe(
            ofType<UserRequested>(AuthActionTypes.UserRequested),
            withLatestFrom(this.store.pipe(select(isUserLoaded))),
            filter(([action, _isUserLoaded]) => !_isUserLoaded),
            mergeMap(([action, _isUserLoaded]) => this.auth.getUserByToken()),
            tap(_user => {
                if (_user) {
                    this.store.dispatch(new UserLoaded({ user: _user }));
                } else {
                    this.store.dispatch(new Logout());
                }
            })
        );

    @Effect()
    init$: Observable<Action> = defer(() => {
        //const userToken = localStorage.getItem(environment.authTokenKey);
        let user = this._cookieService.get('currentUser')
        //let user = localStorage.getItem('currentUser');
        let observableResult = of({ type: 'NO_ACTION' });
        if (user != '') {
            observableResult = of(new Authenticate({ currentUser: JSON.parse(user) }));
        }
        return observableResult;
    });

    private returnUrl: string;

    constructor(private actions$: Actions,
        private router: Router,
        private auth: AuthService,
        private store: Store<AppState>,
        private _cookieService: CookieService) {

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (event.url != '/logout' && event.url != '/auth' && event.url != '/login')
                    this.returnUrl = event.url;
            }
        });
    }
}
