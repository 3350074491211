import { Component, Input, OnInit } from '@angular/core';
import { SparklineChartOptions } from '../../../../../core/_base/layout';

@Component({
	selector: 'kt-widget26',
	templateUrl: './widget26.component.html',
	styleUrls: ['./widget26.component.scss']
})
export class Widget26Component implements OnInit {

	@Input() value: string | number;
	@Input() desc: string;
	@Input() TotalSales: string | number;
	@Input() TotalUnitSales: string | number;
	@Input() TotalVisits: string | number;
	@Input() TotalJoinVisit: string | number;
	@Input() TotalCalls: string | number;
	@Input() TotalLeadsAdded: string | number;
	@Input() TotalOpportunitiesValue: string | number;
	@Input() PeopleCheckedIn: string | number;
	@Input() TotalExpenseClaims: string | number;
	@Input() TotalTeamMembers: string | number;
	@Input() Currency: string | number;
	// @Input() TotalIdlecheck: string | number;
	// @Input() TotalLeavecheck: string | number;
	@Input() TotalIdle: string | number;
	@Input() TotalLeaves: string| number;
	@Input() TotalVisitForm: string| number;
	@Input() TotalDynamicForm: string| number;


	constructor() {
	}

	ngOnInit() {
	}

}