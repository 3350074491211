import { Component, Inject, OnInit, ChangeDetectionStrategy, Input, forwardRef, OnDestroy, ViewChild, ElementRef, OnChanges, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

export interface TagFormValues {
	Tags: string;
}

@Component({
	selector: 'kt-tag',
	templateUrl: './tag.component.html',
	styles: [`.dropdown-menu > li > a, .dropdown-menu > .dropdown-item {padding: 0.5rem 1rem;}
	.display{
		display:none !important;
	}`],
	changeDetection: ChangeDetectionStrategy.Default,
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TagComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => TagComponent),
			multi: true,
		}
	]

})
export class TagComponent implements ControlValueAccessor, OnDestroy {

	@ViewChild('input', { static: true }) input: ElementRef;
	@Input() placeholder: string;
	@Input() isClear: boolean = false;
	@Input() isAdd: boolean;
	@Input() tagList: [
		{ Id: number, TagName: string }
	];
	selectedTag: any = [];
	selectable = true;
	removable = true;
	temp_array = []
	form: FormGroup;
	subscriptions: Subscription[] = [];

	get value(): TagFormValues {
		return this.form.value;
	}

	set value(value: TagFormValues) {
		if (value) { this.selectedTag = value; }
		this.form.patchValue(value);
		this.onChange(value);
		this.onTouched();
	}

	constructor(private formBuilder: FormBuilder) {
		this.form = this.formBuilder.group({
			Tags: []
		});

		this.subscriptions.push(
			this.form.valueChanges.subscribe(value => {
				this.onChange(value);
				this.onTouched();
			})
		);
	}
	ngOnChanges() {
		if (this.isClear) {
			this.selectedTag = [];
			this.form.controls['Tags'].patchValue('');
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	registerOnChange(fn) {
		this.onChange = fn;
	}

	writeValue(value) {
		if (value) {
			this.value = value;
		}
	}

	registerOnTouched(fn) {
		this.onTouched = fn;
	}

	validate(_: FormControl) {
		return this.form.valid ? null : { image: { valid: false, }, };
	}

	formatter = (x: { TagName: string }) => x.TagName;

	search = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			map(term => term.length < 1 ? [] : this.tagList.filter(v => v.TagName.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
		);

	tag(value) {
		if (value != '' || value != null || value != 'undefined') {
			let tagValue = {
				TagName: value,
				IsActive: true,
				isRemoved: false,
			}
			this.selectedTag.push(tagValue);
			this.form.patchValue({
				Tags: this.selectedTag
			});

			this.input.nativeElement.value = '';
		}
	}

	onSelect($event, input) {
		$event.preventDefault();
		if($event.item != '' || $event.item != null || $event.item != 'undefined'){
			this.selectedTag.push($event.item);
			this.form.patchValue({
				Tags: this.selectedTag
			});
			input.value = '';
		}
	}

	remove(index: number): void {
		if (!this.isAdd) {
			this.selectedTag.splice(index, 1);
			this.form.controls.Tags.patchValue(this.selectedTag);
		}

		if (this.isAdd) {
			if (index >= 0) {
				this.selectedTag[index].IsActive = false;
				this.selectedTag[index].isRemoved = true;
				this.form.controls.Tags.patchValue(this.selectedTag);
				this.input.nativeElement.value = '';
			}
		}
	}
}