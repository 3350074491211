export class UpdateInventoryModel {
    ProductId: number;
    TransactionDate: Date;
    Quantity: number;
    Comments: string;

	clear() {
		this.ProductId = 0;
		this.TransactionDate = new Date();
		this.Quantity = 0;
		this.Comments = '';
	}
}