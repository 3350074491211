import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { ProductmasterService } from '../../../../../core/crm/product-master';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { map } from 'rxjs/operators';

@Component({
  selector: 'kt-product-details-dialog',
  templateUrl: './product-details-dialog.component.html',
  styles: ['']
})
export class ProductDetailsDialogComponent implements OnInit {

  constructor(
    private productService: ProductmasterService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProductDetailsDialogComponent>,
    private chRef: ChangeDetectorRef
  ) { }

  productDetails: any = [];
  isImageSelect: boolean = false;
  isVariant: boolean = false;
  imagePreview: any;
  isToggled: boolean = false;
  childCategories: any = [];
  categoryName: any;

  ngOnInit() {
    this.getProductDetails();
  }

  getProductDetails() {
    this.productService.getProductDetail(this.data.id)
      .pipe(
        map(res => {
          if (res.Status == 200) {
            this.productDetails = res.data

            if (this.productDetails.Image) {
              this.isImageSelect = true;
              this.imagePreview = this.productDetails.Image;
            }
            if (this.productDetails.HasChildProduct) {
              this.isVariant = true;
              this.isToggled = true
            }

            this.getCategoryList();
            this.chRef.detectChanges();
          }
        })).subscribe()
  }

  getCategoryList() {
    this.productService.getChildCategories()
      .pipe(map(res => {
        if (res) {
          this.childCategories = res.data;
        }
        if (this.childCategories.length > 0) {
          this.childCategories.filter(item => {
            if (item.CategoryId == this.productDetails['CategoryId'])
            this.categoryName = item.Name;
          })
        }
        this.chRef.detectChanges();
      })).subscribe();
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
