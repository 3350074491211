import { Subscription } from 'rxjs';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LayoutConfigService, SplashScreenService, TranslationService, LayoutConfigModel } from './core/_base/layout';
import { locale as enLang } from './core/_config/i18n/en';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './core/auth/_services/auth.service';
import { Logout } from './core/auth';
import { select, Store } from '@ngrx/store';
import { AppState } from './core/reducers';

export interface Config {
	WebAppTitle: string;
	WebAppIcon: string;
	WebAppGetStartedText: string;
	WebAppGetStartedLogo: string;
	AllowBECMeetingObjectives: boolean;
}

@Component({
	selector: 'body[kt-root]',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
	loader: boolean;
	private unsubscribe: Subscription[] = [];
	model: LayoutConfigModel;
	newUrl: any;
	currentUser: any;
	constructor(private translationService: TranslationService,
		private router: Router,
		private layoutConfigService: LayoutConfigService,
		private splashScreenService: SplashScreenService,
		private http: HttpClient,
		private titleService: Title,
		private _cookieService: CookieService,
		private authService: AuthService,
		private store: Store<AppState>,
	) {
		if(window.location.protocol != 'https:' && !window.location.href.includes('localhost')) {
			location.href = location.href.replace("http://", "https://");
		  }
		this.translationService.loadTranslations(enLang);
		this.getWebAppSetting();
	}

	ngOnInit(): void {
		let url = window.location.href
		this.model = this.layoutConfigService.getConfig();

		this.loader = this.layoutConfigService.getConfig('loader.enabled');
		const routerSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.splashScreenService.hide();
				window.scrollTo(0, 0);
				setTimeout(() => {
					document.body.classList.add('kt-page--loaded');
				}, 500);
			}
		});
		this.unsubscribe.push(routerSubscription);
		this.currentUser = this._cookieService.get('currentUser')
		url = url.split('/')[3]
		if (this.currentUser != '' && url == 'auth') {
			this.router.navigate(['/dashboard'])
		}
		else if (url == 'logout') {
			this.store.dispatch(new Logout());
		}
	}

	ngOnDestroy() {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}

	getWebAppSetting() {
		let string = window.location.href
		this.authService.refreshUrl(string)
		string = string.split('/')[2]
		return this.http.get<Config>('api/WebAppSetting?url=' + string)
			.pipe(map(data => {
				this.titleService.setTitle(data.WebAppTitle);
				this.model.custom.AllowBECMeetingObjectives = data.AllowBECMeetingObjectives;
				this.model.custom.WebAppGetStartedLogo = data.WebAppGetStartedLogo;
				this.model.custom.WebAppGetStartedText = data.WebAppGetStartedText;
				this.model.custom.WebAppIcon = data.WebAppIcon;
				this.model.custom.WebAppTitle = data.WebAppTitle;
				this.model.loader.logo = data.WebAppGetStartedLogo;
				this.layoutConfigService.setConfig(this.model, true);
				this.addFaviconIcon(data.WebAppIcon);
			}))
			.subscribe();
	}

	addFaviconIcon(favicon) {
		const link = (document.querySelector(`link[rel*='icon']`) ||
			document.createElement('link')) as HTMLLinkElement;
		link.type = 'image/x-icon';
		link.rel = 'shortcut icon';
		link.href = favicon;
		document.getElementsByTagName('head')[0].appendChild(link);
	}
}
