import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { } from 'events';

@Component({
  selector: 'kt-button-with-spinner',
  templateUrl: './button-with-spinner.component.html',
  styleUrls: ['./button-with-spinner.component.scss']
})
export class ButtonWithSpinnerComponent implements OnInit {
  isloading: boolean = false
  constructor() { }
  @Input() title
  @Input() toolTip
  @Input() loading
  @Output() performAction: EventEmitter<any> = new EventEmitter()

  ngOnInit() {

  }
  emitEvent() {
    this.performAction.emit(true)
  }
}
