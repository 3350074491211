// Angular
import { Component, ElementRef, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
// Layout config
import { LayoutConfigService } from '../../../../../core/_base/layout';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
/**
 * Sample components with sample data
 */
@Component({
	selector: 'kt-widget12',
	templateUrl: './widget12.component.html',
	styleUrls: ['./widget12.component.scss']
})
export class Widget12Component implements OnInit, OnChanges {

	barChartOptions: ChartOptions = {
		responsive: true,
		// We use these empty structures as placeholders for dynamic theming.
		scales: {
			xAxes: [{
				ticks: {
					autoSkip:true,
					maxTicksLimit: 14
				}
			}],
			yAxes: [{
				ticks: {
					beginAtZero: true,
				}
			}]
		}
	};
	barChartLabels: Label[] = [];
	barChartType: ChartType = 'bar';
	barChartLegend = false;
	colors = [{ "backgroundColor": 'rgba(247, 185, 46, 0.80)' }];
	barChartData: ChartDataSets[] = [{ data: [], label: '', barThickness: 30 }];
	barChartPlugins = [];

	// Public properties
	@Input() chartObj = { labels: [], data: [], total: '', reportLabel: '', options: {} };
	/**
	 * Component constructor
	 * @param layoutConfigService
	 */
	constructor(private layoutConfigService: LayoutConfigService) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		// this.initChart();
		

	}

	ngOnChanges(): void {
		if (this.chartObj) {
			this.initChart();
			this.barChartData[0].data = this.chartObj.data[0];
		}
	}

	private initChart() {
		if (this.chartObj) {
			this.barChartData[0].data = this.chartObj.data[0];
			this.barChartLabels = this.chartObj.labels;
		}
	}
}