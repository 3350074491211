// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { BehaviorSubject, Observable } from 'rxjs';
// Models
import { CustomerModel } from '../_models/customer.model';
import { map, catchError, tap } from 'rxjs/operators';
import { ExcelService } from '../../../_base/crud/services/excel.service';

@Injectable()
export class CustomersService {
	constructor(
		private http: HttpClient,
		private excelService: ExcelService
	) { }
	load = new BehaviorSubject<boolean>(false)
	$load = this.load.asObservable();

	// get customers
	findCustomers(customer): Observable<any> {
		//debugger
		return this.http.post('api/CRM/Leads', customer)
			.pipe(
				map(res => res)
			);
	}

	// add customer
	addCustomer(customer: CustomerModel): Observable<CustomerModel> {
		return this.http.post<CustomerModel>('api/CRM/AddLead', customer);
	}

	// update customer
	updateCustomer(customer: CustomerModel): Observable<any> {
		return this.http.post('api/CRM/UpdateLead', customer);
	}

	// get customer
	getCustomer(customerId): Observable<any> {
		return this.http.get<any>('api/CRM/LeadWithProduct?leadId=' + customerId)
			.pipe(map(resp => {
				return resp.data;
			}));
	}

    //for rerral
	getreferralList(){
		
		return this.http.get('referral/ReferralUser/ApprovedReferralUsers')
		.pipe(
			map(res => res)
		);
	}

	getReferralTimeLine(referredBy){
		return this.http.get('referral/api/myreferral/GetTimelineByReferralCode?referredBy='+ referredBy)
			.pipe(
				map(res => res)
			);
	}

	getReferralCompanyDetail(customerId){
		return this.http.get('referral/api/myreferral/GetCustomerById?customerId='+ customerId)
		.pipe(
			map(res => res)
		);
	}

	
	// My Team
	myTeam(): Observable<any> {
		const params =
		{
			'from': 1,
			'to': -1,
			'text': ''
		};
		return this.http.post('api/CRM/MyTeam', params)
			.pipe(map(res => res));
	}

	// file upload
	fileUpload(file: any): Observable<any> {
		return this.http.post<any>('api/CRM/LeadsBulkUpload', file);
	}

	// export to excel
	fileExport(customer): Observable<any> {
		return this.http.post('api/CRM/ExportToExcel', customer, { responseType: 'arraybuffer' })
			.pipe(
				map((res) => {
					return res;
				}),
				tap(
					data => this.excelService.saveAsExcelFile(data, 'Customers'),
					error => console.log(error),
				),
				catchError(err => {
					return null;
				})
			);
	}

	// assign customer
	assignCustomer(customer): Observable<any> {
		return this.http.post<any>('api/CRM/AssignLead', customer)
	}

	// assign customer
	assignBulkCustomer(customer): Observable<any> {
		return this.http.post<any>('api/CRM/BulkAssignLead', customer)
	}

	// delete customer
	deleteCustomer(customerId: number): Observable<CustomerModel> {
		return this.http.post<CustomerModel>('api/CRM/DeleteLead?leadId=' + customerId, { leadId: customerId });
	}

	// customer source
	getSource(): Observable<any> {
		return this.http.get<any>('api/CRM/LeadSource')
			.pipe(map(resp => {
				return resp.data;
			}));
	}

	// customer stage
	getStage(): Observable<any> {
		return this.http.get<any>('api/CRM/LeadStage')
			.pipe(map(resp => {
				return resp.data;
			}));
	}

	// customer type
	getType(): Observable<any> {
		return this.http.get<any>('api/CRM/LeadType')
			.pipe(map(resp => {
				return resp.data;
			}));
	}

	// parent customer
	getParentCustomer(customerTypeId: number): Observable<any> {
		const params =
		{
			'from': 1,
			'to': -1,
			'LeadTypeId': customerTypeId
		};
		return this.http.post<any>('api/CRM/LeadsByNextLeadType', params)
			.pipe(map(resp => {
				return resp.data.Leads || [];
			}));
	}

	// territory
	getTerritory(): Observable<any> {
		return this.http.get<any>('api/CRM/Territories?from=1&text=&to=-1')
			.pipe(map(resp => {
				return resp.data.Territory;
			}));
	}

	// customer tag
	getTag(): Observable<any> {
		return this.http.get<any>('api/CRM/Tags')
			.pipe(map(resp => {
				return resp.data;
			}));
	}

	//customer designation
	getDesignation(): Observable<any> {
		return this.http.get<any>('api/CRM/LeadDesignations')
			.pipe(map(resp => {
				return resp.data;
			}));
	}

	// customer status
	getCustomerStatus(): Observable<any> {
		return this.http.get<any>('api/CRM/LeadStatus')
			.pipe(map(resp => {
				return resp.data;
			}));
	}

	// meeting objective / visit purpose
	getMeetingObjective(): Observable<any> {
		return this.http.get<any>('api/CRM/VisitPurposes')
			.pipe(map(resp => {
				return resp.data;
			}));
	}

	// Referral objective
	getMyReferral(currentUser): Observable<any> {
		
		return this.http.get<any>('referral/api/GetReferralById?companyId=' + currentUser.CompanyDetail.CompanyId)
			.pipe(map(resp => {
				return resp.data;
			}));
	}

	// customer action points
	getCustomerActionPoint(): Observable<any> {
		return this.http.get<any>('api/CRM/ActionPointsV1')
			.pipe(map(resp => {
				return resp.data;
			}));
	}

	// custom Fields

	getCustomFields(): Observable<any> {
		return this.http.get<any>('api/CustomFields')
			.pipe(map(resp => {
				return resp.data;
			}));
	}

	// add call log / meetingAnalysis
	addCallLog(callLog): Observable<any> {
		return this.http.post<any>('api/CRM/MeetingAnalysis', callLog);
	}

	// add opportunity
	addOpportunity(opportunity): Observable<any> {
		return this.http.post<any>('api/CRM/Opportunity', opportunity);
	}

	// add quick task
	addQuickTask(quickTask): Observable<any> {
		return this.http.post<any>('api/CRM/MeetingAnalysis', quickTask);
	}

	// add appointment
	addAppointment(appointment): Observable<any> {
		return this.http.post<any>('api/CRM/AssignLeadRoster', appointment);
	}

	// lead transactions
	customerTransaction(customer): Observable<any> {
		return this.http.post<any>('api/CRM/LeadTransactions', customer)
			.pipe(map(resp => {
				return resp.data;
			}));
	}

	// comment customer transactions
	commentOnCustomerTransactions(comment): Observable<any> {
		return this.http.post<any>('api/CRM/CommentOnLeadTransactions', comment)
			.pipe(map(resp => {
				return resp.data;
			}));
	}

	// get email accounts
	getEmailAccounts(): Observable<any> {
		return this.http.get<any>('api/EmailAccounts')
			.pipe(map(resp => {
				return resp;
			}));
	}

	// get customer emails
	getCustomersEmails(customerId, nextPageToken): Observable<any> {
		return this.http.get<any>('api/CRM/Leads/' + customerId + '/Emails?nextPageToken=' + nextPageToken)
			.pipe(map(resp => {
				return resp;
			}));
	}
	// getImages
	getImages(id): Observable<any> {
		return this.http.get<any>('api/CRM/LeadTransactionImages?Id=' + id)
			.pipe(map(resp => {
				return resp;
			}));
	}

	//Visitform details
	getdetails(id : number): Observable<any> {
		return this.http.get<any>('api/CRM/FormAnswer?leadTransactionId=' + id)
			.pipe(map(resp => {
				return resp;
			}));
	}
	getfdetails(id : number): Observable<any> {
		return this.http.get<any>('api/DynamicForm/FormAnswer?formFilledId=' + id)
			.pipe(map(resp => {
				return resp;
			}));
	}

	bulkreport(loadtype: string,from: number, to: number, search: string):Observable<any>{
		const params =
		{	'loadtype':loadtype,
			'from': from,
			'to': to,
			'text': search
		};
        return  this.http.get<any>('api/CRM/Customer/BulkUpload/ShowFile?loadtype='+loadtype+'&from=' +from + '&text=' + search + '&to=' + to)
        .pipe(map(resp=>{
            return resp;
        }));
    }
	exportReport(Id):Observable<any>{
		return this.http.post('api/CRM/Customer/BulkUpload/ExportReport/'+Id,Id, { responseType: 'arraybuffer' })
			.pipe(
				map((res) => {
					return res;
				}),
				tap(
					data => this.excelService.saveAsExcelFile(data, 'Customers'),
					error => console.log(error),
				),
				catchError(err => {
					return null;
				})
			);
	}
	ViewReport(Id: number,from: number, to: number, search: string):Observable<any>{
		const params =
		{	'Id':Id,
			'from': from,
			'to': to,
			'text': search
		};
		return this.http.get<any>('api/CRM/Customer/BulkUpload/ViewReport?Id=' +Id+'&from=' +from + '&text=' + search + '&to=' + to)
		.pipe(map(resp=>{
            return resp;
        }));
	}
	exportViewReport(Id):Observable<any>{
		return this.http.post('api/CRM/Customer/BulkUpload/ExportViewReport/'+Id,Id, { responseType: 'arraybuffer' })
			.pipe(
				map((res) => {
					return res;
				}),
				tap(
					data => this.excelService.saveAsExcelFile(data, 'CustomersReport'),
					error => console.log(error),
				),
				catchError(err => {
					return null;
				})
			);
	}
}
