import { Component, OnInit, Inject, ElementRef } from '@angular/core';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ImageViewerConfig, CustomImageEvent } from 'angular-x-image-viewer';
@Component({
  selector: 'kt-preview',
  templateUrl: './past-meet-preview.dialog.component.html',
  styleUrls: ['./past-meet-preview.dialog.component.scss']
})
export class PastMeetPreviewDialogComponent implements OnInit {
  preview:any;
  constructor(public dialogRef: MatDialogRef<PastMeetPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ele:ElementRef) { }

  ngOnInit() {
    this.preview = this.data;
  }
  ngAfterViewInit() {
    const repeat = (this.ele.nativeElement.querySelector('span.fa.fa-undo.rotate') as HTMLElement)
    repeat.style.transform = "rotate(180deg)"
  }
  imageIndexOne = 0;

  config: ImageViewerConfig = { btnIcons: { rotateClockwise: 'fa fa-undo rotate' }, customBtns: [{ name: 'print', icon: 'fas fa-download' }] };

  handleEvent(event: CustomImageEvent) {
    const a = document.createElement('a');
    a.href = this.data[event.imageIndex];
    a.download = 'title';
    document.body.appendChild(a);
    a.click();

  }


}
