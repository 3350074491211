// SERVICES
export { TeamService } from './_services';

// MODELS
export { CustomerModel } from './_models/customer.model';
export { Tags } from './_models/tag.model';
export { CallLogModel } from './_models/callLog.model';
export { OpportunityModel } from './_models/opportunity.model';
export { QuickTaskModel } from './_models/quickTask.model';
export { AppointmentModel } from './_models/appointment.model';

// DataSources
export { CustomersDataSource } from './_data-sources/customers.datasource';