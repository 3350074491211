import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {

  constructor(private http:HttpClient) { }

  changeSubscriptionsStatus(Company):Observable<any>{
    return this.http.put<any>('api/Company/ExtendTrial',Company)
    .pipe(map(
      res => res
    ))
  }
}
