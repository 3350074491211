export class CompanyDetail {
    CompanyId: number;
    CompanyLogo: string;
    CompanyLogoModifiedDate: string;
    CompanyName: string;
    CountryName: string;
    CurrencyId: number;
    CurrencyName: string;
    CurrencySymbol: string;
    DaysRemainig: number;
    GroupId: number;
    GroupName: string;
    SubscriptionStatus: string;
    Timezone: string;
    TrialEndDate: string;
    TrialStartDate: string;

    clear() {
        this.CompanyId = undefined;
        this.CompanyLogo = '';
        this.CompanyLogoModifiedDate = '';
        this.CompanyName = '';
        this.CountryName = '';
        this.CurrencyId = undefined;
        this.CurrencyName = '';
        this.CurrencySymbol = '';
        this.DaysRemainig = undefined;
        this.GroupId = undefined;
        this.GroupName = '';
        this.SubscriptionStatus = '';
        this.Timezone = '';
        this.TrialEndDate = '';
        this.TrialStartDate = '';
    }
}
