import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { CustomersService } from '../../../../../../../core/crm/customers';
import { MatDialog } from '@angular/material';
import { map } from 'rxjs/operators';
import { MailsInstructionsComponent } from './_subs/mails-instructions.component';
import { Router } from '@angular/router';

@Component({
	selector: 'kt-mails',
	templateUrl: './mails.component.html',
	styles: ['.mailInitial{border-radius: 50%;color: white;text-align: center;height: 28px;width: 28px;font-size: 21px;vertical-align: middle;padding: 4px;}'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailsComponent implements OnInit {
	@Input() customerId: number;
	nextPageToken = '';
	customerEmails = [];
	loadingNextMails = false;
	randomColors = [
		"#F38F74",
		"#B67D8B",
		"#B4C37B",
		"#FDDC82",
		"#7299FF",
		"#F075A2",
		"#A3F29D",
		"#A381BD",
		"#8F7B6F"
	];

	constructor(private dialog: MatDialog,
		private customersService: CustomersService,
		private ref: ChangeDetectorRef,
		private router: Router) { }

	ngOnInit() {
		if (this.customerId && this.customerId > 0) {
			this.getEmailAccounts();
		}
	}

	getEmailAccounts() {
		this.customersService.getEmailAccounts()
			.pipe(
				map(res => {
					if (res.Status == 200) {
						this.getCustomersEmails();
					}
				})
			)
			.subscribe();
	}

	getCustomersEmails() {
		this.loadingNextMails = true;
		this.customersService.getCustomersEmails(this.customerId, this.nextPageToken)
			.pipe(
				map(res => {
					if (res.Status == 200) {
						this.loadingNextMails = false;
						this.customerEmails = this.customerEmails.concat(res.data.Emails);
						this.nextPageToken = res.data.NextPageToken;
						this.ref.detectChanges();
					} else {
						this.loadingNextMails = false;
					}
				})
			)
			.subscribe();
	}

	getRandomColor() {
		var letters = '0123456789ABCDEF';
		var color = '#';
		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	isSameDay(dt1, dt2) {
		let date1 = new Date(dt1).setHours(0, 0, 0, 0);
		let date2 = new Date(dt2).setHours(0, 0, 0, 0);
		return (date1 === date2);
	}

	openMailInstruction() {
		const dialogRef = this.dialog.open(MailsInstructionsComponent, { width: '600px' });
		dialogRef.afterClosed().subscribe(res => {
			if (!res)
				return;
		});
	}

	syncEmail() {
		this.router.navigate(['/syncemail'])
	}
}