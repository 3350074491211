import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
// CRUD
import { MessageType } from '../../../../../../../core/_base/crud';
// Services and Models
import { CustomerModel, CustomersService } from '../../../../../../../core/crm/customers';

import { AddressDialogComponent } from '../../../address-dialog/address-dialog.component';
import { AuthService } from '../../../../../../../core/auth';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
// Components
import { CustomerEditDialogComponent } from '../../../customer-edit/customer-edit.dialog.component';
import { MatDialog } from '@angular/material';
// CRUD
import { PreviewDialogComponent } from './preview/preview.dialog.component'
// for referral
import {ReferralCustomerEditComponent} from '../../../referral-customer-edit/referral-customer-edit.component';
// Toastr
import { ToastrService } from 'ngx-toastr';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Component({
	selector: 'kt-details',
	templateUrl: './details.component.html',
	styles: ['.kt-customer-details{background: white;color: rgba(0, 0, 0, 0.87); font-family: Poppins;}'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsComponent implements OnInit {
	customersResult: CustomerModel[] = [];
	customer: CustomerModel
	LeadContacts = [];
	permission;
	@Input() customerId: number;
	@Input() referralList;
	@Input() referByCode;
	customFieldsData: any;
	Customfields: any[];
	referralCustomer: any;
	businessData: any;
	isLoading = false;
	rows = 1;
	@ViewChild('autosize', { static: false }) autosize: CdkTextareaAutosize;


	constructor(public dialog: MatDialog,
		private translate: TranslateService,
		private customersService: CustomersService,
		private toastrService: ToastrService,
		private ref: ChangeDetectorRef,
		private authService: AuthService) { this.getCustomFields(); }

	ngOnInit() {
		if (this.customerId && this.customerId > 0) {
			this.getCustomer();
		}
		this.authService.getPermissions().subscribe(x => {
			this.permission = x.Permissions
			let temp = []
			this.permission.forEach(element => {
				temp.push(element.SystemName)
			});
			this.permission = temp
		});
	}

	getCustomer() {
		this.isLoading = true;
		if(this.referByCode == "" || !this.referByCode)
		{
		this.LeadContacts = [];
		this.Customfields = [];
			this.customersService.getCustomer(this.customerId)
			.pipe(
				map(res => {
					if (res) {
						this.customer = res;
						setTimeout(() => {
							if (this.customer.LeadContacts.length > 0) {
								for (const contact of this.customer.LeadContacts) {
									if (contact.IsActive) {
										this.LeadContacts.push({
											Name: contact.Name,
											ContactNo: contact.ContactNo,
											Email: contact.Email,
											Designation: contact.Designation,
										})
									}
								}
							}
							if (this.customer.CustomFields.length > 0) {
								for (const customField of this.customFieldsData) {
									for (const detailCustomField of this.customer.CustomFields) {
										if (customField.Id === detailCustomField.CustomFieldId) {
											this.Customfields.push({
												CustomFieldId: detailCustomField.CustomFieldId,
												CustomFieldValue: detailCustomField.CustomFieldValue,
												OptionId: detailCustomField.OptionId,
												FieldType: customField.FieldType,
												Placeholder: customField.Placeholder,
												Value: customField.Options
											})
										}
									}
								}
							}
							this.ref.detectChanges();
							this.isLoading = false;//Hide Please Wait Message
						}, 1000);

					}
				})
			)
			.subscribe();
		} else {
			//for referral
			this.customersService.getReferralCompanyDetail(this.customerId)
			.pipe(map((response => {
				this.referralCustomer = response['data'];
				this.isLoading =false
				this.ref.detectChanges();}))
				).subscribe();
		}
	}


	

	getCustomFields() {
		this.customersService.getCustomFields().subscribe(res => {
			this.customFieldsData = res;
			this.ref.detectChanges();

		});
	}

	
	editCustomer(customer: CustomerModel,referralCustomer: any) {
		console.log(customer,'loadCustomersPage')
		//debugger
			let saveMessageTranslateParam = 'APP.CUSTOMERS.EDIT.';
			saveMessageTranslateParam += customer.Id > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
			const _saveMessage = this.translate.instant(saveMessageTranslateParam);
			const _messageType = customer.Id > 0 ? MessageType.Update : MessageType.Create;
			const dialogRef = this.dialog.open(CustomerEditDialogComponent, { data: { customer },width:'900px' });
			dialogRef.afterClosed().subscribe(res => {
				if (!res) {
					return;
				}
				this.getCustomer();
				this.toastrService.success(_saveMessage);
			});
	}

	editReferralCustomer(referralCustomer){
		console.log(referralCustomer,'loadCustomersPagered')
		let saveMessageTranslateParam = 'Updated Sucessfully';
		const _saveMessage = this.translate.instant(saveMessageTranslateParam);
		const dialogRef = this.dialog.open(ReferralCustomerEditComponent, { data: { referralCustomer },width:'900px' });
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
			this.getCustomer();
			this.toastrService.success(_saveMessage);
		});
	}

	preview(imgData) {
		const dialogRef = this.dialog.open(PreviewDialogComponent, { data: [imgData], width: '800px', height: 'auto' });
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
		});
	}

	openMapDilaog() {
		let params = {
			address: this.customer.Address,
			action: 'preview'
		}
		const dialogRef = this.dialog.open(AddressDialogComponent,
			{ data: params }
		)
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
		});
	}
	searchManager(filterReferBy) {
		if (filterReferBy != '') {
			this.businessData = this.referralList.filter(option => { return option.ContactPerson.toLowerCase().startsWith(filterReferBy.toLowerCase()) })

		}
		else {
			this.businessData = this.referralList;
		}	
	}
}