import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomersService } from '../../../../../../../core/crm/customers';
import { map } from 'rxjs/operators';
import { ImageViewerConfig, CustomImageEvent } from 'angular-x-image-viewer';
import { MapsAPILoader } from '@agm/core';
@Component({
  selector: 'kt-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {
  latitude: number;
  longitude: number;
  geoCoder;
  zoom: number = 18;
  img =[];
  isLoading: boolean = true;
  address;

  constructor(public dialogRef: MatDialogRef<PreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customersService: CustomersService,
    private ele: ElementRef,
    private mapsAPILoader: MapsAPILoader
  ) { }

  ngOnInit() {
    if (this.data.showWindow == "image") {
      this.isLoading = true
      this.getimg();
    } else {
      this.latitude = this.data.transaction.Latitude;
      this.longitude = this.data.transaction.Longitude;
      this.getAddress(this.latitude,this.longitude)
    }
  }
  ngAfterViewInit() {
    const repeat = (this.ele.nativeElement.querySelector('span.fa.fa-undo.rotate') as HTMLElement)
    repeat.style.transform = "rotate(180deg)"
  }
  getimg() {
    this.customersService.getImages(this.data.transaction)
      .pipe(map(res => {
        if (res.Status == 200) {
          this.img = res.data
        }
      })).subscribe()
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getAddress(latitude, longitude) {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 18;
            this.address = results[0].formatted_address;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
  
      });
  
    });
    
  }
  imageIndexOne = 0;

  config: ImageViewerConfig = { btnIcons: { rotateClockwise: 'fa fa-undo rotate' }, customBtns: [{ name: 'print', icon: 'fas fa-download' }] };

  handleEvent(event: CustomImageEvent) {
    const a = document.createElement('a');
    a.href = this.img[event.imageIndex];
    a.download = 'title';
    document.body.appendChild(a);
    a.click();

  }

}