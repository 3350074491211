// Angular
import { Component, Inject, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
	selector: 'kt-file-upload-dialog',
	templateUrl: './file-upload-dialog.component.html'
})
export class FileUploadDialogComponent implements OnInit {
	// Public properties
	@ViewChild('fileInput', { static: true }) fileInput: ElementRef;
	fileUploadForm: FormGroup;
	viewLoading: boolean = false;
	displayFileName: string = '';
	fileUploadDetail = {
		FileData: '',
		FileName: ''
	}
	href: string;
	filename: string;

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<FileUploadDialogComponent>
	 * @param data: any
	 */
	constructor(
		public dialogRef: MatDialogRef<FileUploadDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
	) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.setHref()
		this.initFileUploadForm();
	}
	setHref() {
		switch (this.data.from) {
			case 'Customers':
				this.href = '../../../../../../assets/media/BulkUploadSample/BulkUpload/Leads/BulkUpload-Leads.xlsx'
				this.filename = "BulkUpload-Leads.xlsx"
				break;
			case 'Products':
				this.href = '../../../../../../assets/media/BulkUploadSample/BulkUpload/Products/Products-Upload.xlsx'
				this.filename = "Products-Upload.xlsx"
				break;
			case 'Users':
				this.href = '../../../../../../assets/media/BulkUploadSample/BulkUpload/User/Bulk Upload - CRM Team With Managers.xlsx'
				this.filename = "Bulk Upload - CRM Team With Managers.xlsx"
				break;

			default:
				break;
		}
	}
	initFileUploadForm() {
		this.fileUploadForm = this.fb.group({
			file: ['', Validators.required]
		})
	}

	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.dialogRef.close();
	}

	/**
	 * Close dialog with true result
	 */
	onYesClick(): void {
		if (!this.fileUploadForm.value.file) {
			return;
		}

		/* Server loading imitation. Remove this */
		this.viewLoading = true;
		setTimeout(() => {
			this.dialogRef.close(this.fileUploadDetail); // Keep only this row
		});
	}

	/**
	 * Open file select
	 */
	openFile() {
		this.fileInput.nativeElement.click()
	}

	/**
	 * Convert selected file into base64 format
	 */
	handleFileSelect(inputValue: any): void {
		if (inputValue.files[0]) {
			var file = inputValue.files[0];
			this.displayFileName = file.name;
			this.fileUploadDetail.FileName = file.name.substring(0, file.name.lastIndexOf("."));
			var reader = new FileReader();
			reader.onload = (e: any) => {
				var binaryData = e.target.result;
				var base64String = btoa(binaryData);
				this.fileUploadDetail.FileData = base64String;
				this.fileUploadForm.patchValue({
					file: file.name
				});
			}
			reader.readAsBinaryString(file);
		}
	}

	/**
	 * Remove file
	 */
	removeFile() {
		this.displayFileName = '';
		this.fileUploadDetail = {
			FileData: '',
			FileName: ''
		}
		this.fileUploadForm.patchValue({
			file: ''
		});
	}
}