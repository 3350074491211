import { Component, OnInit, Inject } from '@angular/core';
import { TaskService } from '../../../../core/task';
import { map } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';

@Component({
  selector: 'kt-add-members-dialog',
  templateUrl: './add-members-dialog.component.html',
  styleUrls: ['./add-members-dialog.component.scss']
})
export class AddMembersDialogComponent implements OnInit {

  memberList: any = [];
  viewLoading: boolean = false;
  totalRecords: number = 0;
  noRecords: boolean = false;
  userIds: any = [];
  previousFromRow: number = 0;
  previousToRow: number = 0;
  lastRowIndex: number = 0;
  showRemoveUser: boolean = false;
  removeUserDetails: any;
  removeUserList: any = [];
  addRemove: boolean = false;

  constructor(
    private taskService: TaskService,
    public dialogRef: MatDialogRef<AddMembersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {dialogRef.disableClose = true; }

  ngOnInit() {
    if (this.data.usersData != '' && this.data.usersData != undefined) {
      this.showRemoveUser = true;
      this.getMembersList(1, 100, '');
      this.removeUserDetails = this.data.usersData;
    } else if (this.data.usersIds != [] && this.data.usersIds != undefined) {
      this.addRemove = true;
      this.getMembersList(1, 100, '');
      this.previousFromRow = 1;
      this.previousToRow = 10;
    }
    else {
      this.getMembersList(1, 10, '');
      this.previousFromRow = 1;
      this.previousToRow = 10;
    }
  }

  getMembersList(from, to, text) {
    this.taskService.getCompanyMembers(from, to, text)
      .pipe(
        map(members => {
          Array.prototype.push.apply(this.memberList, members.data.Members);
          this.totalRecords = members.data.TotalRecords;
          this.lastRowIndex = this.memberList.length;
          this.viewLoading = false;
          this.getChecked();
        })
      ).subscribe();
  }

  onscroll(event) {
    if (this.previousFromRow < this.totalRecords && this.previousToRow < this.totalRecords) {
      let pos = event.target.scrollTop + event.target.offsetHeight;
      let max = event.target.scrollHeight;
      if (pos >= max) {
        if (this.lastRowIndex + 10 < this.totalRecords || this.lastRowIndex + 10 == this.totalRecords) {
          this.getMembersList(this.previousFromRow + 10, this.previousToRow + 10, '');
          this.previousFromRow += 10;
          this.previousToRow += 10;
        }
        return;
      }
      return;
    }
  }

  searchMember(searchValue) {
    this.viewLoading = true;
    this.taskService.getCompanyMembers(1, this.totalRecords, searchValue)
      .pipe(
        map(members => {
          this.memberList = members.data.Members;
          if (this.memberList == undefined) {
            this.noRecords = true
          }
          else {
            this.noRecords = false;
          }
        })
      ).subscribe();
    this.viewLoading = false;
  }

  checkedMember(event, userId: number, memberName) {
    if (event.checked) {
      if (this.showRemoveUser) {
        if (this.data.Action == 'Owner') {
          this.removeUserList.push({
            UserId: userId
          });
        }
        else {
          this.removeUserList.push(userId);
        }
      }
      else {
        const params = {
          UserId: userId,
          Name: memberName
        }
        this.userIds.push(params);
      }
    }
    else {
      if (this.showRemoveUser) {
        let index = this.removeUserList.indexOf(userId);
        this.removeUserList.splice(index, 1);
      }
      else if (this.addRemove) {
        let index: number = 0;
        for (let i = 0; i < this.userIds.length; i++) {
          index = this.userIds.findIndex(x => x.UserId == userId);
        }
        this.userIds.splice(index, 1);
      }
      else {
        let index: number = 0;
        for (let i = 0; i < this.userIds.length; i++) {
          index = this.userIds.indexOf(this.userIds[i].userId);
        }
        this.userIds.splice(index, 1);
      }
    }
  }

  onSubmit() {
    if (this.showRemoveUser && this.data.Action == 'Owner') {
      this.taskService.removeOwnerTask(this.data.taskId, this.removeUserList)
        .pipe(
          map(res => {
            if (res.Status == 200)
              this.dialogRef.close({ isEdit: true })
          })
        ).subscribe();
    }
    else if (this.showRemoveUser && this.data.Action == 'User') {
      this.taskService.removeUsersExistingTask(this.data.taskId, this.removeUserList)
        .pipe(
          map(res => {
            if (res.Status == 200)
              this.dialogRef.close({ isEdit: true })
          })
        ).subscribe();
    }
    else {
      const members = this.userIds;
      this.dialogRef.close({ members, isEdit: true })
    }
  }

  getChecked() {
    this.userIds = [];
    let param;
    if (this.data.controlname == 'UserIds') {
      this.data.usersIds.forEach(element => {
        for (let i = 0; i < this.memberList.length; i++) {
          if (element == this.memberList[i].UserId) {
            param = {
              UserId: element,
              Name: this.memberList[i].Name
            }
            this.userIds.push(param);
          }
        }
      });

      if (this.data.usersIds != '' && this.data.usersIds != undefined) {
        for (let i = 0; i < this.memberList.length; i++) {
          for (let j = 0; j < this.data.usersIds.length; j++) {
            if (this.memberList[i].UserId == this.data.usersIds[j]) {
              Object.assign(this.memberList[i], { isChecked: true })
            }
          }
        }
      }
    } else if (this.data.controlname == 'TaskOwner') {
      if (this.data.usersIds != '' && this.data.usersIds != undefined) {
        this.userIds = this.data.usersIds;
        for (let i = 0; i < this.memberList.length; i++) {
          for (let j = 0; j < this.data.usersIds.length; j++) {
            if (this.memberList[i].UserId == this.data.usersIds[j].UserId) {
              Object.assign(this.memberList[i], { isChecked: true })
            }
          }
        }
      }
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }

}
